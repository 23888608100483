import { ArcElement, CategoryScale, Legend, Tooltip } from "chart.js";
import Chart from "chart.js/auto";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PetitionsTable from "../../Components/PetitionsTable/PetitionsTable";
import Modal from "../../Components/Modal/Modal";
import {
  petitionTakeover,
  fixPetitions,
  verifyPetition,
} from "../../Redux/petitions/action";
import {
  setSelectedTable,
  setSelectedTableId,
  setTableFilter,
} from "../../Redux/table/action";
import { Regex, isDigit, tableTitles } from "../../Utils/constants";
import "./Home.css";
import PetitionsPreviewModal from "../../Components/PetitionsPreview/PetitionsPreview";
import Filter from "../../Components/Filter/Filter";
import { setErrorStatus } from "../../Redux/status/action";
import PDFRenderer from "../../Components/PDFRenderer/PDFRenderer";
import { isValidArray, isValidObject } from "../../Services/validators";
import AnnouncementTable from "../../Components/AnnouncementTable/AnnouncementTable";
import VolunteeringTable from "../../Components/VolunteeringTable/VolunteeringTable";
import JobTable from "../../Components/JobTable/JobTable";
import JobsPreview from "../../Components/JobsPreview/JobsPreview";
import VolunteeringPreview from "../../Components/VolunteeringPreview/VolunteeringPreview";
import { updateJobStatus } from "../../Redux/jobs/action";
import { updateVolunteeringStatus } from "../../Redux/volunteering/action";

Chart.register(CategoryScale, ArcElement, Tooltip, Legend);

function Home(props) {
  const [previewFile, setPreviewFile] = useState({
    show: false,
    type: null,
    data: null,
  });
  const [canCloseFilter, setCanCloseFilter] = useState();
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [PDFArrayBuffer, setPDFArrayBuffer] = useState([]);

  useEffect(() => {
    if (props.activeSwitch) {
      props.setSelectedTableId("PETITIONS", null);
    }
    // eslint-disable-next-line
  }, [props.activeSwitch]);

  useEffect(() => {
    if (props.table.petitions?.selectedPetition) {
      props.getPetitionsAssets(props.table.petitions?.selectedPetition);
    }
    // eslint-disable-next-line
  }, [props.table.petitions?.selectedPetition, props.petitions.data]);

  const getRemainingHeight = () => {
    switch (true) {
      case props.isFilterApplied:
        return "remaining-body-height-from-header-filter";

      default:
        return "remaining-body-height-from-header";
    }
  };

  useEffect(() => {
    const selectedPetition = props.table.petitions.selectedPetition;
    if (
      selectedPetition &&
      isValidObject(props.petitions.data[selectedPetition]?.fileSrc) &&
      isValidArray(props.petitions.data[selectedPetition]?.fileSrc.pdfs)
    ) {
      updatePDFArrayBuffer(selectedPetition, props.petitions.data);
    }
    // eslint-disable-next-line
  }, [props.table.petitions.selectedPetition, props.petitions.data]);

  const updatePDFArrayBuffer = async (selectedPetition, data) => {
    const pdfUrls = data[selectedPetition].fileSrc.pdfs;

    try {
      const arrayBuffers = await Promise.all(
        pdfUrls.map((url) => {
          return getPdfArrayBufferData(url);
        })
      );
      setPDFArrayBuffer(arrayBuffers);
    } catch (error) {
      console.error("Failed to fetch one or more PDF data:", error);
    }
  };

  return (
    <>
      {/* Complaints Table */}
      {props.activeSwitch !== "MAP" &&
        props.table.selectedTable === tableTitles.petitions && (
          <div
            className={`${getRemainingHeight()} inherit-parent-width flex-justify-content-center display-flex `}
          >
            <div
              className={`${
                props.activeSwitch === "MAP"
                  ? "inherit-parent-width"
                  : "inherit-parent-width flex-justify-content-center"
              } margin-top-16px`}
            >
              <PetitionsTable
                petitions={props.petitions}
                complaintsNextPageNo={props.complaintsNextPageNo}
                getComplaintsNextPage={props.getComplaintsNextPage}
                setSelectedTableId={(documentId) => {
                  props.setSelectedTableId(tableTitles.petitions, documentId);
                  props.setEnableFilter(false);
                  props.getPetitionsAssets(documentId);
                }}
                table={props.table}
                theme={props.theme}
              />
            </div>
          </div>
        )}
      {props.activeSwitch !== "MAP" &&
        props.table.selectedTable === tableTitles.announcement && (
          <div
            className={`${getRemainingHeight()} inherit-parent-width flex-justify-content-center display-flex `}
          >
            <div className="inherit-parent-width flex-justify-content-center margin-top-16px">
              <AnnouncementTable
                announcements={props.announcements}
                table={props.table}
                theme={props.theme}
              />
            </div>
          </div>
        )}
      {props.activeSwitch !== "MAP" &&
        props.table.selectedTable === tableTitles.summary && (
          <div
            className={`${getRemainingHeight()} inherit-parent-width flex-justify-content-center display-flex `}
          >
            <div className="inherit-parent-width flex-justify-content-center margin-top-16px">
              <Fallback
                dataCy="summary"
                // type="summary"
                title="SUMMARY"
                description="Consolidated summary across all available information will be shown here"
              />
            </div>
          </div>
        )}
      {props.activeSwitch !== "MAP" &&
        props.table.selectedTable === tableTitles.volunteering && (
          <div
            className={`${getRemainingHeight()} inherit-parent-width flex-justify-content-center display-flex `}
          >
            <div className="inherit-parent-width flex-justify-content-center margin-top-16px">
              <VolunteeringTable
                volunteering={props.volunteering}
                table={props.table}
                theme={props.theme}
                setSelectedTableId={(documentId) => {
                  props.setSelectedTableId(
                    tableTitles.volunteering,
                    documentId
                  );
                  props.setEnableFilter(false);
                  // props.getPetitionsAssets(documentId);
                }}
              />
            </div>
          </div>
        )}
      {props.activeSwitch !== "MAP" &&
        props.table.selectedTable === tableTitles.jobs && (
          <div
            className={`${getRemainingHeight()} inherit-parent-width flex-justify-content-center display-flex `}
          >
            <div className="inherit-parent-width flex-justify-content-center margin-top-16px">
              <JobTable
                jobs={props.jobs}
                table={props.table}
                theme={props.theme}
                setSelectedTableId={(documentId) => {
                  props.setSelectedTableId(tableTitles.jobs, documentId);
                  props.setEnableFilter(false);
                  // props.getPetitionsAssets(documentId);
                }}
              />
            </div>
          </div>
        )}
      {props.table.selectedTable === tableTitles.petitions &&
        props.enableFilter && (
          <div
            onClick={() => {
              if (canCloseFilter) {
                props.setEnableFilter(false);
              }
            }}
            style={{ top: "64px" }}
            className={`${getRemainingHeight()} z-index-101 background-color-black-with-opacity-light position-fixed top-0 bottom-0 right-0 left-0 inherit-parent-width flex-justify-content-center display-flex `}
          >
            <Filter
              filterData={[
                {
                  title: "issue raised by",
                  value: "",
                  type: "inputBox",
                  inputLabel: "Phone number",
                  inputPlaceholder: "ENTER PHONE NUMBER",
                  position: "right",
                  name: "issueRaised",
                  dataCy: "petitions-filter-issueRaisedBy",
                  inputOnChange: (phoneNumber) => {
                    if (
                      typeof phoneNumber === "string" &&
                      phoneNumber?.length === 10 &&
                      isDigit(phoneNumber)
                    ) {
                      props.setTableFilter(tableTitles.petitions, {
                        ...props.table?.petitions?.filter,
                        issueRaised: [`+91${phoneNumber}`],
                      });
                    } else if (phoneNumber === "") {
                      props.setTableFilter(tableTitles.petitions, {
                        ...props.table?.petitions?.filter,
                        issueRaised: [],
                      });
                    }
                  },
                },
                {
                  title: "status",
                  value: ["OPEN", "CLOSED", "UNDER REVIEW"],
                  position: "right",
                  dataCy: "petitions-filter-status",
                  name: "status",
                  type: "text",
                },

                {
                  title: "date range",
                  position: "right",
                  value: "",
                  name: "dateRange",
                  type: "dateRangeForm",
                  dataCy: "petitions-filter-dateRange",
                },
                {
                  title: "issue taken by",
                  value: "",
                  type: "inputBox",
                  inputLabel: "Email",
                  inputPlaceholder: "ENTER EMAIL",
                  position: "right",
                  name: "issueTakenBy",
                  dataCy: "petitions-filter-issueTakenBy",
                  inputOnChange: (email) => {
                    if (Regex.email.test(email)) {
                      props.setTableFilter(tableTitles.petitions, {
                        ...props.table?.petitions?.filter,
                        issueTakenBy: [email.toLowerCase()],
                      });
                    } else if (email === "") {
                      props.setTableFilter(tableTitles.petitions, {
                        ...props.table?.petitions?.filter,
                        issueTakenBy: [],
                      });
                    }
                  },
                },
              ]}
              setFilter={(filterData) => {
                props.setTableFilter(tableTitles.petitions, filterData);
              }}
              mouseInWhiteArea={() => {
                setCanCloseFilter(false);
              }}
              mouseOutWhiteArea={() => {
                setCanCloseFilter(true);
              }}
              selectedFilter={props.table?.petitions?.filter}
              table={props.table}
              theme={props.theme}
              employees={props.employees}
              dataCy="petitions-filter"
            />
          </div>
        )}
      <Modal
        show={
          props.table.selectedTable === tableTitles.petitions &&
          props.table.petitions.selectedPetition &&
          props.petitions.data?.[props.table.petitions.selectedPetition] &&
          !props.enableFilter
        }
        canIgnore={true}
        onClose={() => {
          props.setSelectedTableId(tableTitles.petitions, null);
        }}
        borderRadius="false"
        boxShadow="box-shadow-none"
        modalBackgroundClassName="position-absolute top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width"
        modalBodyClassName="box-shadow-none"
        height="height-60-percentage"
        width="width-60-percentage"
      >
        <PetitionsPreviewModal
          petitionData={
            props.petitions.data?.[props.table.petitions.selectedPetition] &&
            props.petitions.data?.[props.table.petitions.selectedPetition]
          }
          petitionsAssetsLoading={props.petitionsAssetsLoading}
          petitionLoading={props.petitions.loading}
          auth={props.auth}
          setPreviewFile={(data, type) => {
            setPreviewFile({
              show: true,
              type: type,
              data: type === "pdf" ? PDFArrayBuffer[data] : data,
            });
          }}
          fixPetitions={(data) => {
            props.fixPetitions(
              data.documentId,
              data.locationId,
              props.auth.data.uid,
              data.proof
            );
          }}
          petitionTakeover={(documentId) => {
            props.petitionTakeover(documentId, {
              uid: props.auth.data.uid,
              email: props.auth.data.email,
            });
          }}
          verifyPetition={(data, type) => {
            props.verifyPetition(data, type);
          }}
          // assetsLoading={props.proceduresAssetsLoading}
          sensors={props.sensors}
          theme={props.theme}
        />
      </Modal>
      <Modal
        show={
          props.table.selectedTable === tableTitles.jobs &&
          props.table.jobs?.selectedJob &&
          props.jobs.data?.[props.table?.jobs?.selectedJob] &&
          !props.enableFilter
        }
        canIgnore={true}
        onClose={() => {
          props.setSelectedTableId(tableTitles.jobs, null);
        }}
        borderRadius="false"
        boxShadow="box-shadow-none"
        modalBackgroundClassName="position-absolute top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width"
        modalBodyClassName="box-shadow-none"
        height="height-60-percentage"
        width="width-60-percentage"
      >
        <JobsPreview
          jobData={
            props.jobs.data?.[props.table.jobs?.selectedJob] &&
            props.jobs.data?.[props.table.jobs?.selectedJob]
          }
          updateJobStatus={(documentId) => {
            props.updateJobStatus(documentId);
          }}
          jobsAssetsLoading={props.jobsAssetsLoading}
          petitionLoading={props.jobs.loading}
          auth={props.auth}
          theme={props.theme}
        />
      </Modal>
      <Modal
        show={
          props.table.selectedTable === tableTitles.volunteering &&
          props.table?.volunteering?.selectedVolunteering &&
          props.volunteering.data?.[
            props.table?.volunteering?.selectedVolunteering
          ] &&
          !props.enableFilter
        }
        canIgnore={true}
        onClose={() => {
          props.setSelectedTableId(tableTitles.volunteering, null);
        }}
        borderRadius="false"
        boxShadow="box-shadow-none"
        modalBackgroundClassName="position-absolute top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width"
        modalBodyClassName="box-shadow-none"
        height="height-60-percentage"
        width="width-60-percentage"
      >
        <VolunteeringPreview
          volunteeringData={
            props.volunteering.data?.[
              props.table.volunteering?.selectedVolunteering
            ] &&
            props.volunteering.data?.[
              props.table.volunteering?.selectedVolunteering
            ]
          }
          updateVolunteeringStatus={(documentId) => {
            props.updateVolunteeringStatus(documentId);
          }}
          jobsAssetsLoading={props.jobsAssetsLoading}
          petitionLoading={props.volunteering.loading}
          auth={props.auth}
          theme={props.theme}
        />
      </Modal>
      <Modal
        show={previewFile?.show}
        canIgnore={true}
        onClose={() => {
          setPreviewFile({
            show: false,
            type: null,
            data: null,
          });
        }}
        borderRadius="false"
        boxShadow="false"
      >
        {previewFile.type === "image" && (
          <div
            className="display-flex flex-justify-content-center"
            data-cy={"view-image-modal"}
          >
            <img src={previewFile.data} height={500} alt="preview" />
          </div>
        )}

        {previewFile.type === "pdf" && (
          <div
            style={{ maxHeight: "90%" }}
            className=" padding-left-larger padding-right-larger hide-scroll-bar overflow-auto inherit-parent-height"
          >
            <div
              style={{ minHeight: "500px", maxHeight: "900px" }}
              className="display-flex inherit-parent-width flex-justify-content-center"
            >
              <PDFRenderer
                readOnly={true}
                pdfArrayBuffer={previewFile.data && previewFile.data}
                saveWithBackground={false}
                onPDFChange={() => {
                  if (isPdfLoading === false) {
                    setIsPdfLoading(true);
                  }
                }}
                onRender={() => {
                  if (isPdfLoading === true) {
                    setIsPdfLoading(false);
                  }
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

const mapStateToProps = function(state) {
  return {
    petitions: state.petitions,
    announcements: state.announcements,
    table: state.table,
    jobs: state.jobs,
    volunteering: state.volunteering,
    auth: state.auth,
  };
};

const mapDispatchToProps = function() {
  return {
    setTableFilter: (table, filter) => setTableFilter(table, filter),
    setSelectedTable: (title) => setSelectedTable(title),
    setSelectedTableId: (table, id) => setSelectedTableId(table, id),
    updateJobStatus: (documentId) => updateJobStatus(documentId),
    updateVolunteeringStatus: (documentId) =>
      updateVolunteeringStatus(documentId),
    verifyPetition: (compliantData, type) =>
      verifyPetition(compliantData, type),
    fixPetitions: (complaintId, locationId, employeeId, proof) =>
      fixPetitions(complaintId, locationId, employeeId, proof),
    petitionTakeover: (complaintId, employeeData) =>
      petitionTakeover(complaintId, employeeData),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);

const getPdfArrayBufferData = async (url) => {
  try {
    const res = await fetch(url);
    if (!res.ok) {
      throw new Error("Network response was not ok");
    }
    const arrayBuffer = await res.arrayBuffer();
    return arrayBuffer;
  } catch (error) {
    setErrorStatus({
      custom: "custom",
      message: "Unable to download",
    });
    console.error(error);
  }
};

function Fallback(props) {
  return (
    <div
      className="inherit-parent-height flex-justify-content-space-around flex-direction-column"
      data-cy={`fallback-${props.dataCy}`}
    >
      <div
        className=" inherit-parent-height inherit-parent-width flex-justify-content-center flex-align-items-center flex-direction-column"
        data-cy={"no-Jobs-fallback"}
      >
        <FallBackImage type={props.type} />
        <div className=" padding-top-larger font-family-RHD-medium font-size-larger text-align-center">
          {props.title}
        </div>
        <div className=" font-size-medium text-align-center padding-top-default">
          {props.description}
        </div>
        <div className="padding-top-larger">
          <div
            style={{ width: "300px", height: "44px" }}
            className=" flex-justify-content-center flex-align-items-center border-1px-e5e5e5 border-radius-default"
          >
            COMING SOON
          </div>
        </div>
      </div>
    </div>
  );
}

const FallBackImage = (props) => {
  return (
    <div>
      {props.type === "volunteer" ? (
        <svg
          width="106"
          height="106"
          viewBox="0 0 106 106"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="6"
            y="6"
            width="94"
            height="94"
            rx="47"
            stroke="url(#paint0_linear_560_1077)"
            stroke-opacity="0.03"
            stroke-width="12"
          />
          <g opacity="0.8">
            <path
              d="M62.1391 41.1313C61.6823 41.1149 61.3034 41.4528 61.3034 41.9701V43.4211C61.41 48.7472 60.2971 53.1433 57.5296 55.4793L57.5187 55.4885L57.5077 55.4975C57.4807 55.5194 57.4491 55.5455 57.4149 55.5736C57.1964 55.7536 56.844 56.0436 56.5195 56.2481C54.9194 57.3458 53.0631 57.9234 51.0472 57.9234C49.0677 57.9234 47.2242 57.3301 45.6708 56.305C44.168 57.2297 43.1522 58.6974 43.1522 60.9888V64.8693H58.9422V60.3841C58.8284 58.3795 59.2592 56.3092 60.3717 54.5773L60.3756 54.5713L60.3795 54.5653C62.0595 52.0003 62.9426 48.341 62.8397 43.4165L62.8394 43.3998V42.0788C62.8394 41.8148 62.7392 41.559 62.5841 41.3775C62.4353 41.2033 62.2761 41.1377 62.1391 41.1313Z"
              fill="url(#paint1_linear_560_1077)"
            />
            <path
              d="M48.4431 47.0761C48.4431 48.6144 49.6195 49.7292 50.9934 49.7292C52.3331 49.7292 53.5436 48.5949 53.5436 47.0761C53.5436 45.5724 52.3481 44.4229 50.9934 44.4229C49.6729 44.4229 48.4431 45.592 48.4431 47.0761Z"
              fill="url(#paint2_linear_560_1077)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M53 28C46.3705 28 40.0098 30.6346 35.3218 35.3218C30.6343 40.0094 28 46.3698 28 53C28 59.6302 30.6346 65.9902 35.3218 70.6782C40.0094 75.3657 46.3698 78 53 78C59.6302 78 65.9902 75.3654 70.6782 70.6782C75.3657 65.9906 78 59.6302 78 53C77.9928 46.3723 75.3582 40.0166 70.6708 35.3292C65.9833 30.6417 59.6277 28.0074 53 28ZM58.088 41.9701C58.088 39.6629 59.9498 37.8276 62.2678 37.9184L62.273 37.9186C64.5795 38.0164 66.0548 40.0881 66.0548 42.0788V43.3663C66.163 48.6465 65.2319 53.0214 63.0735 56.3206C62.3829 57.3988 62.0653 58.7835 62.1546 60.2382L62.1576 60.2874V65.2816C62.1576 66.7946 60.9307 68.0848 59.3754 68.0848H42.719C41.1637 68.0848 39.9368 66.7946 39.9368 65.2816V60.9888C39.9368 56.8236 42.2827 54.2473 45.1527 52.949L46.0682 52.5348L46.8417 53.1763C48.0093 54.1447 49.4654 54.7079 51.0472 54.7079C52.4338 54.7079 53.6618 54.3156 54.7234 53.5809L54.7612 53.5547L54.8004 53.5308C54.9271 53.4534 55.0955 53.3159 55.3485 53.1092L55.4654 53.0139C57.0673 51.653 58.1925 48.5592 58.0883 43.4702L58.088 43.4537V41.9701ZM50.9934 52.9447C47.7902 52.9447 45.2277 50.3364 45.2277 47.0761C45.2277 43.8701 47.8436 41.2074 50.9934 41.2074C54.1965 41.2074 56.7591 43.8701 56.7591 47.0761C56.7591 50.3364 54.1432 52.9447 50.9934 52.9447Z"
              fill="url(#paint3_linear_560_1077)"
            />
            <path
              d="M61.5592 60.2748L61.4232 60.2831L61.5592 60.2748ZM53.0089 20H53C44.2488 20 35.8536 23.4774 29.6654 29.6645L29.665 29.665C23.4769 35.8531 20 44.2485 20 53C20 61.7519 23.4774 70.1465 29.6645 76.3346L29.665 76.335C35.8531 82.5231 44.2485 86 53 86C61.7519 86 70.1465 82.5226 76.3346 76.3355L76.335 76.335C82.5231 70.1469 86 61.7515 86 53L86 52.9913C85.9905 44.2463 82.5142 35.8589 76.3276 29.6724C70.1408 23.4856 61.7535 20.0097 53.0089 20Z"
              stroke="url(#paint4_linear_560_1077)"
              stroke-opacity="0.08"
              stroke-width="16"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_560_1077"
              x1="53"
              y1="12"
              x2="53"
              y2="94"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stop-opacity="0.6" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_560_1077"
              x1="53"
              y1="28"
              x2="53"
              y2="78"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stop-opacity="0.9" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_560_1077"
              x1="53"
              y1="28"
              x2="53"
              y2="78"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stop-opacity="0.9" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_560_1077"
              x1="53"
              y1="28"
              x2="53"
              y2="78"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stop-opacity="0.9" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_560_1077"
              x1="53"
              y1="28"
              x2="53"
              y2="78"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stop-opacity="0.6" />
            </linearGradient>
          </defs>
        </svg>
      ) : props.type === "summary" ? (
        <svg
          width="106"
          height="106"
          viewBox="0 0 106 106"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2296_506)">
            <mask
              id="mask0_2296_506"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="106"
              height="106"
            >
              <path d="M106 0H0V106H106V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_2296_506)">
              <path
                d="M100 53C100 27.0426 78.9574 6 53 6C27.0426 6 6 27.0426 6 53C6 78.9574 27.0426 100 53 100C78.9574 100 100 78.9574 100 53Z"
                stroke="url(#paint0_linear_2296_506)"
                stroke-opacity="0.03"
                stroke-width="12"
              />
              <path
                d="M53 78C66.8071 78 78 66.8071 78 53C78 39.1929 66.8071 28 53 28C39.1929 28 28 39.1929 28 53C28 66.8071 39.1929 78 53 78Z"
                fill="url(#paint1_linear_2296_506)"
                fill-opacity="0.8"
              />
              <path
                d="M38.5996 67.4016V64.5216H67.3996V67.4016H38.5996Z"
                fill="white"
              />
              <path
                d="M48.0625 45.1844H51.3539V61.6416H48.0625V45.1844Z"
                fill="white"
              />
              <path
                d="M61.2282 38.6016H64.5196V61.6416H61.2282V38.6016Z"
                fill="white"
              />
              <path
                d="M41.4796 51.7673H44.771V61.6416H41.4796V51.7673Z"
                fill="white"
              />
              <path
                d="M54.6453 48.4758H57.9368V61.6416H54.6453V48.4758Z"
                fill="white"
              />
              <path
                d="M29.6654 29.6645C23.4773 35.8526 20 44.2485 20 53C20 61.7519 23.4774 70.1465 29.6645 76.3346C35.8526 82.5227 44.2485 86 53 86C61.7519 86 70.1465 82.5226 76.3346 76.3355C82.5227 70.1474 86 61.7515 86 53V52.9913C85.9905 44.2463 82.5142 35.8589 76.3276 29.6724C70.1408 23.4856 61.7535 20.0097 53.0089 20H53C44.2488 20 35.8536 23.4774 29.6654 29.6645Z"
                stroke="url(#paint2_linear_2296_506)"
                stroke-opacity="0.08"
                stroke-width="16"
              />
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_2296_506"
              x1="53"
              y1="12"
              x2="53"
              y2="94"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1E1E1E" />
              <stop offset="1" stop-color="#1E1E1E" stop-opacity="0.6" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2296_506"
              x1="53"
              y1="28"
              x2="53"
              y2="78"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1E1E1E" />
              <stop offset="1" stop-color="#1E1E1E" stop-opacity="0.9" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_2296_506"
              x1="53"
              y1="28"
              x2="53"
              y2="78"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1E1E1E" />
              <stop offset="1" stop-color="#1E1E1E" stop-opacity="0.6" />
            </linearGradient>
            <clipPath id="clip0_2296_506">
              <rect width="106" height="106" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="106"
          height="106"
          viewBox="0 0 106 106"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="6"
            y="6"
            width="94"
            height="94"
            rx="47"
            stroke="url(#paint0_linear_398_10858)"
            stroke-opacity="0.03"
            stroke-width="12"
          />
          <g opacity="0.8">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M53 28C46.3705 28 40.0098 30.6346 35.3218 35.3218C30.6343 40.0094 28 46.3698 28 53C28 59.6302 30.6346 65.9902 35.3218 70.6782C40.0094 75.3657 46.3698 78 53 78C59.6302 78 65.9902 75.3654 70.6782 70.6782C75.3657 65.9906 78 59.6302 78 53C77.9928 46.3723 75.3582 40.0166 70.6708 35.3292C65.9833 30.6417 59.6277 28.0074 53 28ZM58.6345 47.5945L55.9815 41.7662C55.5077 40.8262 54.4648 40.168 53.3275 40.168C52.1912 40.168 51.2436 40.8262 50.6745 41.7662L48.0215 47.5945H58.6345ZM62.8985 56.6183L59.4876 49.4745H47.1693L43.6631 56.6183H62.8985ZM65.4571 61.9766L63.8461 58.5929H42.8099L41.2942 61.9766C41.1989 62.353 41.1045 62.7293 41.1045 63.0112C41.1045 64.6094 42.3361 65.8312 43.9472 65.8312H62.8985C64.5096 65.8312 65.7412 64.6094 65.7412 63.0112C65.6468 62.7293 65.5515 62.353 65.4571 61.9766Z"
              fill="url(#paint1_linear_398_10858)"
            />
            <path
              d="M53.0089 20H53C44.2488 20 35.8536 23.4774 29.6654 29.6645L29.665 29.665C23.4769 35.8531 20 44.2485 20 53C20 61.7519 23.4774 70.1465 29.6645 76.3346L29.665 76.335C35.8531 82.5231 44.2485 86 53 86C61.7519 86 70.1465 82.5226 76.3346 76.3355L76.335 76.335C82.5231 70.1469 86 61.7515 86 53L86 52.9913C85.9905 44.2463 82.5142 35.8589 76.3276 29.6724C70.1408 23.4856 61.7535 20.0097 53.0089 20Z"
              stroke="url(#paint2_linear_398_10858)"
              stroke-opacity="0.08"
              stroke-width="16"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_398_10858"
              x1="53"
              y1="12"
              x2="53"
              y2="94"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stop-opacity="0.6" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_398_10858"
              x1="53"
              y1="28"
              x2="53"
              y2="78"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stop-opacity="0.9" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_398_10858"
              x1="53"
              y1="28"
              x2="53"
              y2="78"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stop-opacity="0.6" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </div>
  );
};

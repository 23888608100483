import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";
import { updateJob } from "../../Services/database";
import { getFileUrl } from "../../Services/storage";
import { getJobAssets } from "./action";

export const actionTypes = {
  PUT_JOBS_DATA: "PUT_JOBS_DATA",
  GET_JOB_ASSETS: "GET_JOB_ASSETS",
  UPDATE_JOB_STATUS: "UPDATE_JOB_STATUS",
};

function* putJobsWorker(action) {
  try {
    yield setJobsLoading(true);
    yield put({
      type: "SET_JOBS_DATA",
      payload: {
        data: action.payload.data,
      },
    });

    yield setJobsLoading(false);
    Object.values(action.payload.data).forEach((data) => {
      getJobAssets(data);
    });
  } catch (error) {
    yield setJobsLoading(false);
    setErrorStatus(error);
  }
}

function* getJobAssetsWorker(action) {
  try {
    if (action.payload.data.company.logo) {
      yield put({
        type: "SET_JOBS_ASSETS",
        payload: {
          [action.payload.data.documentId]: {
            ...action.payload.data,
            company: {
              ...action.payload.data.company,
              logoSrc: yield getFileUrl(action.payload.data.company.logo),
            },
          },
        },
      });
    }
  } catch (error) {
    yield setJobsLoading(false);
    setErrorStatus(error);
  }
}

function* updateJobStatusWorker(action) {
  try {
    yield setJobsLoading(true);
    yield updateJob(action.payload.jobId);
    yield setJobsLoading(false);
  } catch (error) {
    yield setJobsLoading(false);
    setErrorStatus(error);
  }
}

export default function* jobsWatcher() {
  yield all([
    takeEvery("PUT_JOBS_DATA", putJobsWorker),
    takeEvery("GET_JOB_ASSETS", getJobAssetsWorker),
    takeEvery("UPDATE_JOB_STATUS", updateJobStatusWorker),
  ]);
}

function* setJobsLoading(bool) {
  yield put({
    type: "SET_JOBS_LOADING",
    payload: {
      loading: bool,
    },
  });
}

import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  alignPhoneNumber,
  capitalizeFirstLetter,
  dateAndTimeConverter,
  isDigit,
} from "../../Utils/constants";
import { isValidArray, isValidObject } from "../../Services/validators";
import {
  AscendingOrderIcon,
  DefaultSortIcon,
  DescendingOrderIcon,
} from "../../Assets/assets";
import SearchFallBackIcon from "../../Assets/SearchFallBackIcon.svg";

const FIELDS = {
  ID: "documentId",
  DESCRIPTION: "description",
  "RAISED BY": "name",
  STATUS: "currentStatus",
  "RAISED AT": "createdAt",
  "UPDATED AT": "updatedAt",
};

const tableHeadings = [
  { title: "ID", width: "100px" },
  { title: "DESCRIPTION", width: "400px" },
  { title: "RAISED BY", width: "300px" },
  { title: "STATUS", width: "130px" },
  { title: "RAISED AT", width: "170px" },
  { title: "UPDATED AT", width: "170px" },
];

export default function PetitionsTable(props) {
  const complaintsListRef = useRef();
  const [sortConfig, setSortConfig] = useState({ key: "", ascending: true });

  useEffect(() => {
    const container = complaintsListRef.current;
    const handleScroll = () => {
      if (
        Math.round(container.scrollTop + container.clientHeight) ===
        container.scrollHeight
      ) {
        if (props.petitions.paginationLoading === false) {
          props.getComplaintsNextPage();
        }
      }
    };
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line
  }, []);

  const sortedPetitions = useMemo(() => {
    if (!isValidObject(props.petitions.data)) {
      return [];
    }

    const field = FIELDS[sortConfig.key];
    if (!field) {
      return Object.values(props.petitions.data);
    }

    return Object.values(props.petitions.data).sort((a, b) => {
      let valueA = a[field];
      let valueB = b[field];

      if (sortConfig.key === "RAISED BY") {
        valueA = a.issuedBy?.userDetails?.[field]?.toUpperCase() || "N/A";
        valueB = b.issuedBy?.userDetails?.[field]?.toUpperCase() || "N/A";
      } else if (["STATUS", "UPDATED AT"].includes(sortConfig.key)) {
        valueA = a.status?.[field];
        valueB = b.status?.[field];
      } else {
        valueA =
          (typeof valueA === "string" ? valueA.toUpperCase() : valueA) || "N/A";
        valueB =
          (typeof valueB === "string" ? valueB.toUpperCase() : valueB) || "N/A";
      }

      if (isDigit(valueA) && isDigit(valueB)) {
        valueA = parseInt(valueA, 10);
        valueB = parseInt(valueB, 10);
      }

      if (valueA < valueB) {
        return sortConfig.ascending ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortConfig.ascending ? 1 : -1;
      }
      return 0;
    });
  }, [props.petitions.data, sortConfig]);

  const handleSort = (key) => {
    setSortConfig((prevSort) => ({
      key,
      ascending: prevSort.key === key ? !prevSort.ascending : true,
    }));
  };

  return (
    <div
      className="remaining-body-height-from-footer inherit-parent-height overflow-scroll"
      ref={complaintsListRef}
      style={{ maxWidth: "1400px" }}
      data-cy={"petitions-table"}
    >
      <table
        className={`inherit-parent-width ${
          isValidArray(sortedPetitions) ? "" : "inherit-parent-height"
        }`}
        style={{ borderCollapse: "collapse" }}
      >
        <thead>
          <tr
            style={{ position: "sticky", top: "0" }}
            className={`font-size-large ${
              props.theme === "dark"
                ? "background-color-dark"
                : "background-white"
            }`}
          >
            {tableHeadings.map((header, index) => (
              <th
                key={index}
                data-cy={`header-${header.title}`}
                className="padding-top-large padding-bottom-large font-size-medium font-family-RHD-medium cursor-pointer"
                style={{ minWidth: header.width }}
                onClick={() => handleSort(header.title)}
              >
                <div className="display-flex padding-horizontal-medium">
                  <div className="padding-right-default">{header.title}</div>
                  {sortConfig.key === header.title && (
                    <div
                      data-cy={`${header.title}-${
                        sortConfig.ascending ? "ascending" : "descending"
                      }-sort`}
                      className="height-fit-content width-fit-content"
                    >
                      {sortConfig.ascending ? (
                        <AscendingOrderIcon
                          color={props.theme === "dark" ? "white" : "#404040"}
                        />
                      ) : (
                        <DescendingOrderIcon
                          color={props.theme === "dark" ? "white" : "#404040"}
                        />
                      )}
                    </div>
                  )}
                  {sortConfig.key !== header.title && (
                    <div
                      data-cy={`${header.title}-default-sort`}
                      className="display-flex flex-align-items-center"
                    >
                      <DefaultSortIcon
                        color={props.theme === "dark" ? "white" : "#404040"}
                      />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody data-cy="table-content-area">
          {isValidArray(sortedPetitions) ? (
            sortedPetitions.map((data, index) => (
              <tr
                key={index}
                className={`font-size-default ${
                  props.theme === "dark"
                    ? "on-hover-background-color-404040"
                    : "on-hover-background-color-primary"
                } ${
                  props.theme === "dark" &&
                  props.table.petitions.selectedComplaint === data.documentId
                    ? "background-color-dark"
                    : "background-white"
                }`}
                onClick={() => props.setSelectedTableId(data.documentId)}
              >
                <td
                  data-cy={`${data.documentId}-compliant`}
                  className="padding-default cursor-pointer text-overflow-ellipsis"
                  style={{ maxWidth: "100px", minWidth: "100px" }}
                >
                  {data.documentId || "N/A"}
                </td>
                <td
                  data-cy={`${data.description}-compliant`}
                  className="padding-default cursor-pointer text-overflow-ellipsis"
                  style={{ maxWidth: "400px", minWidth: "400px" }}
                >
                  {data.description || "N/A"}
                </td>
                <td
                  data-cy={`${data.issuedBy?.userDetails?.phoneNumber}-compliant`}
                  className="padding-default text-align-left cursor-pointer white-space-no-wrap text-overflow-ellipsis"
                  style={{ maxWidth: "300px", minWidth: "300px" }}
                >
                  {isValidObject(data.issuedBy?.userDetails)
                    ? `${alignPhoneNumber(
                        data.issuedBy?.userDetails?.phoneNumber
                      )}, ${data.issuedBy?.userDetails?.name || "N/A"}`
                    : "N/A"}
                </td>
                <td
                  data-cy={`${data.status?.currentStatus}-compliant`}
                  className="padding-default cursor-pointer white-space-no-wrap text-overflow-ellipsis"
                  style={{ maxWidth: "130px", minWidth: "130px" }}
                >
                  {capitalizeFirstLetter(data.status?.currentStatus) || "N/A"}
                </td>
                <td
                  data-cy={`${dateAndTimeConverter(
                    data?.createdAt,
                    "thirdDate"
                  )}-compliant`}
                  className="padding-default cursor-pointer white-space-no-wrap"
                  style={{ maxWidth: "170px", minWidth: "170px" }}
                >
                  {data?.createdAt
                    ? `${dateAndTimeConverter(
                        data?.createdAt,
                        "Time"
                      )}, ${dateAndTimeConverter(data?.createdAt, "thirdDate")}`
                    : "N/A"}
                </td>
                <td
                  data-cy={`${dateAndTimeConverter(
                    data.status?.updatedAt,
                    "thirdDate"
                  )}-compliant`}
                  className="padding-default text-align-center cursor-pointer white-space-no-wrap text-overflow-ellipsis"
                  style={{ maxWidth: "170px", minWidth: "170px" }}
                >
                  {data?.status?.updatedAt
                    ? `${dateAndTimeConverter(
                        data.status?.updatedAt,
                        "Time"
                      )}, ${dateAndTimeConverter(
                        data.status?.updatedAt,
                        "thirdDate"
                      )}`
                    : "N/A"}
                </td>
              </tr>
            ))
          ) : (
            <tr className="inherit-parent-height inherit-parent-width">
              <td colSpan={6} className="text-center">
                <div className="inherit-parent-width font-size-default display-flex flex-direction-column flex-justify-content-center flex-align-items-center">
                  <img src={SearchFallBackIcon} alt="No matching results" />
                  <div className="padding-top-larger">NO MATCHING RESULTS</div>
                  <div className="padding-top-large">
                    There wasn’t any result matching to the search and filter
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

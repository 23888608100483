/**
 * @module AuthSaga
 */

import store from "../store";
import { authActionTypes } from "./saga";

/**
 * set selected table
 * @param {string} selectedTable
 */
export function setSelectedTable(selectedTable) {
  store.dispatch({
    type: authActionTypes.SET_SELECTED_TABLE,
    payload: { selectedTable: selectedTable },
  });
}

/**
 * set selected table
 * @param {string} table
 * @param {string} id
 */
export function setSelectedTableId(table, id) {
  store.dispatch({
    type: authActionTypes.SET_SELECTED_TABLE_ID,
    payload: { table: table, id: id },
  });
}

/**
 * set selected table
 * @param {string} table
 * @param {string} filter
 */
export function setTableFilter(table, filter) {
  store.dispatch({
    type: authActionTypes.SET_TABLE_FILTER,
    payload: { table: table, filter: filter },
  });
}

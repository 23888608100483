const authReducer = (
  state = {
    loading: false,
    data: {
      accessToken: null,
      uid: null,
      email: null,
    },
    credentials: {
      verificationId: null,
      authCredential: null,
    },
    theme: "dark",
  },
  action
) => {
  switch (action.type) {
    case "SET_AUTH_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_AUTH_INFO":
      return {
        ...state,
        data: {
          accessToken: action.payload.accessToken,
          uid: action.payload.uid,
          email: action.payload.email,
        },
      };

    case "SET_AUTH_VERIFICATION_ID":
      return {
        ...state,
        credentials: {
          ...state.credentials,
          verificationId: action.payload.verificationId,
        },
      };

    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        data: {
          ...state.data,
          accessToken: action.payload.accessToken,
        },
      };

    case "SET_AUTH_CREDENTIALS":
      return {
        ...state,
        credentials: {
          ...state.credentials,
          authCredential: action.payload.authCredential,
        },
      };

    case "CLEAR_AUTH_CREDENTIALS":
      return {
        ...state,
        credentials: {
          verificationId: null,
          authCredential: null,
        },
      };

    case "PUT_THEME":
      return {
        ...state,
        theme: action.payload.theme,
      };

    case "RESET":
      return {
        loading: false,
        data: {
          accessToken: null,
          uid: null,
          phoneNumber: null,
          displayName: null,
        },
        credentials: {
          verificationId: null,
          authCredential: null,
        },
        theme: state.theme,
      };

    default:
      return state;
  }
};
export default authReducer;

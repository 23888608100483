import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module AnnouncementsSaga
 */

/**
 *
 * @param {data} payload
 */

export function setJobsData(data) {
  store.dispatch({
    type: actionTypes.PUT_JOBS_DATA,
    payload: {
      data: data,
    },
  });
}

/**
 *
 * @param {data} payload
 */

export function updateJobStatus(documentId) {
  store.dispatch({
    type: actionTypes.UPDATE_JOB_STATUS,
    payload: {
      jobId: documentId,
    },
  });
}

export function getJobAssets(data) {
  store.dispatch({
    type: actionTypes.GET_JOB_ASSETS,
    payload: {
      data: data,
    },
  });
}

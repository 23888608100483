import React from "react";

export default function ControlButton(props) {
  return (
    <div
      className={` display-flex flex-align-items-center inherit-parent-width text-uppercase  margin-bottom-larger padding-left-default  text-overflow-ellipsis font-size-larger ${
        props.selected === true
          ? "bg-color-forSelected-type font-color-primary"
          : ""
      }  `}
      style={{
        height: "48px",
        width: "184px",
      }}
      onClick={() => {
        props?.onClick();
      }}
      data-cy={props?.dataCy}
    >
      <div className="display-flex flex-justify-content-space-between flex-align-items-center inherit-parent-height inherit-parent-width">
        <div className="font-family-RHD-medium">{props?.text}</div>
        {props.selected === true && (
          <div
            style={{
              width: "4px",
              height: "48px",
              backgroundColor: "#4362ea",
            }}
          />
        )}
      </div>
    </div>
  );
}

import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";
import { updateVolunteering } from "../../Services/database";
import { getFileUrl } from "../../Services/storage";
import { getVolunteeringAssets } from "./action";

export const actionTypes = {
  PUT_VOLUNTEERING_DATA: "PUT_VOLUNTEERING_DATA",
  GET_VOLUNTEERING_ASSETS: "GET_VOLUNTEERING_ASSETS",
  UPDATE_VOLUNTEERING_STATUS: "UPDATE_VOLUNTEERING_STATUS",
};

function* putVolunteeringWorker(action) {
  try {
    yield setVolunteeringLoading(true);

    yield put({
      type: "SET_VOLUNTEERING_DATA",
      payload: {
        data: action.payload.data,
      },
    });
    yield setVolunteeringLoading(false);
    Object.values(action.payload.data)?.forEach((data) => {
      getVolunteeringAssets(data);
    });
  } catch (error) {
    yield setVolunteeringLoading(false);
    setErrorStatus(error);
  }
}

function* getVolunteeringAssetsWorker(action) {
  try {
    yield put({
      type: "SET_VOLUNTEERING_ASSETS",
      payload: {
        [action.payload.data.documentId]: {
          ...action.payload.data,
          thumbnailSrc: yield getFileUrl(action.payload.data.thumbnail),
        },
      },
    });
  } catch (error) {
    yield setVolunteeringLoading(false);
    setErrorStatus(error);
  }
}

function* updateVolunteeringStatusWorker(action) {
  try {
    yield setVolunteeringLoading(true);
    yield updateVolunteering(action.payload.volunteeringId);
    yield setVolunteeringLoading(false);
  } catch (error) {
    yield setVolunteeringLoading(false);
    setErrorStatus(error);
  }
}

export default function* volunteeringWatcher() {
  yield all([
    takeEvery("PUT_VOLUNTEERING_DATA", putVolunteeringWorker),
    takeEvery("GET_VOLUNTEERING_ASSETS", getVolunteeringAssetsWorker),
    takeEvery("UPDATE_VOLUNTEERING_STATUS", updateVolunteeringStatusWorker),
  ]);
}

function* setVolunteeringLoading(bool) {
  yield put({
    type: "SET_VOLUNTEERING_LOADING",
    payload: {
      loading: bool,
    },
  });
}

import { useEffect, useRef, useState, useCallback, memo } from "react";
import * as pdfjs from "pdfjs-dist/legacy/build/pdf";
import Button from "../Button/Button";
import { PDFDocument } from "pdf-lib";
import Modal from "../Modal/Modal";
import InputBox from "../InputBox/InputBox";
import "./PDFRenderer.css";
import {
  BrushIcon,
  CloseIcon,
  ProfileEditIcon,
  TextIcon,
  UndoIcon,
} from "../../Assets/assets";

function PDFRenderer(props) {
  // const history = useHistory();
  const [showColorOptions, setShowColorOptions] = useState(false);
  const [showBrushOptions, setShowBrushOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inputTextMode, setInputTextMode] = useState(false);
  const pointersRef = useRef({});
  const notesInputRef = useRef(null);
  const [page, setPage] = useState(1);
  // const [hasAddedNotes, setHasAddedNotes] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [loadedPDF, setLoadedPDF] = useState(null);
  const [loadedFabric, setLoadedFabric] = useState(null);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  // const [renderCount, setRenderCount] = useState(0);
  const fabricCanvas = useRef({
    fabric: null,
    arrayBuffer: null,
    pdfLibDoc: null,
    canvas: null,
    zoomScale: 1,
    panning: false,
    lastX: null,
    lastY: null,
    pausePanning: false,
    image: {
      height: 0,
      width: 0,
    },
  });
  const canvasContainer = useRef(null);
  const rootContainer = useRef(null);
  const isRendering = useRef(false);
  const [data, setData] = useState(props.notes ? props.notes : {});
  const [properties, setProperties] = useState({
    color: "rgba(78,78,78,1)",
    rgba: "rgba(78,78,78,0.5)",
    width: 4,
  });
  pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

  const loadPDF = async () => {
    setLoadedPDF(null);
    if (typeof props.onPDFChange === "function") {
      props.onPDFChange();
    }
    const loadingTask = pdfjs.getDocument({
      data: props.pdfArrayBuffer,
    });
    fabricCanvas.current.pdfLibDoc = await PDFDocument.load(
      props.pdfArrayBuffer
    );
    const pdf = await loadingTask.promise;
    setLoadedPDF(pdf);
  };

  // set editable or not
  useEffect(() => {
    if (
      Object.values(data).length > 0 &&
      props.notes !== null &&
      JSON.stringify(props.notes) !== JSON.stringify(data)
    ) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // set loaded fabric
  useEffect(() => {
    if (props.readOnly === false) {
      import("../../Services/fabric").then((importedFabric) => {
        fabricCanvas.current.fabric = importedFabric.fabric;
        setLoadedFabric(true);
      });
    }
  }, [props.readOnly]);

  const removePreExistingCanvas = () => {
    document.getElementById("pdfCanvas").remove();
    if (document.getElementById("pdfCanvas")) {
      removePreExistingCanvas();
    }
  };

  const renderPDF = async () => {
    try {
      if (loadedPDF === null) {
        return;
      }
      // if (!rootContainer.current) {
      //   if (renderCount <= 10) {
      //     setRenderCount(renderCount + 1);
      //     renderPDF();
      //   }
      // }
      // setRenderCount(0);
      let pdf = loadedPDF;
      setTotalPages(pdf.numPages);
      const currentPage = await pdf.getPage(page);
      const scale = 2;
      const viewport = currentPage.getViewport({ scale: scale });

      const canvas = document.createElement("canvas");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      canvas.style.width = "100%";
      canvas.style.height = "auto";
      canvas.id = "pdfCanvas";
      if (props.readOnly === false) {
        canvas.style.display = "none";
      }
      canvas.setAttribute("canvas-state", "CREATED");
      // Render PDF page into canvas context
      const context = canvas.getContext("2d");
      const renderContext = { canvasContext: context, viewport: viewport };
      const renderTask = currentPage.render(renderContext);
      await renderTask.promise;
      if (document.getElementById("pdfCanvas")) {
        removePreExistingCanvas();
        rootContainer.current.appendChild(canvas);
      } else {
        rootContainer.current.appendChild(canvas);
      }
      canvas.setAttribute("canvas-state", "MOUNTED");
      if (props.readOnly !== false) {
        if (typeof props.onRender === "function") {
          props.onRender();
          isRendering.current = false;
        }
        return;
      }

      if (fabricCanvas.current.canvas === null) {
        fabricCanvas.current.canvas = new fabricCanvas.current.fabric.Canvas(
          "canvas",
          {
            width: canvasContainer.current.offsetWidth,
            height:
              (viewport.height / viewport.width) *
              canvasContainer.current.offsetWidth,
            selection: false,
          }
        );
      }

      // Set the rendered PDF as background image to FabricJS
      var bg = new Image();
      bg.src = document.getElementById("pdfCanvas").toDataURL("image/png");
      fabricCanvas.current.fabric.Image.fromObject(bg, function(img) {
        fabricCanvas.current.canvas.setBackgroundImage(
          img,
          fabricCanvas.current.canvas.renderAll.bind(
            fabricCanvas.current.canvas
          ),
          {
            scaleX: fabricCanvas.current.canvas.width / img.width,
            scaleY: fabricCanvas.current.canvas.height / img.height,
          }
        );

        if (typeof props.onRender === "function" && !data[page]) {
          props.onRender();
        }
        const fabricMountedCanvas = document.getElementById("canvas");
        fabricMountedCanvas.setAttribute("canvas-state", "BG-IMG-PAINTED");
      });

      // Set the existing notes as overlay image to FabricJS
      if (data[page]) {
        fabricCanvas.current.fabric.Image.fromURL(data[page], function(
          notesImg
        ) {
          fabricCanvas.current.canvas.setOverlayImage(
            notesImg,
            fabricCanvas.current.canvas.renderAll.bind(
              fabricCanvas.current.canvas
            ),
            {
              scaleX: fabricCanvas.current.canvas.width / notesImg.width,
              scaleY: fabricCanvas.current.canvas.height / notesImg.height,
            }
          );
          if (typeof props.onRender === "function") {
            props.onRender();
          }
          const fabricMountedCanvas = document.getElementById("canvas");
          fabricMountedCanvas.setAttribute(
            "canvas-state",
            "OVERLAY-IMG-PAINTED"
          );
        });
      }

      // const fabricMountedCanvas = document.getElementById("canvas");
      // fabricMountedCanvas.setAttribute("canvas-state", "PAINTED");

      fabricCanvas.current.canvas.on({
        "after:render": function(e) {
          const fabricMountedCanvas = document.getElementById("canvas");
          fabricMountedCanvas.setAttribute("canvas-state", "RENDERED");
        },
      });

      // Enable pinch zoom and pan
      fabricCanvas.current.canvas.on({
        "touch:gesture": function(e) {
          if (
            e.e.touches &&
            e.e.touches.length === 2 &&
            fabricCanvas.current.panning === false
          ) {
            var point = new fabricCanvas.current.fabric.Point(
              e.self.x,
              e.self.y
            );
            let scale = 1;
            if (e.self.scale > 1) scale = (e.self.scale % 1) / 10 + 1;
            if (e.self.scale < 1) scale = 1 - (1 - e.self.scale) / 10;
            if (fabricCanvas.current.canvas.getZoom() * scale >= 1) {
              if (fabricCanvas.current.zoomScale !== scale) {
                fabricCanvas.current.zoomScale = scale;
                var delta = fabricCanvas.current.canvas.getZoom() * scale;
                fabricCanvas.current.canvas.zoomToPoint(point, delta);
              }
            } else {
              fabricCanvas.current.canvas.setViewportTransform([
                1,
                0,
                0,
                1,
                0,
                0,
              ]);
            }
          }
        },
      });
      fabricCanvas.current.canvas.on({
        "touch:drag": function(event) {
          var e = event.e;
          if (
            fabricCanvas.current.pausePanning === true ||
            fabricCanvas.current.canvas.isDrawingMode === true ||
            e.touches === undefined ||
            e.touches[0].clientX === undefined ||
            e.touches[0].clientY === undefined ||
            e.touches.length === 2
          ) {
            fabricCanvas.current.panning = false;
            return;
          }
          if (fabricCanvas.current.panning) {
            if (
              fabricCanvas.current.canvas.viewportTransform[4] +
                e.touches[0].clientX -
                fabricCanvas.current.lastX <=
                0 &&
              Math.abs(
                fabricCanvas.current.canvas.viewportTransform[4] +
                  e.touches[0].clientX -
                  fabricCanvas.current.lastX
              ) <
                fabricCanvas.current.canvas.getWidth() *
                  fabricCanvas.current.canvas.getZoom() -
                  fabricCanvas.current.canvas.getWidth()
            ) {
              fabricCanvas.current.canvas.viewportTransform[4] +=
                e.touches[0].clientX - fabricCanvas.current.lastX;
              fabricCanvas.current.lastX = e.touches[0].clientX;
            }
            if (
              fabricCanvas.current.canvas.viewportTransform[5] +
                e.touches[0].clientY -
                fabricCanvas.current.lastY <=
                0 &&
              Math.abs(
                fabricCanvas.current.canvas.viewportTransform[5] +
                  e.touches[0].clientY -
                  fabricCanvas.current.lastY
              ) <
                fabricCanvas.current.canvas.getHeight() *
                  fabricCanvas.current.canvas.getZoom() -
                  fabricCanvas.current.canvas.getHeight()
            ) {
              fabricCanvas.current.canvas.viewportTransform[5] +=
                e.touches[0].clientY - fabricCanvas.current.lastY;
              fabricCanvas.current.lastY = e.touches[0].clientY;
            }
            fabricCanvas.current.canvas.requestRenderAll();
          } else {
            fabricCanvas.current.panning = true;
            fabricCanvas.current.lastX = e.touches[0].clientX;
            fabricCanvas.current.lastY = e.touches[0].clientY;
          }
        },
      });
      fabricCanvas.current.canvas.on({
        "selection:created": function(e) {
          fabricCanvas.current.pausePanning = true;
        },
      });
      fabricCanvas.current.canvas.on({
        "selection:cleared": function(e) {
          fabricCanvas.current.pausePanning = false;
        },
      });
      fabricCanvas.current.canvas.on({
        "object:added": function(e) {
          var activeObject = e.target;
          if (activeObject.type === "path") {
            var lastItemIndex =
              fabricCanvas.current.canvas.getObjects().length - 1;
            var item = fabricCanvas.current.canvas.item(lastItemIndex);
            item.selectable = false;
          }
          setIsEdited(true);
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // const exportData = async () => {
  //   let fileConvertedUrl;
  //   let notesStringObj = {};
  //   if (
  //     fabricCanvas.current.canvas.getObjects().length > 0 ||
  //     hasAddedNotes === true
  //   ) {
  //     fabricCanvas.current.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  //     fabricCanvas.current.canvas.backgroundImage = null;
  //     fileConvertedUrl = fabricCanvas.current.canvas.toDataURL({
  //       left: 0,
  //       top: 0,
  //       format: "png",
  //       quality: 1
  //     });
  //     notesStringObj = {
  //       ...data,
  //       [page]: fileConvertedUrl
  //     };
  //     setData({
  //       ...data,
  //       [page]: fileConvertedUrl
  //     });

  //     for (const [pageNo, data] of Object.entries(notesStringObj)) {
  //       let image = await fabricCanvas.current.pdfLibDoc.embedPng(
  //         Uint8Array.from(atob(data.split(",")[1]), (c) => c.charCodeAt(0))
  //       );
  //       fabricCanvas.current.pdfLibDoc.getPages()[pageNo - 1].drawImage(image, {
  //         x: 0,
  //         y: 0,
  //         width: fabricCanvas.current.pdfLibDoc
  //           .getPages()
  //           [pageNo - 1].getWidth(),
  //         height: fabricCanvas.current.pdfLibDoc
  //           .getPages()
  //           [pageNo - 1].getHeight()
  //       });
  //     }
  //     const pdfBytes = await fabricCanvas.current.pdfLibDoc.save();
  //     var bytes = new Uint8Array(pdfBytes);
  //     var blob = new Blob([bytes], { type: "application/pdf" });
  //     // const docUrl = URL.createObjectURL(blob);
  //     // window.open(docUrl);
  //     return blob;
  //   }
  // };

  const changeColor = useCallback(() => {
    fabricCanvas.current.canvas.freeDrawingBrush.color = properties.color;
  }, [properties]);

  const changeWidth = useCallback(() => {
    fabricCanvas.current.canvas.freeDrawingBrush.width = properties.width;
  }, [properties]);

  const flipPage = (pageNumber) => {
    if (props.readOnly === false) {
      if (fabricCanvas.current.canvas.getObjects().length > 0) {
        fabricCanvas.current.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        fabricCanvas.current.canvas.backgroundImage = null;
        setData({
          ...data,
          [page]: fabricCanvas.current.canvas.toDataURL({
            left: 0,
            top: 0,
            format: "png",
            quality: 1,
          }),
        });
        // setHasAddedNotes(true);
      }
      fabricCanvas.current.canvas.overlayImage = null;
      fabricCanvas.current.canvas.renderAll.bind(fabricCanvas.current.canvas);
      clearAll();
    }
    setPage(pageNumber);
  };

  const showNotesInputModal = (e) => {
    const pointer = fabricCanvas.current.canvas.getPointer(e.e);
    pointersRef.current["posX"] = pointer.x;
    pointersRef.current["posY"] = pointer.y;
    fabricCanvas.current.canvas.off("mouse:up", showNotesInputModal);
    setShowModal(true);
  };

  const addText = () => {
    if (notesInputRef.current && notesInputRef.current.trim().length !== 0) {
      var text = new fabricCanvas.current.fabric.Text(notesInputRef.current, {
        left: pointersRef.current.posX,
        top: pointersRef.current.posY,
        textAlign: "center",
        textBackgroundColor: properties.rgba,
      });
      fabricCanvas.current.canvas.add(text);
    }
    setShowModal(false);
    setInputTextMode(false);
  };

  const addTextListener = () => {
    setIsDrawingMode(false);
    setInputTextMode(true);
    fabricCanvas.current.canvas.on("mouse:up", showNotesInputModal);
  };

  const undo = () => {
    var lastItemIndex = fabricCanvas.current.canvas.getObjects().length - 1;
    var item = fabricCanvas.current.canvas.item(lastItemIndex);
    if (
      item !== undefined &&
      (item.get("type") === "path" || item.get("type") === "text")
    ) {
      fabricCanvas.current.canvas.remove(item);
      fabricCanvas.current.canvas.renderAll();
    }
  };

  const onSelectColor = (event, r, g, b) => {
    event.stopPropagation();
    setProperties({
      ...properties,
      rgba: `rgba(${r},${g},${b},0.5)`,
      color: `rgba(${r},${g},${b},1)`,
    });
    setShowColorOptions(false);
  };

  const onSelectSize = (event, width) => {
    event.stopPropagation();
    setProperties({
      ...properties,
      width: width,
    });
    setShowBrushOptions(false);
  };

  const clearAll = () => {
    fabricCanvas.current.canvas.remove(
      ...fabricCanvas.current.canvas.getObjects()
    );
  };
  // to load pdf if array buffer is given
  useEffect(() => {
    if (props.pdfArrayBuffer) {
      loadPDF();
    }
    // eslint-disable-next-line
  }, [props.pdfArrayBuffer]);

  //to render pdf
  useEffect(() => {
    if (props.readOnly !== false) {
      if (loadedPDF !== null && loadedPDF !== undefined) {
        renderPDF();
      }
    } else if (props.readOnly === false) {
      if (
        loadedPDF !== null &&
        loadedPDF !== undefined &&
        loadedFabric === true
      ) {
        renderPDF();
      }
    }
    // eslint-disable-next-line
  }, [loadedPDF, props.readOnly, loadedFabric, page, data]);

  // set notes data
  useEffect(() => {
    if (props.notes) {
      setData({ ...data, ...props.notes });
    }
    // eslint-disable-next-line
  }, [props.notes]);

  // to change color and width of font in notes
  useEffect(() => {
    if (fabricCanvas.current.canvas) {
      fabricCanvas.current.canvas.isDrawingMode = isDrawingMode;
      changeColor();
      changeWidth();
    }
  }, [isDrawingMode, changeColor, changeWidth]);

  const isDisplayFooter = () => {
    // readOnly true display footer else hide
    if (
      props.readOnly ||
      loadedPDF == null ||
      loadedPDF === undefined ||
      !!!loadedFabric
    ) {
      //don`t Show when readOnly mode is enabled
      return false;
    } else {
      if (!props.readOnly || props.isPrescription) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <Modal
        onClickClose={() => {
          setShowModal(false);
        }}
        show={showModal}
        position="position-fixed top-0 right-0 bottom-0 left-0"
        boxShadow="none"
        borderRadius="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="inherit-parent-height"
      >
        <main
          data-cy="add-text-modal"
          className="flex-place-children-page-center inherit-parent-width inherit-parent-height padding-larger"
        >
          <div className="inherit-parent-width max-width-500px background-white padding-larger border-radius-default">
            <form
              onChange={(event) => {
                notesInputRef.current = event.target.value;
              }}
              onSubmit={(event) => {
                event.preventDefault();
                addText();
              }}
            >
              <InputBox
                className="inherit-parent-width border-radius-default"
                name="notes"
                label="Notes"
                type="text"
                autoComplete="off"
                autoFocus={true}
              />
            </form>
            <div className="margin-top-larger flex-justify-content-space-between">
              <Button
                id="submit-btn"
                text="Add"
                onClick={() => {
                  addText();
                }}
                boxShadow={false}
                data-cy="add-notes-btn"
              />
              <Button
                className="margin-left-medium"
                text="Cancel"
                variant="secondary"
                onClick={(_) => {
                  fabricCanvas.current.canvas.off(
                    "mouse:up",
                    showNotesInputModal
                  );
                  setShowModal(false);
                  setInputTextMode(false);
                }}
                disabled={false}
                boxShadow={false}
              />
            </div>
          </div>
        </main>
      </Modal>
      {totalPages === 0 || totalPages === 1 || page === 1 ? null : (
        <button
          data-cy="navigate-previous-page"
          className=" position-absolute z-index-1 height-width-30px top-50-percentage transform-translateY--50-percentage border-radius-50-percentage background-color-transparent-black-lite left-3px "
          onClick={() => {
            page > 1 && flipPage(page - 1);
          }}
        >
          <div className="transform-rotate-180-deg inherit-parent-height inherit-parent-width flex-place-children-page-center">
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99994 5.58624C7.78095 6.36726 7.78099 7.63351 7.00003 8.41458L2.12164 13.2936C1.73113 13.6841 1.09795 13.6842 0.707428 13.2936C0.316927 12.9031 0.31693 12.27 0.707434 11.8795L5.58606 7.00082L0.706598 2.12131C0.316083 1.73079 0.316081 1.09764 0.706594 0.707117C1.09711 0.316588 1.73028 0.316584 2.12081 0.707109L6.99994 5.58624Z"
                fill="#ffff"
              />
            </svg>
          </div>

          {/* <img
								src={nextPrevIcon}
								alt="previous_icon"
							/> */}
        </button>
      )}
      {page === totalPages ? null : (
        <button
          data-cy="navigate-next-page"
          className="position-absolute z-index-1 height-width-30px top-50-percentage transform-translateY--50-percentage border-radius-50-percentage background-color-transparent-black-lite right-3px"
          onClick={() => {
            page < totalPages && flipPage(page + 1);
          }}
        >
          <div className="inherit-parent-height inherit-parent-width flex-place-children-page-center">
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99994 5.58624C7.78095 6.36726 7.78099 7.63351 7.00003 8.41458L2.12164 13.2936C1.73113 13.6841 1.09795 13.6842 0.707428 13.2936C0.316927 12.9031 0.31693 12.27 0.707434 11.8795L5.58606 7.00082L0.706598 2.12131C0.316083 1.73079 0.316081 1.09764 0.706594 0.707117C1.09711 0.316588 1.73028 0.316584 2.12081 0.707109L6.99994 5.58624Z"
                fill="#ffff"
              />
            </svg>
          </div>
        </button>
      )}
      {/* Navigation Back PopUp */}
      {/* <Modal
				onClickClose={() => {
					props.setShowWarningModal && props.setShowWarningModal(false)
				}}
				show={props.showWarningModal}
				position="position-fixed top-0 right-0 bottom-0 left-0"
				boxShadow="none"
				borderRadius="none"
				width="inherit-parent-width"
				background="background-transparent"
				height="inherit-parent-height"
			>
				<main
					data-cy="navigation-back-confirmation-modal"
					className="flex-place-children-page-center inherit-parent-width inherit-parent-height padding-larger"
				>
					<div className="inherit-parent-width max-width-500px background-white padding-larger border-radius-default">
						<form
							onChange={(event) => {
								notesInputRef.current = event.target.value;
							}}
							onSubmit={(event) => {
								event.preventDefault();
							}}
						>
							<InputBox
								className="inherit-parent-width border-radius-default"
								name="should"
								label="should"
								type="text"
								autoComplete="off"
								autoFocus={true}
							/>
						</form>
						<div className="margin-top-larger flex-justify-content-space-between">
							<Button
								id="submit-btn"
								text="Add"
								onClick={() => {
								}}
								boxShadow={false}
								data-cy="add-notes-btn"
							/>
							<Button
								className="margin-left-medium"
								text="Cancel"
								variant="secondary"
								onClick={(_) => {
									props.setShowWarningModal(false)
								}}
								disabled={false}
								boxShadow={false}
							/>
						</div>
					</div>
				</main>
			</Modal> */}
      {/*  */}
      {((loadedPDF === null || loadedPDF === undefined) &&
        props.readOnly === true) ||
      ((loadedPDF === null || loadedPDF === undefined) &&
        props.readOnly === false &&
        loadedFabric === false) ? (
        <PDFrendererSuspense />
      ) : (
        <div
          ref={rootContainer}
          data-cy="pdf-renderer-canvas"
          className={`${
            props.readOnly === false
              ? " inherit-parent-height display-flex flex-direction-column position-relative"
              : "height-fit-content margin-auto position-relative"
          }`}
        >
          <canvas id="pdfCanvas" style={{ display: "none" }} />
          {props.readOnly === false && loadedFabric === true && (
            <div
              ref={canvasContainer}
              className={`flex-grow-1 position-relative flex-place-children-page-center hide-scroll-bar ${
                props.isPrescription ? "justify-content-start" : ""
              }`}
            >
              <canvas id="canvas" />
            </div>
          )}

          {isDisplayFooter() && (
            <>
              <footer
                data-cy="pdf-editing-tools-footer"
                className="margin-top-small background-color-white padding-default flex-place-children-page-center inherit-parent-width box-shadow-default border-top-radius-default"
              >
                <section className="padding-default edit-document-options inherit-parent-width max-width-588px flex-center-children-vertically flex-justify-content-space-between">
                  <button
                    data-cy={`drawing-mode-${
                      isDrawingMode ? "selected" : "unselected"
                    }`}
                    onClick={() => {
                      if (props.loading) return;
                      if (showColorOptions) {
                        setShowColorOptions(false);
                      }
                      if (showBrushOptions) {
                        setShowBrushOptions(false);
                      }

                      if (inputTextMode !== true) {
                        setIsDrawingMode((prevState) => !prevState);
                      }
                    }}
                    className={`${
                      isDrawingMode
                        ? "background-primary "
                        : "background-color-white"
                    }`}
                  >
                    <div>
                      <ProfileEditIcon color={isDrawingMode ? "white" : ""} />
                    </div>
                  </button>

                  <button
                    className="edit-btn-with-options flex-place-children-page-center"
                    onClick={() => {
                      if (props.loading) return;
                      if (showColorOptions) {
                        setShowColorOptions(false);
                      }
                      setShowBrushOptions((prevState) => !prevState);
                    }}
                  >
                    <div
                      className={`edit-options-wrapper ${
                        showBrushOptions ? " display-block" : "display-none"
                      }`}
                    >
                      <div onClick={(event) => onSelectSize(event, 8)}>
                        <BrushIcon width={"21"} height={"12"} />
                      </div>{" "}
                      <div onClick={(event) => onSelectSize(event, 6)}>
                        <BrushIcon width={"18"} height={"10"} />
                      </div>{" "}
                      <div onClick={(event) => onSelectSize(event, 4)}>
                        <BrushIcon width={"16"} height={"8"} />
                      </div>
                    </div>

                    <div>
                      <BrushIcon
                        width={
                          properties.width === 8
                            ? "21"
                            : properties.width === 6
                            ? "18"
                            : null
                        }
                        height={
                          properties.width === 8
                            ? "12"
                            : properties.width === 6
                            ? "10"
                            : null
                        }
                      />
                    </div>
                  </button>

                  <button
                    onClick={() => {
                      if (props.loading) return;
                      if (showBrushOptions) {
                        setShowBrushOptions(false);
                      }
                      setShowColorOptions((prevState) => !prevState);
                    }}
                    className="edit-btn-with-options flex-place-children-page-center"
                  >
                    <div
                      className={`edit-options-wrapper ${
                        showColorOptions ? " display-block" : "display-none"
                      } `}
                    >
                      <span
                        className="background-color-black"
                        onClick={(event) => onSelectColor(event, 0, 0, 0)}
                      />
                      <span
                        className="background-color-white"
                        onClick={(event) => onSelectColor(event, 255, 255, 255)}
                      />
                      <span
                        className="background-color-dark-grey"
                        onClick={(event) => onSelectColor(event, 78, 78, 78)}
                      />
                      <span
                        className="background-color-primary-green"
                        onClick={(event) => onSelectColor(event, 0, 200, 128)}
                      />
                      <span
                        className="background-color-red"
                        onClick={(event) => onSelectColor(event, 230, 69, 57)}
                      />
                    </div>
                    <span
                      style={{
                        backgroundColor: properties.color,
                      }}
                    />
                  </button>

                  <button
                    data-cy={`add-text-option-btn-${
                      inputTextMode ? "selected" : "unselected"
                    }`}
                    onClick={() => {
                      if (props.loading) return;
                      addTextListener();
                    }}
                    className={`${
                      inputTextMode
                        ? "background-primary "
                        : "background-color-white"
                    }`}
                  >
                    <div>
                      <TextIcon color={inputTextMode ? "white" : ""} />
                    </div>
                  </button>

                  <button
                    className="background-color-white"
                    onClick={() => {
                      if (props.loading) return;
                      undo();
                    }}
                  >
                    <UndoIcon />
                  </button>

                  <button
                    className="background-color-white"
                    onClick={() => {
                      if (props.loading) return;
                      clearAll();
                    }}
                  >
                    <CloseIcon />
                  </button>
                </section>

                <section className="inherit-parent-width max-width-588px flex-center-children-vertically flex-justify-content-space-between ">
                  <Button
                    loading={props.loading}
                    id="add-btn"
                    data-cy="save-pdf-btn"
                    text="Save"
                    boxShadow={false}
                    className="margin-small"
                    onClick={async () => {
                      if (!props.loading) {
                        if (showBrushOptions) setShowBrushOptions(false);
                        if (showColorOptions) setShowColorOptions(false);
                        if (isDrawingMode) setIsDrawingMode(false);
                        if (showModal) setShowModal(false);
                        if (inputTextMode) setInputTextMode(false);

                        // const blob = await exportData();
                        // if (blob !== undefined && blob !== null) {
                        //   if (props.isPrescription) {
                        //     typeof props.addPrescription === "function" &&
                        //       props.addPrescription(blob, history);
                        //   } else {
                        //     typeof props.addNotes === "function" &&
                        //       props.addNotes(
                        //         {
                        //           pdfBlob: blob,
                        //           isDraft: false
                        //         },
                        //         history
                        //       );
                        //   }
                        // }
                      }
                    }}
                    disabled={!isEdited}
                  />
                  {/* <Button
											id="draft-btn"
											text="Draft"
											className="margin-small"
											variant="bordered"
											onClick={() => {
												const data = exportData();
												props.addNotes({
													pages: { ...data },
													isDraft: true
												});
											}}
											disabled={false}
										/> */}
                </section>
              </footer>
            </>
          )}
        </div>
      )}
    </>
  );
}
export default memo(PDFRenderer);
// export default PDFRenderer;

function PDFrendererSuspense() {
  return (
    <main className="inherit-parent-width padding-medium ">
      <ul className="margin-top-larger margin-bottom-larger list-style-none">
        <li
          className="padding-right-large shimmer margin-bottom-default"
          style={{ width: "80%", height: "2vh" }}
        ></li>
        <li
          className="padding-right-large shimmer margin-bottom-default"
          style={{ width: "90%", height: "2vh" }}
        ></li>
        <li
          className="padding-right-large shimmer margin-bottom-default"
          style={{ width: "70%", height: "2vh" }}
        ></li>
      </ul>
      <ul className="margin-top-larger margin-bottom-larger padding-vertical-larger list-style-none">
        <li
          className="padding-right-large shimmer margin-bottom-default"
          style={{ width: "80%", height: "2vh" }}
        ></li>
        <li
          className="padding-right-large shimmer margin-bottom-default"
          style={{ width: "90%", height: "2vh" }}
        ></li>
        <li
          className="padding-right-large shimmer margin-bottom-default"
          style={{ width: "70%", height: "2vh" }}
        ></li>
      </ul>
      <ul className="margin-top-larger margin-bottom-larger list-style-none">
        <li
          className="padding-right-large shimmer margin-bottom-default"
          style={{ width: "80%", height: "2vh" }}
        ></li>
        <li
          className="padding-right-large shimmer margin-bottom-default"
          style={{ width: "90%", height: "2vh" }}
        ></li>
        <li
          className="padding-right-large shimmer margin-bottom-default"
          style={{ width: "70%", height: "2vh" }}
        ></li>
      </ul>
    </main>
  );
}

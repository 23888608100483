import { isValidArray, isValidObject } from "../Services/validators";

export const Regex = {
  pinCode: /^[1-9][0-9]{5}$/gm,
  aadhaarNumber: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  phoneNumber: /^[6-9]\d{9}$/,
  healthId: /^(?=[a-zA-Z])([a-zA-Z0-9]){2,13}[a-zA-Z]$/i,
  // eslint-disable-next-line
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const api = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://api.dev.gzero.byepo.com"
      : process.env.REACT_APP_STAGING === "uat"
      ? "https://api.uat.ninto.in"
      : process.env.REACT_APP_STAGING === "production"
      ? "https://ninto.in/api"
      : "http://127.0.0.1:5001/seed",
};

export const bucketNames = {
  defaultBucket:
    process.env.REACT_APP_STAGING === "development"
      ? "gs://origin-25-dev.appspot.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "gs://origin-25-dev.appspot.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "gs://origin-25-prod.appspot.com"
      : "gs://origin-25-dev.appspot.com",
};

export const logrocketInit = {
  key:
    process.env.REACT_APP_STAGING === "development"
      ? "byepo/o25-admin-dev"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "byepo/o25-admin-uat"
      : process.env.REACT_APP_STAGING === "production"
      ? "byepo/o25-admin-prod"
      : "",
};

export function rippleEffect(event) {
  const parentElement = event.currentTarget;
  const containerElement = event.currentTarget.parentElement;
  const viewportOffset = parentElement.getBoundingClientRect();
  parentElement.classList.add("ripple-element");

  const circle = document.createElement("span");
  circle.classList.add("ripple-span");
  const diameter = Math.max(
    parentElement.clientWidth,
    parentElement.clientHeight
  );
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX +
    containerElement.scrollLeft -
    parentElement.offsetLeft -
    radius}px`;
  circle.style.top = `${event.clientY +
    containerElement.scrollTop -
    viewportOffset.y -
    radius}px`;
  circle.classList.add("ripples");

  const ripple = parentElement.getElementsByClassName("ripples")[0];

  if (ripple) {
    ripple.remove();
  }
  //Add ripple element
  parentElement.appendChild(circle);
}

export function dateAndTimeConverter(timestamp, type) {
  if (!timestamp) return;

  if (type === "cardDate") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "numeric" }) +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" });
    //   +
    //   " " +
    //   dateObject.toLocaleString("en-IN", { weekday: "long" }) +
    //   " ";
    return date;
  } else {
    if (type === "firstDate") {
      let dateObject = new Date(timestamp);
      const date = dateObject
        .toLocaleString("en-IN", { day: "numeric" })
        .toUpperCase();
      return date;
    }
    if (type === "secondDate") {
      let dateObject = new Date(timestamp);
      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" });
      return date;
    }
    if (type === "thirdDate") {
      let dateObject = new Date(timestamp);
      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }) +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" }).substr(2);
      return date;
    }
  }

  if (type === "Time") {
    let dateObject = new Date(timestamp);
    const date = dateObject
      .toLocaleString("en-IN", {
        hour: "numeric" && "2-digit",
        minute: "numeric" && "2-digit",
        hour12: true,
      })
      .toUpperCase();

    // + " ";
    return date;
  }
}

export const filterData = {
  category: {
    0: "community",
    1: "institutional",
    2: "publicSpace",
    3: "transitArea",
  },
  // changesProposed: {
  //   0: "major",
  //   1: "minor",
  //   2: "new",
  // },
  // condition: {
  //   0: "inUse",
  //   1: "renew",
  //   2: "defunct",
  //   3: "demolished",
  // },
  zone: {},
  ward: {},
  status: { 0: "Red", 1: "Green", 2: "Yellow" },
  dateRange: {},
  // quality: {
  //   0: "good",
  //   2: "bad",
  //   1: "renew",
  //   3: "demolished",
  // },
};

export const transformFilterNames = (filterType, items) => {
  function convertLabel(label) {
    if (filterType === "category") {
      switch (label) {
        case "community":
          return "Community";
        case "institutional":
          return "Institutional";
        case "publicSpace":
          return "Public Space";
        case "transitArea":
          return "Transit Area";
        default:
          return "";
      }
    }

    if (filterType === "changesProposed") {
      switch (label) {
        case "major":
          return "Major";
        case "minor":
          return "Minor";
        case "new":
          return "New";
        default:
          return "";
      }
    }

    if (filterType === "condition") {
      switch (label) {
        case "inUse":
          return "In Usage";
        case "renew":
          return "Under Renewal";
        case "defunct":
          return "Defunct";
        case "demolished":
          return "Demolished";
        default:
          return "";
      }
    }

    if (filterType === "quality") {
      switch (label) {
        case "good":
          return "Good";
        case "bad":
          return "Bad";
        case "renew":
          return "Under Renewal";
        case "demolished":
          return "Demolished";
        default:
          return "";
      }
    }
  }

  return items.map((item) => ({
    ...item,
    label: convertLabel(item.label),
  }));
};

export const transformKey = (key) => {
  switch (key) {
    case "status":
      return "Status";
    case "dateRange":
      return "Date Range";
    case "issueRaised":
      return "Issue Raised";
    case "issueTakenBy":
      return "Issue Taken By";
    default:
      return key;
  }
};

export const getKeyDescription = (key) => {
  switch (key) {
    case "category":
      return "Filter according to the category of locations";
    case "changesProposed":
      return "Changes Proposed Description";
    case "condition":
      return "Condition Description";
    case "zone":
      return "Filter according to the zone in which the location is present";
    case "ward":
      return "Filter according to the ward in which the location is present";
    case "status":
      return "Filter according to the status of the location";
    case "dateRange":
      return "Change the date range within which the location data is to be displayed";
    case "quality":
      return "Quality Description";
    default:
      break;
  }
};

export const getMenuTitle = (pathName) => {
  switch (pathName) {
    case "/":
      return "Dashboard";
    case "/admin":
      return "Admin";
    case "/presets":
      return "Presets";
    case "/manager":
      return "Manager";
    default:
      break;
  }
};

export const tableHeadings = {
  index: {
    title: "PCT NO",
    className: "min-width-55px width-4-percentage",
  },
  ward: {
    title: "WARD",
    className: "min-width-65px width-4-percentage",
  },
  zone: {
    title: "ZONE",
    className: "min-width-65px width-4-percentage",
  },
  location: {
    title: "ADDRESS",
    className: "min-width-200px width-30-percentage",
  },
  category: {
    title: "CATEGORY",
    className: "min-width-100px width-15-percentage",
  },
  status: {
    title: "STATUS",
    className: "min-width-fit width-10-percentage",
  },
  seats: {
    title: "SEATS",
    className:
      "min-width-65px width-4-percentage white-space-normal text-align-center",
  },
  openComplaints: {
    title: "RESOLVED PETITIONS",
    className:
      "min-width-100px width-10-percentage white-space-normal text-align-center",
  },
  closedComplaints: {
    title: "UNRESOLVED PETITIONS",
    className:
      "min-width-100px width-10-percentage white-space-normal text-align-center",
  },
  completedProcedure: {
    title: "COMPLETED PROCEDURES",
    className:
      "min-width-100px width-10-percentage white-space-normal text-align-center",
  },
};

export const checkArraysAreEqual = (arr1, arr2) => {
  if (!isValidArray(arr1) && !isValidArray(arr2)) {
    return true;
  }

  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    const element1 = arr1[i];
    const element2 = arr2[i];

    if (Array.isArray(element1) && Array.isArray(element2)) {
      if (!checkArraysAreEqual(element1, element2)) {
        return false;
      }
    } else if (typeof element1 === "object" && typeof element2 === "object") {
      if (!checkObjectsAreEqual(element1, element2)) {
        return false;
      }
    } else if (element1 !== element2) {
      return false;
    }
  }

  return true;
};

export const checkObjectsAreEqual = (obj1, obj2) => {
  if (!isValidObject(obj1) && !isValidObject(obj1)) {
    return true;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (typeof value1 === "object" && typeof value2 === "object") {
      if (!checkObjectsAreEqual(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
};

export const getPublicURL = (documentId) => {
  switch (process.env.REACT_APP_STAGING) {
    case "local":
      return `http://localhost:3002/${documentId}`;

    case "development":
      return `https://public.dev.gzero.byepo.com/${documentId}`;

    case "production":
      return `https://public.rsbpct.com/${documentId}`;

    case "UAT":
      return `https://public.uat.gzero.byepo.com/${documentId}`;

    default:
      return `https://public.dev.gzero.byepo.com/${documentId}`;
  }
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const timeAgo = (timestamp) => {
  const now = new Date();
  const updatedTime = new Date(timestamp);

  const timeDiff = now - updatedTime;

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (seconds < 60) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} MIN${minutes === 1 ? "" : "S"}`;
  } else if (hours < 24) {
    return `${hours} HR${hours === 1 ? "" : "S"}`;
  } else if (days < 30) {
    return `${days} DAY${days === 1 ? "" : "S"}`;
  } else {
    return `${months} MONTH${months === 1 ? "" : "S"}`;
  }
};

export const getValidColor = (key) => {
  if (key === "Green") {
    return "background-color-green";
  } else if (key === "Yellow") {
    return "background-color-yellow";
  } else if (key === "Red") {
    return "background-color-red";
  } else {
    return "background-color-red";
  }
};

export const getValidStatus = (key) => {
  if (key === "Green") {
    return "Optimal";
  } else if (key === "Yellow") {
    return "Moderate";
  } else if (key === "Red") {
    return "Critical";
  } else {
    return key;
  }
};

export const getValidStatusByCount = (key) => {
  if (key <= 0) {
    return "Optimal";
  } else if (key > 0 && key <= 5) {
    return "Moderate";
  } else if (key > 5) {
    return "Critical";
  } else {
    throw new Error("Invalid Status Count argument");
  }
};

export const getComplaintsCount = (data, type) => {
  return (
    data?.complaintCount?.[new Date().getFullYear()]?.[
      new Date().getMonth() + 1
    ]?.[new Date().getDate()]?.[type] || 0
  );
};

export const calculateTotal = (item, fromDate, toDate, method, type) => {
  const methodCount = item?.[method];
  const fromDateObj = +new Date(fromDate).setHours(0, 0, 0);
  const toDateObj = +new Date(toDate).setHours(0, 0, 0);
  let total = 0;
  if (methodCount) {
    Object.keys(methodCount).forEach((year) => {
      Object.keys(methodCount?.[year]).forEach((month) => {
        Object.keys(methodCount?.[year][month]).forEach((day) => {
          const currentDate = +new Date(`${year}-${month}-${day}`).setHours(
            0,
            0,
            0
          );
          if (currentDate >= fromDateObj && currentDate <= toDateObj) {
            if (methodCount?.[year][month][day].hasOwnProperty([type])) {
              total += methodCount?.[year][month][day]?.[type];
            }
          }
        });
      });
    });
    return total;
  }
};

export const getStatusCount = (data) => {
  const complaintCount =
    data?.complaintCount?.[new Date().getFullYear()]?.[
      new Date().getMonth() + 1
    ]?.[new Date().getDate()];

  const open = isNaN(complaintCount?.open) ? 0 : complaintCount?.open;
  const closed = isNaN(complaintCount?.closed) ? 0 : complaintCount?.closed;

  const difference = open - closed;

  return difference < 0 ? 0 : difference;
};

export const getCompletedProcedureCount = (data) => {
  return (
    data?.procedureCount?.[new Date().getFullYear()]?.[
      new Date().getMonth() + 1
    ]?.[new Date().getDate()]?.positive +
      data?.procedureCount?.[new Date().getFullYear()]?.[
        new Date().getMonth() + 1
      ]?.[new Date().getDate()]?.negative || 0
  );
};

export const capitalizeFirstLetter = (word) => {
  if (!word) {
    return "N/A";
  }
  const firstLetter = word.charAt(0);

  const firstLetterCap = firstLetter.toUpperCase();

  const remainingLetters = word.slice(1).toLowerCase();

  return firstLetterCap + remainingLetters;
};

export const alignPhoneNumber = (phoneNumber) => {
  if (phoneNumber?.includes(+91)) {
    const num = phoneNumber.slice(3);
    return num.slice(0, 5) + " " + num.slice(5);
  } else if (phoneNumber) {
    return phoneNumber.slice(0, 5) + " " + phoneNumber.slice(5);
  }
};

export const petitionsQueryPath = {
  status: "status.currentStatus",
  issueRaised: "issuedBy.userDetails.phoneNumber",
  issueTakenBy: "takenOverBy.email",
  category: "category",
  dateRange: "status.updatedAt",
  PCTNo: "locationId",
};

export const jobsQueryPath = {
  procedureName: "procedureId",
  assignedTo: "employeeId",
  procedureType: "procedureType",
  dateRange: "createdAt",
};

export const volunteeringQueryPath = {
  procedureName: "procedureId",
  assignedTo: "employeeId",
  procedureType: "procedureType",
  dateRange: "createdAt",
};

export const tableTitles = {
  announcement: "ANNOUNCEMENT",
  jobs: "JOBS",
  petitions: "PETITIONS",
  volunteering: "VOLUNTEERING",
  summary: "SUMMARY",
};

export const transformTableName = (tableName) => {
  switch (tableName) {
    case "LOCATIONS":
      return "locations";
    case "PROCEDURES":
      return "procedures";
    case "PETITIONS":
      return "petitions";
    default:
      return;
  }
};

export const defaultFilterData = {
  jobs: {
    company: [],
    location: [],
    status: [],
    title: [],
  },
  volunteering: {
    company: [],
    location: [],
    status: [],
    title: [],
  },
  petitions: {
    status: [],
    category: [],
    dateRange: {
      from: null,
      to: null,
    },
    issueRaised: [],
    issueTakenBy: [],
    PCTNo: [],
  },
};

export const isDigit = (val) => /^\d+$/.test(val);

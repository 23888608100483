import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./Redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import LogRocket from "logrocket";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import UnsupportedDevice from "./Components/UnsupportedDevice/UnsupportedDevice";

ReactDOM.render(
  <ErrorBoundary
    onClick={() => {
      localStorage.clear();
      window.location.reload();
    }}
    handleComponentDidCatch={(error) => {
      LogRocket.captureMessage(error.message, {
        tags: {
          userType: "admin",
        },
        extra: {
          fatal: true,
          code: error.code,
        },
      });
    }}
  >
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {(process.env.REACT_APP_STAGING === "development" &&
              !window.Cypress) ||
            !navigator.userAgent.includes("Mobile") ? (
              <App />
            ) : (
              <UnsupportedDevice />
            )}
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

if (window.Cypress) {
  window.store = store;
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register({
    onUpdate: onSWUpdate,
    onSuccess: (_controller, buildNo) => {
      console.log(`Successfully updated and serving build ${buildNo}`);
    },
  });
}

const skipWaitingClearCacheAndReload = async () => {
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data === "FORCE_UPDATE_COMPLETE") {
      window.location.reload(true);
    }
  });

  navigator.serviceWorker.ready.then((registration) => {
    registration.waiting.postMessage("FORCE_UPDATE");
  });
};

async function onSWUpdate(controller, force) {
  if (force === true && controller) {
    const criticalUpdateCard = document.getElementById("critical-update-card");
    criticalUpdateCard.classList.remove("display-none");
    criticalUpdateCard.classList.add("display-block");
    const criticalUpdateButton = document.getElementById(
      "critical-update-confirm-button"
    );
    criticalUpdateButton.addEventListener("click", () =>
      skipWaitingClearCacheAndReload()
    );
  }
}

reportWebVitals();

import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module AnnouncementsSaga
 */

/**
 *
 * @param {data} payload
 */

export function setAnnouncements(data) {
  store.dispatch({
    type: actionTypes.PUT_ANNOUNCEMENTS_DATA,
    payload: {
      data: data,
    },
  });
}

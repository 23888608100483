import { useState } from "react";
import "./Login.css";
import { connect } from "react-redux";
import InputBox from "../../Components/InputBox/InputBox";
import Button from "../../Components/Button/Button";
import { login } from "../../Redux/authentication/actions";
import { Regex } from "../../Utils/constants";
import appLogo from "../../Assets/appLogo.png";

function Login(props) {
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });
  const [isValidLoginInput, setIsValidLoginInput] = useState({
    email: false,
    password: false,
  });

  return (
    <div
      className="z-index-101 background-color-black-with-opacity-light position-fixed top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width display-flex flex-justify-content-center flex-align-items-center"
      data-cy="login-modal-background"
    >
      <div
        className={` ${
          props.theme === "dark" ? "background-color-dark" : "background-white"
        } background-white  width-500px padding-large font-family-RHD-medium box-shadow-default border-radius-default flex-direction-column flex-justify-content-center flex-align-items-center padding-larger `}
        data-cy="login-modal"
      >


        <div className=" flex-justify-content-center flex-align-items-center margin-top-large">
          <img
            src={appLogo}
            alt="app logo"
            style={{
              height: "40px",
              width: "40px",
            }}
          />

          <div className="font-size-larger padding-left-large">Arun Nehru</div>
        </div>

        <div className="font-size-large padding-top-larger">LOGIN</div>
        <div className="font-size-default padding-top-default font-family-RHD-regular">
          Enter your registered email and password
        </div>
        <form
          className="width-400px padding-horizontal-larger"
          onChange={(event) => {
            setLoginFormData({
              ...loginFormData,
              [event.target.name]: event.target.value,
            });
            if (event.target.name === "password") {
              setIsValidLoginInput({
                ...isValidLoginInput,
                password: event.target.value.length > 6 ? true : false,
              });
            }
          }}
          autoComplete="off"
          onSubmit={(event) => {
            event.preventDefault();
            props.login(loginFormData.email, loginFormData.password);
          }}
        >
          <InputBox
            className="inherit-parent-width padding-top-larger"
            name="email"
            label="Email"
            type="email"
            removeResponsive={true}
            autoComplete="off"
            value={loginFormData.email}
            fontColor={props.theme === "dark" ? "font-color-white" : ""}
            labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
            validation={(value) => {
              if (!value) {
                setIsValidLoginInput({ ...isValidLoginInput, email: true });
                return {
                  status: true,
                };
              }
              if (!Regex.email.test(value)) {
                setIsValidLoginInput({ ...isValidLoginInput, email: false });
                return {
                  status: false,
                  message: "Please enter a email",
                };
              } else {
                setIsValidLoginInput({ ...isValidLoginInput, email: true });
                return {
                  status: true,
                };
              }
            }}
            data-cy="login-screen-email-field"
            required
          />

          <InputBox
            className="inherit-parent-width padding-top-larger"
            name="password"
            label="Password"
            type="password"
            removeResponsive={true}
            autoComplete="off"
            value={loginFormData.password}
            fontColor={props.theme === "dark" ? "font-color-white" : ""}
            labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
            data-cy="login-screen-password-field"
            required
          />

          <Button
            data-cy={
              props.auth.loading ? "login-loading-button" : "login-button"
            }
            loading={props.auth.loading}
            type="submit"
            className="margin-top-larger margin-bottom-larger"
            text="Login"
            disabled={!isValidLoginInput.email || !isValidLoginInput.password}
            boxShadow={false}
          />
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = function(state) {
  return {
    auth: state.auth,
    profile: state.profile,
  };
};

const mapDispatchToProps = function() {
  return {
    login: (email, password) => login(email, password),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

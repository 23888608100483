import React, { useState, useMemo } from "react";
import {
  capitalizeFirstLetter,
  dateAndTimeConverter,
  isDigit,
} from "../../Utils/constants";
import { isValidArray, isValidObject } from "../../Services/validators";
import {
  AscendingOrderIcon,
  DefaultSortIcon,
  DescendingOrderIcon,
} from "../../Assets/assets";
import SearchFallBackIcon from "../../Assets/SearchFallBackIcon.svg";

const FIELDS = {
  ID: "documentId",
  TITLE: "title",
  "AUTHOR NAME": "authorName",
  CATEGORY: "category",
  "CREATED AT": "createdAt",
  "READ TIME": "readTime",
};

const tableHeadings = [
  { title: "ID", width: "250px" },
  { title: "TITLE", width: "400px" },
  { title: "AUTHOR NAME", width: "200px" },
  { title: "CATEGORY", width: "100px" },
  { title: "CREATED AT", width: "170px" },
  { title: "READ TIME", width: "130px" },
];

export default function AnnouncementTable({ announcements, theme }) {
  const [sortConfig, setSortConfig] = useState({ key: "", ascending: true });

  const sortedAnnouncements = useMemo(() => {
    if (!isValidObject(announcements.data)) {
      return [];
    }

    const field = FIELDS[sortConfig.key];
    if (!field) return Object.values(announcements.data);

    return Object.values(announcements.data).sort((a, b) => {
      let valueA =
        sortConfig.key === "CREATED AT"
          ? a[field]
          : a[field].english?.toUpperCase() || "N/A";
      let valueB =
        sortConfig.key === "CREATED AT"
          ? b[field]
          : b[field].english?.toUpperCase() || "N/A";

      if (isDigit(valueA) && isDigit(valueB)) {
        valueA = parseInt(valueA);
        valueB = parseInt(valueB);
      }

      if (valueA < valueB) return sortConfig.ascending ? -1 : 1;
      if (valueA > valueB) return sortConfig.ascending ? 1 : -1;
      return 0;
    });
  }, [announcements.data, sortConfig]);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      ascending: prevConfig.key === key ? !prevConfig.ascending : true,
    }));
  };

  return (
    <div
      className="remaining-body-height-from-footer inherit-parent-height overflow-scroll"
      style={{ maxWidth: "1400px" }}
      data-cy={"announcements-table"}
    >
      <table
        className={`inherit-parent-width ${
          isValidArray(sortedAnnouncements) ? "" : "inherit-parent-height"
        }`}
        style={{ borderCollapse: "collapse" }}
      >
        <thead>
          <tr
            style={{ position: "sticky", top: "0" }}
            className={`font-size-large ${
              theme === "dark" ? "background-color-dark" : "background-white"
            }`}
          >
            {tableHeadings.map((header, index) => (
              <th
                key={index}
                data-cy={`header-${header.title}`}
                className="padding-top-large padding-bottom-large font-size-medium font-family-RHD-medium cursor-pointer"
                style={{ minWidth: header.width }}
                onClick={() => handleSort(header.title)}
              >
                <div className="display-flex padding-horizontal-medium">
                  <div className="padding-right-default">{header.title}</div>
                  {sortConfig.key === header.title && (
                    <div
                      data-cy={`${header.title}-${
                        sortConfig.ascending ? "ascending" : "descending"
                      }-sort`}
                      className="height-fit-content width-fit-content"
                    >
                      {sortConfig.ascending ? (
                        <AscendingOrderIcon
                          color={theme === "dark" ? "white" : "#404040"}
                        />
                      ) : (
                        <DescendingOrderIcon
                          color={theme === "dark" ? "white" : "#404040"}
                        />
                      )}
                    </div>
                  )}
                  {sortConfig.key !== header.title && (
                    <div
                      data-cy={`${header.title}-default-sort`}
                      className="display-flex flex-align-items-center"
                    >
                      <DefaultSortIcon
                        color={theme === "dark" ? "white" : "#404040"}
                      />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody data-cy="table-content-area">
          {isValidArray(sortedAnnouncements) ? (
            sortedAnnouncements.map((data, index) => (
              <tr
                key={index}
                className={`font-size-default ${
                  theme === "dark"
                    ? "on-hover-background-color-404040"
                    : "on-hover-background-color-primary"
                } ${theme === "dark" &&
                  data.documentId ===
                    sortedAnnouncements.selectedAnnouncement &&
                  "background-color-dark"} ${theme !== "dark" &&
                  data.documentId ===
                    sortedAnnouncements.selectedAnnouncement &&
                  "background-white"}`}
                onClick={() => {
                  // Handle row click
                }}
              >
                <td
                  data-cy={`${data.documentId}-compliant`}
                  className="padding-default cursor-pointer text-overflow-ellipsis"
                  style={{ maxWidth: "100px", minWidth: "100px" }}
                >
                  {data.documentId || "N/A"}
                </td>
                <td
                  data-cy={`${data.title}-compliant`}
                  className="padding-default cursor-pointer text-overflow-ellipsis"
                  style={{ maxWidth: "400px", minWidth: "400px" }}
                >
                  {data.title.english || "N/A"}
                </td>
                <td
                  data-cy={`${data.authorName}-compliant`}
                  className="padding-default text-align-left cursor-pointer white-space-no-wrap text-overflow-ellipsis"
                  style={{ maxWidth: "300px", minWidth: "300px" }}
                >
                  {capitalizeFirstLetter(data.authorName.english) || "N/A"}
                </td>
                <td
                  data-cy={`${data.category}-compliant`}
                  className="padding-default cursor-pointer white-space-no-wrap"
                  style={{ maxWidth: "170px", minWidth: "170px" }}
                >
                  {data.category.english || "N/A"}
                </td>
                <td
                  data-cy={`${data.createdAt}-compliant`}
                  className="padding-default text-align-center cursor-pointer white-space-no-wrap text-overflow-ellipsis"
                  style={{ maxWidth: "170px", minWidth: "170px" }}
                >
                  {data.createdAt
                    ? `${dateAndTimeConverter(
                        data.createdAt,
                        "Time"
                      )}, ${dateAndTimeConverter(data.createdAt, "thirdDate")}`
                    : "N/A"}
                </td>
                <td
                  data-cy={`${data.readTime}-compliant`}
                  className="padding-default cursor-pointer white-space-no-wrap text-overflow-ellipsis"
                  style={{ maxWidth: "130px", minWidth: "130px" }}
                >
                  {data.readTime.english || "N/A"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className="text-center">
                <div className="inherit-parent-width font-size-default display-flex flex-direction-column flex-justify-content-center flex-align-items-center">
                  <img src={SearchFallBackIcon} alt="No matching results" />
                  <div className="padding-top-larger">NO MATCHING RESULTS</div>
                  <div className="padding-top-large">
                    There wasn’t any result matching to the search and filter
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

import { useEffect, useState, useRef } from "react";
import {
  query,
  collection,
  where,
  onSnapshot,
  doc,
  getDocs,
  updateDoc,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { database } from "./firebase";
import { setErrorStatus } from "../Redux/status/action";
import { isValidArray } from "./validators";
import { getFileUrl } from "./storage";
import { setPetitionsData } from "../Redux/petitions/action";
import { setAnnouncements } from "../Redux/announcements/action";
import { setVolunteeringData } from "../Redux/volunteering/action";
import { setJobsData } from "../Redux/jobs/action";

export function usePetitionsListener(isAuth, filterQuery) {
  const [petitionsListener, setPetitionsListener] = useState({
    listener: null,
  });
  const [petitionsAssetsLoading, setPetitionsAssetsLoading] = useState(false);
  const currentCursor = useRef(null);
  const data = useRef(null);
  const listeningFilterData = useRef(null);
  const dataLimit = 50;

  async function getPetitionsAssets(documentId) {
    try {
      setPetitionsAssetsLoading(true);
      if (
        !data?.current[documentId]?.fileSrc ||
        !data?.current[documentId]?.closure
      ) {
        const selectedData = data.current?.[documentId];
        let complaintImages = [];
        let complaintPdf = [];

        if (isValidArray(selectedData?.assets)) {
          for (let j = 0; j < selectedData?.assets.length; j++) {
            const file = await getFileUrl(selectedData?.assets[j]);
            if (file.type === "image") {
              complaintImages.push(file.url);
            } else if (file.type === "application") {
              complaintPdf.push(file.url);
            }
          }
        }

        if (selectedData?.updates) {
          for (let i = 0; i < selectedData?.updates.length; i++) {
            if (!selectedData.updates[i].assets) {
              selectedData.updates[i].assets = { images: [], audio: null };
            } else {
              if (!selectedData.updates[i].assets.images) {
                selectedData.updates[i].assets.images = [];
              }
              if (!selectedData.updates[i].assets.audio) {
                selectedData.updates[i].assets.audio = null;
              }
            }
            for (let j = 0; j < selectedData?.updates[i]?.proof?.length; j++) {
              const file = await getFileUrl(selectedData?.updates[i].proof[j]);
              if (file.type === "image") {
                selectedData.updates[i].assets.images.push(file.url);
              } else if (file.type === "audio") {
                selectedData.updates[i].assets.audio = file.url;
              }
            }
          }
        }

        let closureFileSrc = {};
        let closureImages = [];
        if (isValidArray(selectedData?.closure?.proof)) {
          for (let j = 0; j < selectedData?.closure?.proof.length; j++) {
            const file = await getFileUrl(selectedData.closure?.proof[j]);
            if (file.type === "image") {
              closureImages.push(file.url);
            } else if (file.type === "audio") {
              closureFileSrc = { ...closureFileSrc, audio: file.url };
            }
          }
        }

        data.current = {
          ...data.current,
          [documentId]: {
            ...selectedData,
            fileSrc: {
              ...(isValidArray(complaintPdf) ? { pdfs: complaintPdf } : {}),
              ...(isValidArray(complaintImages)
                ? { images: complaintImages }
                : {}),
            },
            closure: {
              ...selectedData.closure,
              fileSrc: {
                ...closureFileSrc,
                ...(isValidArray(closureImages)
                  ? { images: closureImages }
                  : {}),
              },
            },
          },
        };
      }
      setPetitionsData(data.current, "SET");
      setPetitionsAssetsLoading(false);
    } catch (error) {
      setPetitionsAssetsLoading(false);
      console.error("getPetitionsAssets", error);
    }
  }

  // pagination
  const getNextPage = async (preLoadedLimit, latestPetitions) => {
    if (!currentCursor?.current) {
      return;
    }
    let paginationQueryRef = query(
      collection(database, "petitions"),
      orderBy("status.updatedAt", "desc"),
      startAfter(currentCursor?.current),
      limit(preLoadedLimit !== undefined ? preLoadedLimit : dataLimit)
    );

    listeningFilterData.current?.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        paginationQueryRef = query(
          paginationQueryRef,
          where(
            listeningFilterData.current[index - 2],
            listeningFilterData.current[index - 1],
            value
          )
        );
      }
    });

    const querySnapshot = await getDocs(paginationQueryRef);
    let petitions = {};
    querySnapshot.forEach((doc) => {
      petitions[doc.id] = {
        ...doc.data(),
        documentId: doc.id,
      };
    });

    if (latestPetitions) {
      data.current = {
        ...latestPetitions,
        ...petitions,
      };
    } else {
      data.current = {
        ...data.current,
        ...petitions,
      };
    }

    currentCursor.current = querySnapshot.docs[querySnapshot.docs.length - 1];
    setPetitionsData(data.current, "SET");
  };

  // Listener
  const subscribeToPetitions = () => {
    // If "in" values multiplied with each other is equal to or grater than 20, stop.
    let queryRef = query(
      collection(database, "petitions"),
      orderBy("status.updatedAt", "desc"),
      limit(dataLimit)
    );

    filterQuery.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        queryRef = query(
          queryRef,
          where(filterQuery[index - 2], filterQuery[index - 1], value)
        );
      }
    });

    return onSnapshot(
      queryRef,
      (dataSnapshot) => {
        let petitions = {};
        dataSnapshot.forEach((doc) => {
          petitions[doc.id] = { ...doc.data(), documentId: doc.id };
        });

        currentCursor.current = dataSnapshot.docs[dataSnapshot.docs.length - 1];
        // Check if filters are changed from previously running listener
        if (
          data.current === null ||
          JSON.stringify(listeningFilterData.current) !==
            JSON.stringify(filterQuery)
        ) {
          data.current = petitions;
          listeningFilterData.current = filterQuery;
          setPetitionsData(data.current, "SET");
        } else if (Object.values(data.current).length > dataLimit) {
          const previousPetitionsTotal =
            Object.values(data.current).length - dataLimit;

          getNextPage(previousPetitionsTotal, petitions);
        } else {
          data.current = petitions;
          setPetitionsData(data.current, "SET");
        }
      },
      (error) => {
        console.error(error, "from petitions listener");
        setErrorStatus(error);
      }
    );
  };

  function getPetitionsNextPage() {
    getNextPage();
  }

  useEffect(() => {
    if (
      typeof petitionsListener.listener === "function" &&
      isAuth === true &&
      filterQuery
    ) {
      petitionsListener.listener();
      setPetitionsListener({
        listener: subscribeToPetitions(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  useEffect(() => {
    if (isAuth === true && petitionsListener.listener === null) {
      setPetitionsListener({
        listener: subscribeToPetitions(),
      });
    } else if (
      typeof petitionsListener.listener === "function" &&
      isAuth === false
    ) {
      petitionsListener.listener();
      setPetitionsListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [isAuth, petitionsListener]);

  return [getPetitionsNextPage, getPetitionsAssets, petitionsAssetsLoading];
}

export function useAnnouncementsListener(props) {
  const [announcementListener, setAnnouncementListener] = useState({
    listener: null,
  });

  const subscribeToAnnouncement = () => {
    const announcementQuery = query(collection(database, "announcements"));

    return onSnapshot(
      announcementQuery,
      (dataSnapshot) => {
        let announcements = {};
        dataSnapshot.forEach((doc) => {
          announcements[doc.id] = {
            ...doc.data(),
            documentId: doc.id,
          };
        });
        setAnnouncements(announcements);
      },
      (error) => {
        console.error(error, "from announcements");
        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (props.isAuth === true && announcementListener.listener === null) {
      setAnnouncementListener({
        listener: subscribeToAnnouncement(),
      });
    } else if (
      props.isAuth === false &&
      typeof announcementListener.listener === "function"
    ) {
      announcementListener.listener();
      setAnnouncementListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth]);
}

export function useVolunteeringListener(isAuth, filterQuery) {
  const [volunteeringListener, setVolunteeringListener] = useState({
    listener: null,
  });
  const [volunteeringAssetsLoading, setVolunteeringAssetsLoading] = useState(
    false
  );
  const currentCursor = useRef(null);
  const data = useRef(null);
  const listeningFilterData = useRef(null);
  const dataLimit = 50;

  async function getVolunteeringAssets(documentId) {
    try {
      setVolunteeringAssetsLoading(true);
      if (
        !data.current[documentId]?.fileSrc ||
        !data.current[documentId]?.closure
      ) {
        const selectedData = data.current?.[documentId];
        let complaintImages = [];
        let complaintPdf = [];

        if (isValidArray(selectedData.assets)) {
          for (let j = 0; j < selectedData?.assets.length; j++) {
            const file = await getFileUrl(selectedData?.assets[j]);
            if (file.type === "image") {
              complaintImages.push(file.url);
            } else if (file.type === "application") {
              complaintPdf.push(file.url);
            }
          }
        }

        if (selectedData.updates) {
          for (let i = 0; i < selectedData?.updates.length; i++) {
            if (!selectedData.updates[i].assets) {
              selectedData.updates[i].assets = { images: [], audio: null };
            } else {
              if (!selectedData.updates[i].assets.images) {
                selectedData.updates[i].assets.images = [];
              }
              if (!selectedData.updates[i].assets.audio) {
                selectedData.updates[i].assets.audio = null;
              }
            }
            for (let j = 0; j < selectedData?.updates[i]?.proof?.length; j++) {
              const file = await getFileUrl(selectedData?.updates[i].proof[j]);
              if (file.type === "image") {
                selectedData.updates[i].assets.images.push(file.url);
              } else if (file.type === "audio") {
                selectedData.updates[i].assets.audio = file.url;
              }
            }
          }
        }

        let closureFileSrc = {};
        let closureImages = [];
        if (isValidArray(selectedData.closure?.proof)) {
          for (let j = 0; j < selectedData.closure?.proof.length; j++) {
            const file = await getFileUrl(selectedData.closure?.proof[j]);
            if (file.type === "image") {
              closureImages.push(file.url);
            } else if (file.type === "audio") {
              closureFileSrc = { ...closureFileSrc, audio: file.url };
            }
          }
        }

        data.current = {
          ...data.current,
          [documentId]: {
            ...selectedData,
            fileSrc: {
              ...(isValidArray(complaintPdf) ? { pdfs: complaintPdf } : {}),
              ...(isValidArray(complaintImages)
                ? { images: complaintImages }
                : {}),
            },
            closure: {
              ...selectedData.closure,
              fileSrc: {
                ...closureFileSrc,
                ...(isValidArray(closureImages)
                  ? { images: closureImages }
                  : {}),
              },
            },
          },
        };
      }
      setVolunteeringData(data.current, "SET");
      setVolunteeringAssetsLoading(false);
    } catch (error) {
      setVolunteeringAssetsLoading(false);
      console.error("getVolunteeringAssets", error);
    }
  }

  // pagination
  const getNextPage = async (preLoadedLimit, latestVolunteering) => {
    if (!currentCursor?.current) {
      return;
    }
    let paginationQueryRef = query(
      collection(database, "volunteering"),
      orderBy("status.updatedAt", "desc"),
      startAfter(currentCursor?.current),
      limit(preLoadedLimit !== undefined ? preLoadedLimit : dataLimit)
    );

    listeningFilterData.current?.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        paginationQueryRef = query(
          paginationQueryRef,
          where(
            listeningFilterData.current[index - 2],
            listeningFilterData.current[index - 1],
            value
          )
        );
      }
    });

    const querySnapshot = await getDocs(paginationQueryRef);
    let volunteering = {};
    querySnapshot.forEach((doc) => {
      volunteering[doc.id] = {
        ...doc.data(),
        documentId: doc.id,
      };
    });

    if (latestVolunteering) {
      data.current = {
        ...latestVolunteering,
        ...volunteering,
      };
    } else {
      data.current = {
        ...data.current,
        ...volunteering,
      };
    }

    currentCursor.current = querySnapshot.docs[querySnapshot.docs.length - 1];
    setVolunteeringData(data.current, "SET");
  };

  // Listener
  const subscribeToVolunteering = () => {
    // If "in" values multiplied with each other is equal to or grater than 20, stop.
    let queryRef = query(
      collection(database, "volunteering"),
      orderBy("createdAt", "desc"),
      limit(dataLimit)
    );

    filterQuery.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        queryRef = query(
          queryRef,
          where(filterQuery[index - 2], filterQuery[index - 1], value)
        );
      }
    });

    return onSnapshot(
      queryRef,
      (dataSnapshot) => {
        let volunteering = {};
        dataSnapshot.forEach((doc) => {
          volunteering[doc.id] = { ...doc.data(), documentId: doc.id };
        });

        currentCursor.current = dataSnapshot.docs[dataSnapshot.docs.length - 1];
        // Check if filters are changed from previously running listener
        if (
          data.current === null ||
          JSON.stringify(listeningFilterData.current) !==
            JSON.stringify(filterQuery)
        ) {
          data.current = volunteering;
          listeningFilterData.current = filterQuery;
          setVolunteeringData(data.current, "SET");
        } else if (Object.values(data.current).length > dataLimit) {
          const previousVolunteeringTotal =
            Object.values(data.current).length - dataLimit;

          getNextPage(previousVolunteeringTotal, volunteering);
        } else {
          data.current = volunteering;
          setVolunteeringData(data.current, "SET");
        }
      },
      (error) => {
        console.error(error, "from volunteering listener");
        setErrorStatus(error);
      }
    );
  };

  function getVolunteeringNextPage() {
    getNextPage();
  }

  useEffect(() => {
    if (
      typeof volunteeringListener.listener === "function" &&
      isAuth === true &&
      filterQuery
    ) {
      volunteeringListener.listener();
      setVolunteeringListener({
        listener: subscribeToVolunteering(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  useEffect(() => {
    if (isAuth === true && volunteeringListener.listener === null) {
      setVolunteeringListener({
        listener: subscribeToVolunteering(),
      });
    } else if (
      typeof volunteeringListener.listener === "function" &&
      isAuth === false
    ) {
      volunteeringListener.listener();
      setVolunteeringListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [isAuth, volunteeringListener]);

  return [
    getVolunteeringNextPage,
    getVolunteeringAssets,
    volunteeringAssetsLoading,
  ];
}

export function useJobListener(isAuth, filterQuery) {
  const [jobListener, setJobListener] = useState({
    listener: null,
  });
  const [jobAssetsLoading, setJobAssetsLoading] = useState(false);
  const currentCursor = useRef(null);
  const data = useRef(null);
  const listeningFilterData = useRef(null);
  const dataLimit = 50;

  async function getJobAssets(documentId) {
    try {
      setJobAssetsLoading(true);
      if (
        !data.current[documentId]?.fileSrc ||
        !data.current[documentId]?.closure
      ) {
        const selectedData = data.current?.[documentId];
        let complaintImages = [];
        let complaintPdf = [];

        if (isValidArray(selectedData.assets)) {
          for (let j = 0; j < selectedData?.assets.length; j++) {
            const file = await getFileUrl(selectedData?.assets[j]);
            if (file.type === "image") {
              complaintImages.push(file.url);
            } else if (file.type === "application") {
              complaintPdf.push(file.url);
            }
          }
        }

        if (selectedData.updates) {
          for (let i = 0; i < selectedData?.updates.length; i++) {
            if (!selectedData.updates[i].assets) {
              selectedData.updates[i].assets = { images: [], audio: null };
            } else {
              if (!selectedData.updates[i].assets.images) {
                selectedData.updates[i].assets.images = [];
              }
              if (!selectedData.updates[i].assets.audio) {
                selectedData.updates[i].assets.audio = null;
              }
            }
            for (let j = 0; j < selectedData?.updates[i]?.proof?.length; j++) {
              const file = await getFileUrl(selectedData?.updates[i].proof[j]);
              if (file.type === "image") {
                selectedData.updates[i].assets.images.push(file.url);
              } else if (file.type === "audio") {
                selectedData.updates[i].assets.audio = file.url;
              }
            }
          }
        }

        let closureFileSrc = {};
        let closureImages = [];
        if (isValidArray(selectedData.closure?.proof)) {
          for (let j = 0; j < selectedData.closure?.proof.length; j++) {
            const file = await getFileUrl(selectedData.closure?.proof[j]);
            if (file.type === "image") {
              closureImages.push(file.url);
            } else if (file.type === "audio") {
              closureFileSrc = { ...closureFileSrc, audio: file.url };
            }
          }
        }

        data.current = {
          ...data.current,
          [documentId]: {
            ...selectedData,
            fileSrc: {
              ...(isValidArray(complaintPdf) ? { pdfs: complaintPdf } : {}),
              ...(isValidArray(complaintImages)
                ? { images: complaintImages }
                : {}),
            },
            closure: {
              ...selectedData.closure,
              fileSrc: {
                ...closureFileSrc,
                ...(isValidArray(closureImages)
                  ? { images: closureImages }
                  : {}),
              },
            },
          },
        };
      }
      setJobsData(data.current, "SET");
      setJobAssetsLoading(false);
    } catch (error) {
      setJobAssetsLoading(false);
      console.error("getJobAssets", error);
    }
  }

  // pagination
  const getNextPage = async (preLoadedLimit, latestJob) => {
    if (!currentCursor?.current) {
      return;
    }
    let paginationQueryRef = query(
      collection(database, "jobs"),
      orderBy("status.updatedAt", "desc"),
      startAfter(currentCursor?.current),
      limit(preLoadedLimit !== undefined ? preLoadedLimit : dataLimit)
    );

    listeningFilterData.current?.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        paginationQueryRef = query(
          paginationQueryRef,
          where(
            listeningFilterData.current[index - 2],
            listeningFilterData.current[index - 1],
            value
          )
        );
      }
    });

    const querySnapshot = await getDocs(paginationQueryRef);
    let job = {};
    querySnapshot.forEach((doc) => {
      job[doc.id] = {
        ...doc.data(),
        documentId: doc.id,
      };
    });

    if (latestJob) {
      data.current = {
        ...latestJob,
        ...job,
      };
    } else {
      data.current = {
        ...data.current,
        ...job,
      };
    }

    currentCursor.current = querySnapshot.docs[querySnapshot.docs.length - 1];
    setJobsData(data.current, "SET");
  };

  // Listener
  const subscribeToJob = () => {
    // If "in" values multiplied with each other is equal to or grater than 20, stop.
    let queryRef = query(
      collection(database, "jobs"),
      orderBy("createdAt", "desc"),
      limit(dataLimit)
    );

    filterQuery.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        queryRef = query(
          queryRef,
          where(filterQuery[index - 2], filterQuery[index - 1], value)
        );
      }
    });

    return onSnapshot(
      queryRef,
      (dataSnapshot) => {
        let job = {};
        dataSnapshot.forEach((doc) => {
          job[doc.id] = { ...doc.data(), documentId: doc.id };
        });

        currentCursor.current = dataSnapshot.docs[dataSnapshot.docs.length - 1];
        // Check if filters are changed from previously running listener
        if (
          data.current === null ||
          JSON.stringify(listeningFilterData.current) !==
            JSON.stringify(filterQuery)
        ) {
          data.current = job;
          listeningFilterData.current = filterQuery;
          setJobsData(data.current, "SET");
        } else if (Object.values(data.current).length > dataLimit) {
          const previousJobTotal =
            Object.values(data.current).length - dataLimit;

          getNextPage(previousJobTotal, job);
        } else {
          data.current = job;
          setJobsData(data.current, "SET");
        }
      },
      (error) => {
        console.error(error, "from job listener");
        setErrorStatus(error);
      }
    );
  };

  function getJobNextPage() {
    getNextPage();
  }

  useEffect(() => {
    if (
      typeof jobListener.listener === "function" &&
      isAuth === true &&
      filterQuery
    ) {
      jobListener.listener();
      setJobListener({
        listener: subscribeToJob(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  useEffect(() => {
    if (isAuth === true && jobListener.listener === null) {
      setJobListener({
        listener: subscribeToJob(),
      });
    } else if (typeof jobListener.listener === "function" && isAuth === false) {
      jobListener.listener();
      setJobListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [isAuth, jobListener]);

  return [getJobNextPage, getJobAssets, jobAssetsLoading];
}

export async function updateVerifyPetition(complaintId, data) {
  await updateDoc(doc(database, "petitions", complaintId), data);
}

export async function updatePetition(compliantId, employeeId, proof) {
  const rootRef = doc(database, "petitions", compliantId);

  await updateDoc(rootRef, {
    status: { currentStatus: "UNDER REVIEW", updatedAt: +new Date() },
    closure: {
      resolvedAt: +new Date(),
      resolvedBy: employeeId,
      proof: proof,
    },
  });
}

export async function updateJob(jobId) {
  const rootRef = doc(database, "jobs", jobId);

  await updateDoc(rootRef, {
    status: { currentStatus: "CLOSED", lastUpdatedAt: +new Date() },
  });
}

export async function updateVolunteering(jobId) {
  const rootRef = doc(database, "volunteering", jobId);

  await updateDoc(rootRef, {
    status: { currentStatus: "CLOSED", lastUpdatedAt: +new Date() },
  });
}

export async function takeoverPetition(compliantId, employeeData) {
  const rootRef = doc(database, "petitions", compliantId);

  await updateDoc(rootRef, {
    takenOverBy: employeeData,
  });
}

// export async function createPetitions() {
//   await setDoc(doc(database, "petitions", "seedCitizenPetition4"), {
//     issuedBy: {
//       issuedAt: +new Date(),
//       userDetails: {
//         name: "wolf",
//         phoneNumber: "+918825726717",
//       },
//     },
//     category: "ELECTRICAL",
//     createdAt: +new Date(),
//     status: {
//       currentStatus: "OPEN",
//       updatedAt: +new Date(),
//     },
//     assets: [],
//   });
// }

import { Route, Navigate, useNavigate, Routes } from "react-router-dom";
import Login from "./Screens/Login/Login";
import Profile from "./Screens/Profile/Profile";
import React, { useEffect, useState } from "react";
import { useAuthSubscriber } from "./Services/authentication";
import { connect } from "react-redux";
import SnackBar from "./Components/SnackBar/SnackBar";
import Home from "./Screens/Home/Home";
import LogRocket from "logrocket";
import Header from "./Components/Header/Header";
import {
  petitionsQueryPath,
  defaultFilterData,
  getMenuTitle,
  logrocketInit,
  tableTitles,
  transformTableName,
} from "./Utils/constants";
import Modal from "./Components/Modal/Modal";
import Button from "./Components/Button/Button";
import { logout } from "./Redux/authentication/actions";
import {
  useAnnouncementsListener,
  useJobListener,
  usePetitionsListener,
  useVolunteeringListener,
} from "./Services/database";
import "./App.css";
import {
  AnnouncementIcon,
  CloseIcon,
  DashboardIcon,
  JobsIcon,
  LogoutIcon,
  PetitionIcon,
  SummaryIcon,
  VolunteerIcon,
} from "./Assets/assets";
import { isValidArray } from "./Services/validators";
import {
  setSelectedTable,
  setSelectedTableId,
  setTableFilter,
} from "./Redux/table/action";

function App(props) {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [isAuth, setIsAuth] = useState(null);
  const [activeSwitch, setActiveSwitch] = useState("TABLE");
  const [selectedFilterKey, setSelectedFilterKey] = useState("");
  const [enableSearch, setEnableSearch] = useState(false);
  const [enableMenu, setEnableMenu] = useState(false);
  const [enableFilter, setEnableFilter] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [claims, setClaims] = useState(null);
  const [filterQuery, setFilterQuery] = useState({
    petitions: [],
    volunteering: [],
    jobs: [],
  });
  const [searchData, setSearchData] = useState({
    key: null,
    submittedKey: null,
  });

  const [showSearchBox, setShowSearchBox] = useState(true);
  useEffect(() => {
    if (
      props.table.locations?.filter?.dateRange.from === null &&
      props.table.locations?.filter?.dateRange.to === null
    ) {
      props.setTableFilter(tableTitles.locations, {
        ...props.table.locations.filter,
        dateRange: {
          from: new Date().toISOString().split("T")[0],
          to: new Date().toISOString().split("T")[0],
        },
      });
    }
    // eslint-disable-next-line
  }, [props.table.locations?.filter]);

  useEffect(() => {
    if (searchData.key?.length === 0) {
      setSearchData({
        key: null,
        submittedKey: null,
      });
    }
  }, [searchData.key]);

  const menu = [
    {
      title: "Dashboard",
      icon: (
        <DashboardIcon
          color={
            getMenuTitle(window.location.pathname) === "Dashboard"
              ? "white"
              : ""
          }
        />
      ),
    },
  ];

  useAuthSubscriber((props) => {
    setIsAuth(props.isAuth);
    setClaims(props.claims);
  }, isAuth);

  const [
    getComplaintsNextPage,
    getPetitionsAssets,
    petitionsAssetsLoading,
  ] = usePetitionsListener(isAuth, filterQuery.petitions);

  useJobListener(isAuth, filterQuery.jobs);
  useVolunteeringListener(isAuth, filterQuery.volunteering);

  useAnnouncementsListener({ isAuth: isAuth });
  const isAllowed = () => {
    if (
      claims?.employee?.create &&
      claims?.employee?.update &&
      claims?.procedure?.create &&
      claims?.procedure?.update
    ) {
      return true;
    } else {
      return false;
    }
  };

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      if (logrocketInit.key) {
        LogRocket.init(logrocketInit.key);
      }
    }
    // createPetitions();
  }, []);

  // Generate query for petitions filter
  useEffect(() => {
    let query = [];
    if (
      searchData.key?.length === 8 &&
      /^[a-zA-Z]{3}\d{5}$/.test(searchData.key) &&
      searchData.submittedKey
    ) {
      query = [...query, "petitionId", "==", searchData.key.toUpperCase()];
    }
    Object.keys(props.table.petitions.filter).forEach((key) => {
      if (
        key !== "dateRange" &&
        key !== "PCTNo" &&
        isValidArray(props.table.petitions.filter[key])
      ) {
        if (props.table.petitions.filter[key]) {
          query = [
            ...query,
            petitionsQueryPath[key],
            "in",
            props.table.petitions.filter[key],
          ];
        }
      } else if (key === "dateRange") {
        if (props.table.petitions.filter[key].from !== null) {
          if (props.table.petitions.filter[key].from) {
            query = [
              ...query,
              petitionsQueryPath[key],
              ">=",
              +new Date(`${props.table.petitions.filter[key].from}:00:00:00`),
            ];
          }
        }
        if (props.table.petitions.filter[key].to !== null) {
          query = [
            ...query,
            petitionsQueryPath[key],
            "<=",
            +new Date(`${props.table.petitions.filter[key].to}:23:59:59`),
          ];
        }
      }
    });

    setFilterQuery({ ...filterQuery, petitions: query });
    // eslint-disable-next-line
  }, [props.table.petitions.filter, searchData.submittedKey]);

  //for snack bar messages
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  //to show and hide searchbar box
  useEffect(() => {
    if (window.location.pathname !== "/" && showSearchBox && enableFilter) {
      return setShowSearchBox(false);
    }

    if (window.location.pathname === "/" && !showSearchBox && !enableFilter) {
      return setShowSearchBox(true);
    }

    // eslint-disable-next-line
  }, [window.location.pathname, enableFilter]);

  const isFilterApplied = (selectedFilter, table, type) => {
    if (
      selectedFilter?.status?.length > 0 ||
      selectedFilter?.issueTakenBy?.length > 0 ||
      selectedFilter?.issueRaised?.length > 0 ||
      (selectedFilter?.dateRange?.from && selectedFilter?.dateRange?.to)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={`font-color-dark background-color-light inherit-parent-height inherit-parent-width background-image`}
    >
      {isAuth === null ? (
        <div
          data-cy="home-loading"
          className="inherit-parent-height inherit-parent-width display-flex flex-justify-content-center flex-align-items-center"
        >
          <div className="padding-left-default display-flex flex-justify-content-center flex-align-items-center padding-right-default padding-top-small padding-bottom-small border-radius-default ">
            <div className="qr-loader" data-cy="qr-loader" />
          </div>
        </div>
      ) : (
        <>
          {window.location.pathname !== "/login" && (
            <Header
              theme={"light"}
              activeSwitch={activeSwitch}
              setActiveSwitch={setActiveSwitch}
              showSearchBox={showSearchBox}
              setLogoutModal={setLogoutModal}
              filterClick={() => {
                if (enableFilter) {
                  setEnableFilter(false);
                } else {
                  setEnableFilter(true);
                }
              }}
              searchClick={() => {
                setEnableSearch(true);
                setEnableFilter(false);
                setEnableMenu(false);
              }}
              closeClick={() => {
                if (enableSearch) {
                  props.putAlgoliaData({
                    query: "",
                    from: null,
                  });
                }
                setEnableSearch(false);
                setEnableFilter(false);
                setEnableMenu(false);
              }}
              menuClick={() => {
                setEnableMenu(true);
                setEnableSearch(false);
                setEnableFilter(false);
              }}
              enableSearch={enableSearch}
              searchKeyOnChange={(event) => {
                if (props.table.selectedTable === tableTitles.petitions) {
                  setSearchData({ ...searchData, key: event.target.value });
                }
              }}
              onSearchSubmit={(type) => {
                if (type === "arrow") {
                  setSearchData({
                    ...searchData,
                    submittedKey: searchData.key,
                  });
                }
                if (type === "close") {
                  setSearchData({
                    key: "",
                    submittedKey: "",
                  });
                }
              }}
              searchData={searchData}
              clearSearch={() => {
                setSearchData("", null);
              }}
              clearAll={() => {
                props.setTableFilter(
                  props.table.selectedTable,
                  defaultFilterData[
                    transformTableName(props.table.selectedTable)
                  ]
                );
              }}
              isFilterApplied={isFilterApplied}
              table={props.table}
              setTableFilter={props.setTableFilter}
              overLayClick={() => {
                setEnableSearch(false);
              }}
            />
          )}

          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute isAuth={isAuth}>
                  <Login navigate={navigate} theme={"light"} />
                </PublicRoute>
              }
            />

            <Route
              path="/"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <Home
                    isAuth={isAuth}
                    claims={claims}
                    activeSwitch={activeSwitch}
                    setActiveSwitch={setActiveSwitch}
                    showLoginModal={window.location.pathname === "/login"}
                    enableSearch={enableSearch}
                    searchKey={searchData.key}
                    navigate={navigate}
                    isFilterApplied={isFilterApplied(
                      props.table[transformTableName(props.table.selectedTable)]
                        ?.filter,
                      props.table.selectedTable,
                      "header"
                    )}
                    enableFilter={enableFilter}
                    setSelectedFilterKey={setSelectedFilterKey}
                    selectedFilterKey={selectedFilterKey}
                    getComplaintsNextPage={() => {
                      getComplaintsNextPage();
                    }}
                    getPetitionsAssets={getPetitionsAssets}
                    petitionsAssetsLoading={petitionsAssetsLoading}
                    setEnableFilter={setEnableFilter}
                    theme={"light"}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <Profile navigate={navigate} />
                </ProtectedRoute>
              }
            />

            <Route
              path="*"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <Navigate to="/" replace />
                </ProtectedRoute>
              }
            />
          </Routes>

          {window.location.pathname === "/" && !enableFilter && (
            <div
              style={{ height: "58px" }}
              className={`bottom-navigation-background-color-light position-absolute bottom-0 inherit-parent-width display-flex flex-justify-content-center flex-align-items-center `}
              data-cy="bottom-navigation-bar"
            >
              {[
                tableTitles.summary,
                tableTitles.announcement,
                tableTitles.petitions,
                tableTitles.jobs,
                tableTitles.volunteering,
              ].map((data, index) => (
                <div
                  key={index}
                  style={{ height: "40px", width: "175px" }}
                  className={` display-flex flex-align-items-center flex-justify-content-center padding-default cursor-pointer ${
                    data === props.table.selectedTable
                      ? "bottom-primary-underscore"
                      : " bottom-secondary-underscore-1px"
                  }`}
                  onClick={() => {
                    setSelectedTable(data);
                  }}
                  data-cy={`${data}-navigation-button`}
                >
                  <div className="flex-justify-content-center flex-align-items-center ">
                    {getIcons(data, data === props.table.selectedTable)}
                  </div>
                  <div
                    className={`padding-left-default  font-family-RHD-medium  ${
                      data === props.table.selectedTable
                        ? "font-color-primary"
                        : ""
                    }`}
                  >
                    {data}
                  </div>
                </div>
              ))}
            </div>
          )}

          <Modal
            show={enableMenu && window.location.pathname !== "/login"}
            canIgnore={true}
            onClose={() => {
              setEnableMenu(!enableMenu);
            }}
            width="width-fit-content"
            boxShadow="none"
            // maxWidth="max-width-528px"
            background="background-transparent"
            height=" inherit-parent-height"
            position={" position-absolute bottom-0 "}
          >
            <div
              style={{ width: "230px" }}
              className={`background-color-light inherit-parent-height  font-family-gilroy-medium  background-white `}
              data-cy="logout-confirmation-modal"
            >
              <div className="display-flex flex-align-items-center padding-left-larger padding-top-default padding-bottom-default">
                <div
                  onClick={() => {
                    setEnableMenu(false);
                  }}
                  className="cursor-pointer"
                  data-cy={"jam-menu-icon"}
                >
                  <CloseIcon color="white" width={16} height={16} />
                </div>
                <div className="cursor-pointer margin-left-larger margin-right-larger">
                  {/* <img
                    src={chennaiCorporationLogo}
                    alt="chennai-corporation-logo"
                    width={32}
                  /> */}

                  <div
                    style={{
                      height: "32px",
                      width: "32px",
                      backgroundColor: "black",
                    }}
                  />
                </div>
                <div className="font-size-18px cursor-pointer padding-right-larger  margin-right-larger">
                  0.25
                </div>
              </div>
              <div className=" remaining-body-height-from-header flex-direction-column flex-justify-content-space-between ">
                <div>
                  {menu.map((menu, index) => (
                    <div
                      key={index}
                      style={{ width: "214px" }}
                      className={`${
                        getMenuTitle(window.location.pathname) === menu.title
                          ? "background-color-primary font-color-dark"
                          : ""
                      } display-flex flex-align-items-center border-radius-right-4px padding-top-default padding-bottom-default padding-left-larger margin-top-medium margin-bottom-medium width-fit-content cursor-pointer ${
                        menu.title === "Presets"
                          ? isAllowed()
                            ? ""
                            : "font-color-tertiary cursor-no-drop"
                          : ""
                      }`}
                      data-cy={`menu-${menu.title}${
                        props.selectedMenu === menu.title ? "-selected" : ""
                      }`}
                      onClick={() => {
                        if (
                          (menu.title === "Presets" && isAllowed()) ||
                          menu.title !== "Presets"
                        ) {
                          setEnableMenu(false);
                          switch (menu.title) {
                            case "Dashboard":
                              return navigate("/");
                            case "Admin":
                              return navigate("/");
                            case "Presets":
                              return navigate("/presets");
                            case "Manager":
                              return navigate("/");
                            default:
                              break;
                          }
                        }
                      }}
                    >
                      {menu.icon}
                      <div className=" padding-left-default">{menu.title}</div>
                    </div>
                  ))}
                </div>

                <div className=" padding-bottom-larger ">
                  <div
                    style={{ width: "214px" }}
                    className="  cursor-pointer border-radius-right-4px padding-top-default padding-bottom-default padding-left-larger margin-top-medium margin-bottom-medium display-flex flex-align-items-center"
                    onClick={() => {
                      setEnableMenu(false);
                      setLogoutModal(true);
                    }}
                    data-cy={"menu-Logout"}
                  >
                    <LogoutIcon color="white" />
                    <div className=" padding-left-default">Logout</div>
                  </div>
                  <div className="padding-left-larger font-size-small">
                    &copy; ALL RIGHTS RESERVED 2024
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            show={logoutModal}
            canIgnore={true}
            onClose={() => {
              setLogoutModal(!logoutModal);
            }}
            background="background-transparent"
            width="inherit-parent-width"
            boxShadow="box-shadow-none"
            maxWidth="max-width-528px"
            height="height-fit-to-content"
          >
            <div
              className={`background-color-light font-family-gilroy-medium text-align-center padding-larger background-white border-radius-default `}
              data-cy="logout-confirmation-modal"
            >
              <div className="font-family-RHD-medium">LOGOUT</div>
              <div className="font-size-large padding-top-medium">
                Are you sure you want to logout?
              </div>

              <div className="display-flex padding-top-larger">
                <Button
                  boxShadow={false}
                  type="submit"
                  variant="danger"
                  text="Logout"
                  className={" margin-right-medium"}
                  onClick={() => {
                    setEnableMenu(false);
                    logout();
                    setLogoutModal(false);
                  }}
                  data-cy="confirm-logout-button"
                />
                <Button
                  boxShadow={false}
                  text="Cancel"
                  variant="transparent"
                  onClick={() => {
                    setLogoutModal(false);
                  }}
                  className={`font-color-dark border-1px-e5e5e5 margin-left-medium`}
                  data-cy="cancel-logout-button"
                />
              </div>
            </div>
          </Modal>
          <SnackBar
            message={status}
            status={props.status}
            type={props.status.code === null ? "success" : "error"}
            theme={"light"}
          />
        </>
      )}
    </div>
  );
}

const mapStateToProps = function(state) {
  return {
    auth: state.auth,
    algolia: state.algolia,
    locations: state.locations,
    status: state.status,
    table: state.table,
    procedures: state.procedures,
    employees: state.employees,
  };
};

const mapDispatchToProps = function() {
  return {
    setTableFilter: (table, filter) => setTableFilter(table, filter),
    setSelectedTableId: (table, id) => setSelectedTableId(table, id),
    setSelectedTable: (table) => setSelectedTable(table),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

const ProtectedRoute = ({ isAuth, children }) => {
  if (!isAuth) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};

const PublicRoute = ({ isAuth, children }) => {
  if (!isAuth) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const getIcons = (title, selected) => {
  switch (title) {
    case "ANNOUNCEMENT":
      return (
        <AnnouncementIcon
          width="18"
          height="18"
          color={selected ? "#4362EA" : "black"}
        />
      );
    case "PETITIONS":
      return (
        <PetitionIcon
          width="20"
          height="20"
          color={selected ? "#4362EA" : "black"}
        />
      );
    case "JOBS":
      return <JobsIcon color={selected ? "#4362EA" : "black"} />;
    case "VOLUNTEERING":
      return <VolunteerIcon color={selected ? "#4362EA" : "black"} />;
    case "SUMMARY":
      return <SummaryIcon color={selected ? "#4362EA" : "black"} />;

    default:
      break;
  }
};

import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";

export const actionTypes = {
  PUT_ANNOUNCEMENTS_DATA: "PUT_ANNOUNCEMENTS_DATA",
};

function* putAnnouncementsWorker(action) {
  try {
    yield setAnnouncementsLoading(true);
    yield put({
      type: "SET_ANNOUNCEMENTS_DATA",
      payload: {
        data: action.payload.data,
      },
    });
    yield setAnnouncementsLoading(false);
  } catch (error) {
    yield setAnnouncementsLoading(false);
    setErrorStatus(error);
  }
}

export default function* announcementsWatcher() {
  yield all([takeEvery("PUT_ANNOUNCEMENTS_DATA", putAnnouncementsWorker)]);
}

function* setAnnouncementsLoading(bool) {
  yield put({
    type: "SET_ANNOUNCEMENTS_LOADING",
    payload: {
      loading: bool,
    },
  });
}

import React from "react";
import "./TrackProgress.css";
// import {
//   FolderIcon,
//   FolderWithTickIcon,
//   FolderWithWrongIcon,
//   TextWithTickIcon,
//   TextWithWrongIcon,
// } from "../../Assets/assets";
// import { isValidArray } from "../../Services/validators";
import { dateAndTimeConverter } from "../../Utils/constants";
import CarouselAndAudioPlayer from "../CarouselAndAudioPlayer/CarouselAndAudioPlayer";

export default function TrackProgress(props) {
  return (
    <div>
      {props.data?.map((data, index) => (
        <>
          <div className="display-flex flex-align-items-center">
            <div className="width-fit-content height-fit-content">
              <div
                className="background-white"
                style={{
                  height: "12px",
                  width: "12px",
                }}
              />
              {index !== props.data.length - 1 ? (
                <div
                  style={{ height: "115px" }}
                  className="width-16px flex-align-items-center flex-justify-content-center"
                >
                  <div
                    style={{ width: "2px" }}
                    className={`inherit-parent-height ${
                      props.theme === "dark"
                        ? "white-dashed-border"
                        : "black-dashed-border"
                    }`}
                  />
                </div>
              ) : (
                <div style={{ height: "115px" }} />
              )}
            </div>
            <div className="display-flex inherit-parent-width flex-justify-content-space-between ">
              <div className="display-flex flex-direction-column width-80-percentage text-overflow-ellipsis">
                <div className="padding-left-large text-uppercase">
                  {data?.title || data?.remarks}
                </div>

                {data.updatedAt && (
                  <div className="padding-left-large padding-top-small padding-bottom-default font-size-small">
                    {`${dateAndTimeConverter(
                      data.updatedAt,
                      "Time"
                    )} , ${dateAndTimeConverter(data.updatedAt, "cardDate")}`}
                  </div>
                )}
              </div>
              <div>
                <CarouselAndAudioPlayer
                  fileSrc={data?.assets}
                  theme={props.theme}
                  onImageClick={(data) => {
                    props.setPreview(data);
                  }}
                />
              </div>
              <div></div>
            </div>
          </div>
          {data.description ? (
            <div className="display-flex">
              <div className="width-16px flex-align-items-center flex-justify-content-center">
                {!(index === props.data.length - 1) && (
                  <div
                    style={{ width: "2px" }}
                    className={`inherit-parent-height ${
                      props.theme === "dark"
                        ? "white-dashed-border"
                        : "black-dashed-border"
                    } `}
                  />
                )}
              </div>
              <div className="padding-left-large padding-top-small padding-bottom-default">
                {data.description}
              </div>
            </div>
          ) : (
            !(index === props.data.length - 1) && (
              <div className="display-flex">
                <div className="width-16px height-32px flex-align-items-center flex-justify-content-center">
                  <div
                    style={{ width: "2px" }}
                    className={`inherit-parent-height ${
                      props.theme === "dark"
                        ? "white-dashed-border"
                        : "black-dashed-border"
                    }`}
                  />
                </div>
              </div>
            )
          )}

          {data.description && !(index === props.data.length - 1) && (
            <div className="display-flex">
              <div className="width-16px flex-align-items-center flex-justify-content-center">
                <div
                  style={{ width: "2px", height: "9px" }}
                  className={`${
                    props.theme === "dark"
                      ? "white-dashed-border"
                      : "black-dashed-border"
                  }`}
                />
              </div>
              <div className="padding-left-large padding-top-large"></div>
            </div>
          )}
        </>
      ))}
    </div>
  );
}

import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import { uploadCompliantProof } from "../../Services/storage";
import { isValidArray } from "../../Services/validators";
import {
  takeoverPetition,
  updatePetition,
  updateVerifyPetition,
} from "../../Services/database";
import store from "../store";
import { verifyPetition } from "./action";
import { bucketNames } from "../../Utils/constants";

export const actionTypes = {
  PUT_PETITIONS_DATA: "PUT_PETITIONS_DATA",
  VERIFY_PETITION: "VERIFY_PETITION",
  FIX_PETITIONS: "FIX_PETITIONS",
  PETITION_TAKEOVER: "PETITION_TAKEOVER",
};

function* putPetitionsWorker(action) {
  try {
    if (action.payload.type === "SET") {
      yield setPetitionsLoading(true);
      yield put({
        type: "SET_PETITIONS_DATA",
        payload: {
          data: action.payload.data,
        },
      });
    }

    if (action.payload.type === "ADD") {
      yield setPetitionsPaginationLoading(true);
      yield put({
        type: "ADD_PETITIONS_DATA",
        payload: {
          data: action.payload.data,
        },
      });
    }

    yield setPetitionsLoading(false);
    yield setPetitionsPaginationLoading(false);
  } catch (error) {
    yield setPetitionsLoading(false);
    yield setPetitionsPaginationLoading(false);
    setErrorStatus(error);
  }
}

function* verifyPetitionWorker(action) {
  try {
    // yield setPetitionsLoading(true);
    const data = {
      status: {
        currentStatus: action.payload.type === "close" ? "CLOSED" : "OPEN",
        updatedAt: +new Date(),
      },
      closedBy: {
        uid: store.getState().auth.data.uid,
        email: store.getState().auth.data.email,
      },
    };
    if (action.payload.petitionData?.documentId) {
      yield updateVerifyPetition(action.payload.petitionData?.documentId, data);
      setSuccessStatus(
        `Petition ${
          action.payload.type === "close" ? "closed" : "opened"
        } successfully`
      );
    }

    yield setPetitionsLoading(false);
  } catch (error) {
    console.error("verifyPetitionWorker", error);
    yield setPetitionsLoading(false);
    setErrorStatus(error);
  }
}

function* fixPetitionsWorker(action) {
  try {
    yield setPetitionsLoading(true);

    const { proof, petitionId, locationId, employeeId } = action.payload;
    let imageFileUrls = [];
    let audioFileUrls = [];

    const createdAt = store.getState().petitions.data[petitionId].createdAt;

    if (!(createdAt < +new Date())) {
      setErrorStatus({
        code: "custom",
        message: "Please check your device time",
      });
      yield setPetitionsLoading(false);
      return;
    }

    if (isValidArray(proof.image)) {
      const imageUploadResults = yield* uploadFiles(
        proof.image,
        petitionId,
        locationId,
        "closure"
      );

      imageFileUrls = imageUploadResults.map(
        (result) =>
          `${bucketNames.defaultBucket}/petitions/${petitionId}/closure/${result}`
      );
    }

    if (proof.audio) {
      const audioUploadResults = yield* uploadFiles(
        [proof.audio],
        petitionId,
        locationId,
        "closure"
      );
      audioFileUrls = audioUploadResults.map(
        (result) =>
          `${bucketNames.defaultBucket}/petitions/${petitionId}/closure/${result}`
      );
    }

    const filesUrls = [...imageFileUrls, ...audioFileUrls];

    yield updatePetition(petitionId, employeeId, filesUrls);

    verifyPetition(store.getState().petitions?.data?.[petitionId], "close");

    // yield setPetitionsLoading(false);
  } catch (error) {
    console.error("fixPetitionsWorker", error);
    setErrorStatus(error);
    yield setPetitionsLoading(false);
  }
}

function* complaintTakeoverWorker(action) {
  try {
    yield setPetitionsLoading(true);
    yield takeoverPetition(
      action.payload.petitionId,
      action.payload.employeeData
    );

    setSuccessStatus("Compliant Taken over successfully");

    yield setPetitionsLoading(false);
  } catch (error) {
    console.error("complaintTakeoverWorker", error);
    setErrorStatus(error);
    yield setPetitionsLoading(false);
  }
}

export default function* complaintsWatcher() {
  yield all([
    takeEvery("PUT_PETITIONS_DATA", putPetitionsWorker),
    takeEvery("VERIFY_PETITION", verifyPetitionWorker),
    takeEvery("FIX_PETITIONS", fixPetitionsWorker),
    takeEvery("PETITION_TAKEOVER", complaintTakeoverWorker),
  ]);
}

function* setPetitionsLoading(bool) {
  yield put({
    type: "SET_PETITIONS_LOADING",
    payload: {
      loading: bool,
    },
  });
}

function* setPetitionsPaginationLoading(bool) {
  yield put({
    type: "SET_PETITIONS_PAGINATION_LOADING",
    payload: {
      loading: bool,
    },
  });
}

// function* setPetitionsAssetsLoading(bool) {
//   yield put({
//     type: "SET_PETITIONS_ASSETS_LOADING",
//     payload: {
//       assetsLoading: bool,
//     },
//   });
// }

function* uploadFiles(files, petitionId, locationId, folder) {
  const uploadPromises = files.map((file) =>
    uploadCompliantProof(file, petitionId, locationId, folder)
  );
  return yield Promise.all(uploadPromises);
}

import React, { useState, useEffect } from "react";
import "./Modal.css";

function Modal(props) {
  const [show, setShow] = useState();

  useEffect(() => {
    setShow(props.show ? props.show : false);
  }, [props.show]);

  const getBackgroundClassNames = (customClassNames) => {
    return `z-index-101 background-color-black-with-opacity-light position-fixed top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width ${
      customClassNames ? customClassNames : ``
    }`;
  };

  return (
    <>
      {show === true ? (
        <>
          <div
            data-cy="modalBackground"
            className={
              props.RemoveBlackOverlay
                ? ""
                : getBackgroundClassNames(props.modalBackgroundClassName)
            }
            onClick={() => {
              if (props.canIgnore === true) {
                setShow(false);
                if (typeof props.onClose === "function") {
                  props.onClose();
                }
              }
            }}
          ></div>
          <div
            className={`z-index-101 ${props.modalBodyClassName ||
              ``} ${props.borderRadius || "border-radius-default"}
            ${props.position || "position-fixed-center-self"} ${props.height ||
              "minimum-height-250px"}
            ${props.boxShadow || "box-shadow-default"} ${props.background ||
              "background-white"}
            ${props.minWidth || "minimum-width-600px"} ${props.width ||
              "width-auto"}
            ${props.maxWidth || "max-width"} ${props.maxHeight ||
              "max-height"}`}
          >
            {props.children || <div></div>}
          </div>
        </>
      ) : null}
    </>
  );
}

export default Modal;

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./authentication/reducer";
import complaintsReducer from "./petitions/reducer";
import profileReducer from "./profile/reducer";
import statusReducer from "./status/reducer";
import tableReducer from "./table/reducer";
import announcementsReducer from "./announcements/reducer";
import jobsReducer from "./jobs/reducer";
import volunteeringReducer from "./volunteering/reducer";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loading", "credentials"],
};

const announcementsPersistConfig = {
  key: "announcements",
  storage: storage,
  blacklist: ["loading", "credentials"],
};

const jobsPersistConfig = {
  key: "jobs",
  storage: storage,
  blacklist: ["loading"],
};

const volunteeringPersistConfig = {
  key: "volunteering",
  storage: storage,
  blacklist: ["loading"],
};

const profilePersistConfig = {
  key: "profile",
  storage: storage,
  blacklist: ["loading"],
};

const statusPersistConfig = {
  key: "status",
  storage: storage,
  blacklist: ["code", "message"],
};

const complaintsPersistConfig = {
  key: "petitions",
  storage: storage,
  blacklist: ["loading"],
};

const tablePersistConfig = {
  key: "table",
  storage: storage,
  blacklist: ["loading"],
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["auth", "profile", "status"],
};

//root reducer
const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  announcements: persistReducer(
    announcementsPersistConfig,
    announcementsReducer
  ),
  jobs: persistReducer(jobsPersistConfig, jobsReducer),
  volunteering: persistReducer(volunteeringPersistConfig, volunteeringReducer),
  petitions: persistReducer(complaintsPersistConfig, complaintsReducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  status: persistReducer(statusPersistConfig, statusReducer),
  table: persistReducer(tablePersistConfig, tableReducer),
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

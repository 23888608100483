import React, { useState, useEffect } from "react";
import "./Header.css";
import {
  BackArrowIcon,
  CloseIcon,
  FilterIcon,
  LogoutIcon,
  SearchIcon,
} from "../../Assets/assets";
import { transformKey, transformTableName } from "../../Utils/constants";
import { useHorizontalScroll } from "../../Services/useHorizontalScroll";
import { isValidArray } from "../../Services/validators";
import appLogo from "../../Assets/appLogo.png";

export default function Header(props) {
  const showSearchIcon = (key, submittedKey) => {
    if ((key === null || key?.length <= 0) && !submittedKey) {
      return "search";
    }

    if (key && /^[a-zA-Z]{3}\d{5}$/.test(key) && key !== submittedKey) {
      return "arrow";
    }

    if (!/^[a-zA-Z]{3}\d{5}$/.test(key) || key === submittedKey) {
      return "close";
    }
  };

  return (
    <div
      className={` ${
        props.theme === "dark" ? "background-color-dark" : "background-white"
      } border-bottom-tertiary-dark`}
    >
      <div
        className={`height-64px ${props.className} inherit-parent-width display-flex flex-align-items-center padding-top-large padding-bottom-large `}
        data-cy="header"
      >
        <div className="inherit-parent-width">
          <div className="display-flex inherit-parent-width flex-align-items-center flex-justify-content-space-between">
            <div className="display-flex padding-left-larger flex-align-items-center">
                <img
                  src={appLogo}
                  alt="app logo"
                  style={{
                    height: "32px",
                    width: "32px",
                  }}
                />
              <div className="font-size-18px font-family-RHD-medium padding-left-default">
                Arun Nehru
              </div>
            </div>
            <div className="display-flex flex-align-items-center">
              {props?.showSearchBox && (
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    props.onSearchSubmit(
                      showSearchIcon(
                        props.searchData?.key,
                        props.searchData?.submittedKey
                      )
                    );
                  }}
                  style={{ height: 36 }}
                  className={`inherit-parent-width margin-left-larger margin-right-larger `}
                >
                  <div className="display-flex flex-justify-content-center flex-align-items-center  border-bottom-tertiary-dark">
                    <input
                      style={{
                        width: "304px",
                      }}
                      className={` ${
                        props.theme === "dark"
                          ? "background-color-dark font-color-white"
                          : "background-white "
                      } inherit-parent-height border-none font-family-RHD-regular font-size-18px padding-default inherit-parent-width`}
                      value={props.searchData?.key}
                      onChange={props.searchKeyOnChange}
                      placeholder="Search for something..."
                      title="Search bar"
                      data-cy="search-input-field"
                    />
                    <button
                      className="padding-right-default background-color-transparent cursor-pointer"
                      type={"submit"}
                      data-cy={
                        props?.activeSwitch === "TABLE"
                          ? "search-clear-button"
                          : "search-button"
                      }
                    >
                      {showSearchIcon(
                        props.searchData?.key,
                        props.searchData?.submittedKey
                      ) === "search" && (
                        <div>
                          <SearchIcon
                            color={props.theme === "dark" ? "white" : "#404040"}
                          />
                        </div>
                      )}
                      {showSearchIcon(
                        props.searchData?.key,
                        props.searchData?.submittedKey
                      ) === "arrow" && (
                        <div
                          style={{
                            transform: "rotate(180deg)",
                          }}
                        >
                          <BackArrowIcon
                            color={props.theme === "dark" ? "white" : "#404040"}
                          />
                        </div>
                      )}

                      {showSearchIcon(
                        props.searchData?.key,
                        props.searchData?.submittedKey
                      ) === "close" && (
                        <CloseIcon
                          color={props.theme === "dark" ? "white" : "#404040"}
                          width={16}
                          height={16}
                        />
                      )}
                    </button>
                  </div>
                </form>
              )}
            </div>
            <div>
              {
                <div className="display-flex flex-justify-content-center">
                  {/* <div
                  onClick={props.themeOnClick}
                  className="cursor-pointer margin-top-larger margin-bottom-larger margin-left-larger"
                  data-cy="theme-icon"
                >
                  <ThemeIcon
                    color={props.theme === "dark" ? "white" : "#404040"}
                  />
                </div> */}

                  <div
                    onClick={() => {
                      if (props.table.selectedTable === "PETITIONS") {
                        props.filterClick();
                      }
                    }}
                    className="cursor-pointer margin-larger"
                    data-cy="filter-icon"
                  >
                    <FilterIcon
                      color={props.theme === "dark" ? "white" : "#404040"}
                    />
                  </div>
                  <div
                    className="cursor-pointer margin-larger"
                    onClick={() => {
                      props.setLogoutModal(true);
                    }}
                    data-cy={"menu-Logout"}
                  >
                    <LogoutIcon
                      color={props.theme === "dark" ? "white" : "#404040"}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {props.isFilterApplied(
        props.table[transformTableName(props.table.selectedTable)]?.filter,
        props.table.selectedTable,
        "header"
      ) &&
        window.location.pathname === "/" && (
          <div
            className={`inherit-parent-width font-size-medium flex-center-children-vertically padding-left-larger padding-right-larger padding-bottom-default padding-top-default flex-justify-content-space-between  z-index-1 ${props.filterViewClassName} `}
            data-cy="filter-view"
          >
            <div className="inherit-parent-width-subtract-70px ">
              {
                <div className="inherit-parent-width display-flex flex-justify-content-space-between">
                  <div className=" inherit-parent-width display-flex flex-align-items-center">
                    <FilterView
                      setClearForm={props.setClearForm}
                      items={props.items}
                      filterData={
                        props.table[
                          transformTableName(props.table.selectedTable)
                        ]?.filter
                      }
                      table={props.table}
                      setTableFilter={props.setTableFilter}
                      theme={props.theme}
                    />
                  </div>
                </div>
              }
            </div>

            <div
              data-cy="filter-view-clearAll"
              onClick={props.clearAll}
              className={` text-underline cursor-pointer`}
            >
              Clear All
            </div>
          </div>
        )}

      {document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") {
          props.closeClick();
        }
      })}
    </div>
  );
}

const FilterView = (props) => {
  const filterView = useHorizontalScroll();

  const [validFilterData, setValidFilterData] = useState(null);

  useEffect(() => {
    let data = {};

    Object.keys(props.filterData).forEach((key) => {
      if (
        (key !== "dateRange" && isValidArray(props.filterData[key])) ||
        (key === "dateRange" &&
          (props.filterData?.dateRange?.from !== null ||
            props.filterData.dateRange?.to !== null))
      ) {
        data[key] = props.filterData[key];
      }
    });
    setValidFilterData(data);
  }, [props.filterData]);

  return (
    <>
      <div className="font-family-RHD-medium  display-flex padding-right-default">
        {`FILTER`}
        <Spacing /> {"-"} <Spacing />
      </div>

      {
        <div
          ref={filterView}
          className="display-flex flex-align-items-center overflow-scroll hide-scroll-bar"
          data-cy="filter-view"
        >
          {validFilterData &&
            Object.keys(validFilterData)?.map((key, index, filterKeys) => (
              <div className="display-flex" key={index}>
                <div
                  className={`text-capitalize display-flex white-space-nowrap`}
                >
                  {transformKey(key)}
                  <Spacing /> {":"} <Spacing />
                </div>

                {validFilterData[key]?.length > 0 &&
                  key !== "dateRange" &&
                  validFilterData[key]?.map((data, index) => (
                    <>
                      <FilterItem
                        key={`${key}-${index}`}
                        type="filter"
                        data={data}
                        index={index}
                        keyName={key}
                        table={props.table}
                        filterData={props.filterData}
                        setTableFilter={props.setTableFilter}
                        theme={props.theme}
                      />
                      {filterKeys[index + 1] && (
                        <div className="padding-left-default padding-right-default">
                          |
                        </div>
                      )}
                    </>
                  ))}

                {key === "dateRange" &&
                  validFilterData[key]?.from &&
                  validFilterData[key]?.to && (
                    <>
                      <FilterItem
                        keyName={key}
                        table={props.table}
                        data={validFilterData[key]}
                        filterData={props.filterData}
                        setTableFilter={props.setTableFilter}
                        theme={props.theme}
                      />
                      {filterKeys[index + 1] && (
                        <div className="padding-left-default padding-right-default">
                          |
                        </div>
                      )}
                    </>
                  )}
              </div>
            ))}
        </div>
      }
    </>
  );
};

const Spacing = () => {
  return (
    <p
      className="inherit-parent-height"
      style={{
        width: "8px",
      }}
    />
  );
};

const getValidStatus = (key) => {
  switch (key) {
    case "Optimal":
      return "Green";
    case "Moderate":
      return "Yellow";
    case "Critical":
      return "Red";
    case "UNDER REVIEW":
      return "Under Review";
    case "OPEN":
      return "Open";
    case "CLOSED":
      return "Closed";

    default:
      return;
  }
};

const FilterItem = (props) => {
  const handleRemoveClick = () => {
    if (props.keyName === "dateRange") {
      props.setTableFilter(props.table.selectedTable, {
        ...props.filterData,
        [props.keyName]: {
          from: null,
          to: null,
        },
      });
    } else {
      props.setTableFilter(props.table.selectedTable, {
        ...props.filterData,
        [props.keyName]: props.filterData?.[props.keyName]?.filter(
          (d) => d !== props.data
        ),
      });
    }
  };

  const getFilterContentAndCyKey = (type, data) => {
    let content;
    let cyKey;

    switch (type) {
      case "issueTakenBy":
      case "issueRaised":
        content = data;
        cyKey = `header-filter-view-${type}-${data}`;
        break;
      case "status":
        content = getValidStatus(data);
        cyKey = `header-filter-view-status-${getValidStatus(data)}`;
        break;
      case "dateRange":
        content = `${data?.from} - ${data?.to}`;
        cyKey = "header-filter-view-dateRange";
        break;
      default:
        return null;
    }
    return { content, cyKey };
  };

  return (
    <div
      className="display-flex padding-left-medium font-family-RHD-medium text-underline white-space-nowrap"
      data-cy={getFilterContentAndCyKey(props.keyName, props.data)?.cyKey}
      onClick={handleRemoveClick}
      key={props?.key}
    >
      {getFilterContentAndCyKey(props.keyName, props.data)?.content && (
        <div className=" display-flex flex-justify-content-center cursor-pointer white-space-nowrap">
          {getFilterContentAndCyKey(props.keyName, props.data)?.content}
        </div>
      )}
      <Spacing />
      <div
        className={`${
          props.index === props.filterData?.[props.keyName]?.length - 1
            ? "padding-right-default"
            : ""
        } cursor-pointer padding-top-smaller padding-left-smaller`}
      >
        <CloseIcon
          height={12}
          width={12}
          color={props.theme === "dark" ? "white" : "#404040"}
        />
      </div>
    </div>
  );
};

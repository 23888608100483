import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import {
  AddIcon,
  CloseIcon,
  NoImageProvidedIcon,
  PdfFileIcon,
} from "../../Assets/assets";
import { isValidArray, isValidObject } from "../../Services/validators";
import { setErrorStatus } from "../../Redux/status/action";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import "../CarouselAndAudioPlayer/CarouselAndAudioPlayer.css";

export default function CarouselAndAudioPlayer(props) {
  const [close, setClose] = useState(false);
  const handleRemoveImage = (proofSrc) => {
    setClose(true);
    props.setCroppedImage({
      images: props?.fileSrc?.images?.filter((data) => data !== proofSrc),
    });
    setClose(false);
  };

  useEffect(() => {
    if (close) {
      props.onImageClick(null);
    }
    // eslint-disable-next-line
  }, [close]);

  const getImageSrc = (proofSrc, add) => {
    if (add && proofSrc instanceof File) {
      try {
        return URL.createObjectURL(proofSrc);
      } catch (error) {
        console.error("Error creating object URL:", error);
        return null;
      }
    }
    return proofSrc;
  };

  return (
    <div
      className="inherit-parent-height width-fit-content display-flex flex-direction-column flex-align-items-center flex-justify-content-center"
      data-cy={`${props.dataCy}-carousel-component`}
    >
      <div className="cursor-pointer display-flex">
        <Carousel
          autoPlay={false}
          centerSlidePercentage={50}
          infiniteLoop={false}
          onClickItem={(_index, item) => {
            if (!close) {
              props.onImageClick(
                item?.props?.children?.props?.children?.[1]?.props?.src
              );
            }
          }}
          selectedItem={props.add ? props?.fileSrc?.images?.length - 1 : null}
          width="300px"
          showArrows={true}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
        >
          {props?.fileSrc?.images?.map(
            (proofSrc, index) =>
              proofSrc && (
                <div
                  key={index}
                  className="display-flex flex-align-items-center flex-justify-content-center"
                >
                  <div
                    style={{
                      width: "241.8px",
                    }}
                  >
                    {props.add && (
                      <div
                        className="display-flex flex-justify-content-center flex-align-items-center cursor-pointer"
                        onClick={() => {
                          handleRemoveImage(proofSrc);
                        }}
                        onMouseEnter={() => {
                          setClose(true);
                        }}
                        onMouseLeave={() => {
                          setClose(false);
                        }}
                        style={{
                          borderRadius: "100%",
                          width: 18,
                          zIndex: 1,
                          height: 18,
                          backgroundColor: "black",
                          position: "absolute",
                          top: 4,
                          right: 34,
                        }}
                      >
                        <CloseIcon height="12" width="12" color="white" />
                      </div>
                    )}
                    <img
                      className="margin-right-large"
                      src={getImageSrc(proofSrc, props.add)}
                      alt={`item-${index}`}
                      style={{
                        maxWidth: "241.8px",
                        height: "108px",
                        objectFit: "cover",
                      }}
                      data-cy={`petition-proof-${index + 1}`}
                    />
                  </div>
                </div>
              )
          )}
        </Carousel>

        {isValidArray(props?.fileSrc?.pdfs) && (
          <div className="inherit-parent-height inherit-parent-width display-flex flex-justify-content-space-between">
            {props?.fileSrc?.pdfs?.map((_data, index) => (
              <div
                key={index}
                style={{
                  width: "241.8px",
                  height: "108px",
                  backgroundColor: "#757575",
                }}
                onClick={() => {
                  props.onPDFClick(index);
                }}
                className="border-radius-default margin-right-large font-color-dark flex-align-items-center flex-justify-content-center"
              >
                <PdfFileIcon height={40} width={40} />
              </div>
            ))}
          </div>
        )}

        {props.fileSrc?.images?.length <= 4 && props.add && (
          <form
            className="inherit-parent-width padding-left-default flex-align-items-center display-flex"
            onChange={(event) => {
              if (event.target.files) {
                if (
                  ["image/png", "image/jpeg", "image/jpg"].includes(
                    event.target.files[0]?.type
                  ) === false
                ) {
                  return setErrorStatus({
                    code: "custom",
                    message: "Kindly upload a valid File",
                  });
                }

                if (
                  ["image/png", "image/jpeg", "image/jpg"].includes(
                    event.target.files[0]?.type
                  ) === true
                ) {
                  props.setFile({
                    ...props.file,
                    image: [
                      ...props.file.image,
                      URL.createObjectURL(event.target.files[0]),
                    ],
                  });
                }
              }
            }}
          >
            <div className="height-width-56px margin-bottom-default cursor-pointer">
              <label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  name={"fileUploaderInput"}
                  onClick={(event) => {
                    event.target.value = "";
                  }}
                  className="visibility-hidden position-absolute"
                />
                <div
                  className="padding-bottom-medium margin-right-larger display-flex flex-justify-content-center flex-align-items-center background-color-grey-C2C2C2"
                  style={{
                    width: "241.8px",
                    height: "108px",
                    backgroundColor: "#6D6D6D40",
                  }}
                  data-cy="Add-image-icon"
                  key="add-icon"
                >
                  <AddIcon color="white" />
                </div>
              </label>
            </div>
          </form>
        )}
      </div>

      {!isValidObject(props?.fileSrc) && !props.add && (
        <div
          className="display-flex flex-justify-content-center flex-align-items-center"
          style={{
            width: "300px",
          }}
          data-cy="no-image-provided-icon"
        >
          <div
            className="display-flex flex-justify-content-center flex-align-items-center background-color-grey-C2C2C2 cursor-no-drop"
            style={{
              width: "241.8px",
              height: "108px",
              backgroundColor: "#6D6D6D40",
            }}
          >
            {props.petitionsAssetsLoading ? (
              <div className="padding-left-default padding-right-default padding-top-small padding-bottom-small border-radius-default ">
                <div className="qr-loader" data-cy="qr-loader" />
              </div>
            ) : (
              <NoImageProvidedIcon />
            )}
          </div>
        </div>
      )}
      {!props.add && typeof props.fileSrc?.audio === "string" && (
        <AudioPlayer
          className={`inherit-parent-width padding-vertical-small ${
            typeof props.fileSrc?.audio !== "string"
              ? "cursor-no-drop"
              : "cursor-pointer"
          }`}
          src={
            isValidObject(props?.fileSrc) &&
            typeof props.fileSrc?.audio === "string"
              ? props.fileSrc?.audio
              : ""
          }
          theme={props.theme}
          dataCy={`${props.dataCy}-audio-player`}
        />
      )}
    </div>
  );
}

import { all } from "redux-saga/effects";
import authWatcher from "./authentication/saga";
import complaintsWatcher from "./petitions/saga";
import profileWatcher from "./profile/saga";
import statusWatcher from "./status/saga";
import tableWatcher from "./table/saga";
import announcementsWatcher from "./announcements/saga";
import jobsWatcher from "./jobs/saga";
import volunteeringWatcher from "./volunteering/saga";

export default function* rootSaga() {
  yield all([
    authWatcher(),
    profileWatcher(),
    announcementsWatcher(),
    jobsWatcher(),
    volunteeringWatcher(),
    statusWatcher(),
    complaintsWatcher(),
    tableWatcher(),
  ]);
}

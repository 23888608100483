import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module PetitionsSaga
 */

/**
 *
 * @param {data} payload
 */

export function setPetitionsData(data, type) {
  store.dispatch({
    type: actionTypes.PUT_PETITIONS_DATA,
    payload: {
      data: data,
      type: type,
    },
  });
}

/**
 *
 * @param {petitionData} payload
 */
export function verifyPetition(petitionData, type) {
  store.dispatch({
    type: actionTypes.VERIFY_PETITION,
    payload: {
      petitionData: petitionData,
      type: type,
    },
  });
}

/**
 *
 * @param {data} payload complains data
 */
export function fixPetitions(petitionId, locationId, employeeId, proof) {
  store.dispatch({
    type: actionTypes.FIX_PETITIONS,
    payload: {
      petitionId: petitionId,
      employeeId: employeeId,
      locationId: locationId,
      proof: proof,
    },
  });
}

export function petitionTakeover(petitionId, data) {
  store.dispatch({
    type: actionTypes.PETITION_TAKEOVER,
    payload: {
      petitionId: petitionId,
      employeeData: data,
    },
  });
}

import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module VolunteeringSaga
 */

/**
 *
 * @param {data} payload
 */

export function setVolunteeringData(data) {
  store.dispatch({
    type: actionTypes.PUT_VOLUNTEERING_DATA,
    payload: {
      data: data,
    },
  });
}

export function getVolunteeringAssets(data) {
  store.dispatch({
    type: actionTypes.GET_VOLUNTEERING_ASSETS,
    payload: {
      data: data,
    },
  });
}

/**
 *
 * @param {data} payload
 */

export function updateVolunteeringStatus(data) {
  store.dispatch({
    type: actionTypes.UPDATE_VOLUNTEERING_STATUS,
    payload: {
      volunteeringId: data,
    },
  });
}

import React, { useEffect, useState } from "react";
import ControlButton from "../ControlButton/ControlButton";
import { isValidArray } from "../../Services/validators";
import { BackArrowIcon } from "../../Assets/assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { dateAndTimeConverter } from "../../Utils/constants";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import CropperModal from "../CropperModal/CropperModal";
import TrackProgress from "../TrackProgress/TrackProgress";
import CarouselAndAudioPlayer from "../CarouselAndAudioPlayer/CarouselAndAudioPlayer";

export default function JobsPreview(props) {
  const [activeOption, setActiveOption] = useState("DETAILS");
  const [file, setFile] = useState({ image: [], audio: null });
  const [croppedImage, setCroppedImage] = useState({ images: [] });

  useEffect(() => {
    if (
      activeOption === "UPDATE" &&
      props.jobData?.status.currentStatus === "CLOSED"
    ) {
      setActiveOption("MANAGE");
    }
    // eslint-disable-next-line
  }, [props.jobData]);

  return (
    <div
      data-cy="job-modal"
      className={` ${
        props.theme === "dark"
          ? "background-color-secondary"
          : "background-white"
      } inherit-parent-height inherit-parent-width display-flex padding-larger min-width-170px overflow-hidden flex-direction-column font-family-RHD-regular`}
    >
      {/* Header-section */}
      <div className="inherit-parent-width padding-top-default padding-bottom-default margin-bottom-medium flex-align-items-center display-flex ">
        <div
          className="font-size-larger font-family-RHD-medium text-uppercase padding-top-default padding-bottom-default"
          data-cy={props.jobData?.documentId || "N/A"}
        >
          JOB &nbsp; #{props.jobData?.documentId || "N/A"}
        </div>
        <div
          style={{
            backgroundColor: "#f0f0f0",
            borderRadius: "16px",
          }}
          className="font-size-default text-uppercase margin-left-large padding-left-large font-family-RHD-medium padding-right-large padding-top-small padding-bottom-small flex-align-items-center flex-justify-content-center"
        >
          {props.jobData?.status.currentStatus}
        </div>
        {(activeOption === "VERIFY" ||
          activeOption === "UPDATE" ||
          activeOption === "PETITION-UPDATES") && (
          <div className="display-flex flex-align-items-center">
            <div
              className="cursor-pointer padding-top-default width-fit-content "
              onClick={() => {
                setActiveOption("MANAGE");
              }}
            >
              <BackArrowIcon color={"white"} />
            </div>
            {activeOption === "PETITION-UPDATES" && (
              <div className="padding-left-large">UPDATES</div>
            )}
          </div>
        )}
      </div>
      <div className="display-flex inherit-parent-height">
        <div
          className="inherit-parent-height display-flex flex-direction-column grey-border-right"
          data-cy="header-section"
        >
          {["DETAILS", "MANAGE"].map((header, index) => (
            <ControlButton
              key={index}
              text={header}
              selected={
                (activeOption === "VERIFY" ||
                  activeOption === "UPDATE" ||
                  activeOption === "PETITION-UPDATES") &&
                header === "MANAGE"
                  ? true
                  : activeOption === header
              }
              onClick={() => {
                setActiveOption(header);
              }}
              theme={props.theme}
              dataCy={
                activeOption === header
                  ? `header-selected-${header?.toLowerCase()}`
                  : `header-select-${header?.toLowerCase()}`
              }
            />
          ))}
        </div>

        <div
          style={{ width: "calc(100% - 196px)" }}
          className="inherit-parent-height display-flex flex-direction-column padding-left-larger font-size-default "
          data-cy="jobs-body-section"
        >
          {/* job choice */}
          {activeOption === "DETAILS" && (
            <div
              className="inherit-parent-width  overflow-scroll"
              data-cy="job-description-section"
              style={{
                height: "calc(100% - 50px)",
              }}
            >
              <ComplaintSelectCard
                jobsAssetsLoading={props.jobsAssetsLoading}
                theme={props.theme}
                setPreview={(data, type) => {
                  props.setPreviewFile(data, type);
                }}
                jobData={props.jobData}
              />
            </div>
          )}

          {/* manage choice */}
          {activeOption === "MANAGE" && (
            <div
              className="inherit-parent-height inherit-parent-width display-flex flex-direction-column font-size-default overflow-scroll"
              data-cy="manage-description-section"
            >
              <div className="flex-justify-content-space-between inherit-parent-height flex-direction-column">
                <div>
                  {[
                    props.jobData?.takenOverBy
                      ? {
                          title: "UNDERTAKEN BY",
                          value: `${props.jobData?.takenOverBy?.phoneNumber ||
                            props.jobData?.takenOverBy?.email} , ${props.jobData
                            ?.takenOverBy?.employeeName ||
                            props.jobData?.takenOverBy?.uid ||
                            "N/A"}`,
                        }
                      : {},
                    props.jobData?.takenOverBy
                      ? {
                          title: "UNDERTAKEN AT",
                          value:
                            `${dateAndTimeConverter(
                              props.jobData?.status?.updatedAt,
                              "Time"
                            )}, ${dateAndTimeConverter(
                              props.jobData?.status?.updatedAt,
                              "cardDate"
                            )}` || "N/A",
                          dataCy: dateAndTimeConverter(
                            props.jobData?.status?.updatedAt,
                            "thirdDate"
                          ),
                        }
                      : {},

                    {
                      title: "UPDATED AT",
                      value:
                        `${dateAndTimeConverter(
                          props.jobData?.status?.lastUpdatedAt,
                          "Time"
                        )}, ${dateAndTimeConverter(
                          props.jobData?.status?.lastUpdatedAt,
                          "cardDate"
                        )}` || "N/A",
                      dataCy: dateAndTimeConverter(
                        props.jobData?.status?.lastUpdatedAt,
                        "thirdDate"
                      ),
                    },
                    isValidArray(props.jobData?.updates)
                      ? {
                          title: "UPDATES",
                          dataCy: dateAndTimeConverter(
                            props.jobData?.updates?.[0]?.updatedAt,
                            "thirdDate"
                          ),
                          value: (
                            <div className="display-flex flex-justify-content-space-between flex-align-items-center">
                              <div>
                                <div
                                  style={{
                                    minWidth: "200px",
                                  }}
                                  className="text-uppercase width-90-percentage text-overflow-ellipsis text-over"
                                >
                                  {
                                    props.jobData?.updates?.[
                                      props.jobData?.updates.length - 1
                                    ]?.remarks
                                  }
                                </div>
                                <div
                                  className="padding-top-small font-size-small"
                                  data-cy={dateAndTimeConverter(
                                    props.jobData?.updates?.[0]?.updatedAt,
                                    "thirdDate"
                                  )}
                                >{`${dateAndTimeConverter(
                                  props.jobData?.updates?.[0]?.updatedAt,
                                  "Time"
                                )}, ${dateAndTimeConverter(
                                  props.jobData?.updates?.[0]?.updatedAt,
                                  "cardDate"
                                )}`}</div>
                              </div>
                              <div
                                onClick={() => {
                                  setActiveOption("PETITION-UPDATES");
                                }}
                                data-cy="updates-view-more"
                                className="text-underline cursor-pointer font-size-small"
                              >
                                VIEW MORE
                              </div>
                            </div>
                          ),
                        }
                      : {},
                  ].map(
                    (data, index) =>
                      data.title && (
                        <div key={`${index}-${data?.title}`}>
                          <Fields
                            title={data?.title}
                            dataCy={data?.dataCy || false}
                            data={
                              isValidArray(data?.value) ? (
                                <div
                                  className="display-flex flex-direction-column inherit-parent-width overflow-scroll padding-bottom-medium"
                                  style={{
                                    maxHeight: "172px",
                                  }}
                                >
                                  {data?.value?.map((element, index) => (
                                    <div
                                      className={` display-flex text-uppercase ${
                                        index !== 0 ? "padding-top-default" : ""
                                      } ${
                                        index !== data?.value?.length - 1
                                          ? "padding-bottom-default"
                                          : ""
                                      }`}
                                      key={index}
                                      data-cy={`${element?.phoneNumber} , ${element?.name}`}
                                    >
                                      <p
                                        style={{
                                          width: "114px",
                                          minWidth: "114px",
                                          maxWidth: "114px",
                                        }}
                                      >
                                        {element?.phoneNumber}
                                      </p>
                                      <p className="padding-horizontal-default">
                                        ,
                                      </p>
                                      <p className="white-space-no-wrap">
                                        {element?.name}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div data-cy={data?.value}> {data?.value} </div>
                              )
                            }
                          />
                        </div>
                      )
                  )}
                </div>

                <div
                  style={{
                    position: "absolute",
                    bottom: "24px",
                    right: "24px",
                  }}
                  className="display-flex"
                >
                  <div className=" inherit-parent-width flex-justify-content-end">
                    <Button
                      boxShadow={false}
                      type="button"
                      variant="primary"
                      text="NO LONGER ACCEPTING"
                      style={{ width: "200px" }}
                      disabled={props.jobData.status.currentStatus === "CLOSED"}
                      onClick={() => {
                        props.updateJobStatus(props.jobData?.documentId);
                        // setActiveOption("UPDATE");
                      }}
                      data-cy={"no-longer-accepting-jobs-button"}
                    />
                  </div>

                  {props.jobData?.status?.currentStatus === "OPEN" &&
                    props.jobData?.takenOverBy?.uid === props.auth.data.uid && (
                      <div className=" inherit-parent-width flex-justify-content-end">
                        <Button
                          boxShadow={false}
                          type="button"
                          style={{ width: "200px" }}
                          variant="primary"
                          text="FIX COMPLIANT"
                          onClick={() => {
                            setActiveOption("UPDATE");
                          }}
                          data-cy={"fix-complaint-button"}
                        />
                      </div>
                    )}

                  {props.jobData?.status?.currentStatus === "UNDER REVIEW" && (
                    <div className=" inherit-parent-width flex-justify-content-end">
                      <Button
                        boxShadow={false}
                        type="button"
                        variant="primary"
                        text="Verify"
                        style={{ width: "200px" }}
                        onClick={() => {
                          setActiveOption("VERIFY");
                        }}
                        data-cy={"verify-button"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {activeOption === "VERIFY" && (
            <div
              className="inherit-parent-height inherit-parent-width display-flex flex-direction-column font-size-default overflow-scroll"
              data-cy="manage-description-section"
            >
              {props.jobData?.status?.currentStatus === "UNDER REVIEW" && (
                <div className="inherit-parent-height inherit-parent-width flex-justify-content-center">
                  <div
                    style={{ paddingBottom: "32px" }}
                    className="display-flex flex-direction-column width-70-percentage inherit-parent-height flex-justify-content-center"
                  >
                    <div className="text-align-center padding-bottom-larger">
                      YOU CAN CLOSE THE PETITION OR REOPEN IF THE MEDIA IS
                      INCORRECT
                    </div>
                    <div className="display-flex inherit-parent-width flex-justify-content-space-around">
                      <Button
                        boxShadow={false}
                        type="button"
                        variant="transparent"
                        text="Close Compliant"
                        className="border-1px-e5e5e5 margin-right-small width-224-px margin-right-larger"
                        onClick={() => {
                          props.verifyPetition(
                            {
                              documentId: props.jobData?.documentId,
                            },
                            "close"
                          );
                          setActiveOption("MANAGE");
                        }}
                        data-cy={"close-button"}
                      />
                      <Button
                        boxShadow={false}
                        type="button"
                        variant="transparent"
                        text="ReOpen"
                        className="border-1px-e5e5e5  margin-right-small width-224-px  margin-left-larger"
                        onClick={() => {
                          props.verifyPetition(
                            {
                              documentId: props.jobData?.documentId,
                            },
                            "open"
                          );
                          setActiveOption("MANAGE");
                        }}
                        data-cy={"open-button"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {activeOption === "UPDATE" && (
            <div
              className="inherit-parent-height inherit-parent-width display-flex flex-direction-column font-size-default over-flow-hidden"
              data-cy="manage-description-section"
            >
              <div className="display-flex flex-justify-content-space-between inherit-parent-height flex-direction-column">
                <div className="padding-vertical-default">
                  <Fields
                    title="UPLOAD FILES"
                    dataCy="upload-file-section"
                    data={
                      <div className="display-flex">
                        <CarouselAndAudioPlayer
                          jobsAssetsLoading={props.jobsAssetsLoading}
                          setCroppedImage={setCroppedImage}
                          fileSrc={croppedImage}
                          dataCy="update-section"
                          theme={props.theme}
                          setFile={setFile}
                          file={file}
                          add={true}
                          onImageClick={(data) => {
                            // props.setPreviewFile(data);
                          }}
                        />
                      </div>
                    }
                  />
                  {/* <Fields
                  title="ADD VOICE NOTE"
                  data={
                    <StartRecording
                      file={file}
                      setFile={setFile}
                      theme={props.theme}
                    />
                  }
                  dataCy="add-voice-note-section"
                /> */}
                </div>
                <div className="inherit-parent-width flex-justify-content-end">
                  <Button
                    boxShadow={false}
                    type="button"
                    loading={props.jobLoading}
                    variant="primary"
                    text="UPLOAD"
                    style={{
                      width: "200px",
                    }}
                    disabled={croppedImage?.images?.length <= 0 ? true : false}
                    onClick={() => {
                      props.fixPetitions({
                        documentId: props.jobData?.documentId,
                        locationId: props.jobData?.locationId,
                        proof: {
                          image: croppedImage.images,
                          audio: file.audio,
                        },
                      });
                    }}
                    data-cy="close-button"
                  />
                </div>
              </div>
            </div>
          )}

          {activeOption === "PETITION-UPDATES" && (
            <div className="overflow-scroll hide-scroll-bar">
              <TrackProgress
                data={props.jobData?.updates}
                setSelectedFolderIndex={(index) => {
                  props.onFolderOnClick(index);
                }}
                setPreview={(data) => {
                  props.setPreviewFile(data);
                }}
                theme={props.theme}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        show={isValidArray(file?.image)}
        canIgnore={true}
        onClose={() => {
          setFile({ ...file, image: [] });
        }}
        width="inherit-parent-width"
        maxWidth="max-width-800px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="inherit-parent-height max-height-500px"
      >
        <div
          data-cy="cropper-modal"
          className={`background-white inherit-parent-height border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <CropperModal
            theme={props.theme}
            className="border-radius-default"
            OnBlobCreate={(blob) => {
              const imageFile = new File([blob], `${+new Date()}.png`, {
                type: "image/png",
              });
              setCroppedImage({ images: [...croppedImage.images, imageFile] });
            }}
            file={file.image}
            setFile={() => {
              setFile({ ...file, image: [] });
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

const ComplaintSelectCard = (props) => {
  const getRaisedBy = (data) => {
    if (data) {
      return `${data?.phoneNumber} , ${data?.email}`;
    }
  };
  return (
    <div
      className="inherit-parent-width display-flex flex-direction-column "
      key={props?.key}
      data-cy={`job-view-section`}
    >
      {[
        {
          title: "DESCRIPTION",
          value: props.jobData?.description?.english || "N/A",
        },
        {
          title: "STATUS",
          value: props.jobData?.status?.currentStatus || "N/A",
        },
        {
          title: "COMPANY",
          value: props.jobData?.company?.name.english || "N/A",
        },
        {
          title: "CONTACT",
          value: getRaisedBy(props.jobData?.contact) || "N/A",
        },
        {
          title: "LOCATION",
          value: props.jobData?.location?.city.english || "N/A",
        },
        {
          title: "RAISED ON",
          value: props.jobData?.createdAt
            ? `${dateAndTimeConverter(
                props.jobData?.createdAt,
                "Time"
              )}, ${dateAndTimeConverter(props.jobData?.createdAt, "cardDate")}`
            : "N/A",
          dataCy: dateAndTimeConverter(props.jobData?.createdAt, "thirdDate"),
        },
        {
          title: "LOGO",
          value: (
            <div
              style={{
                width: "52px",
                height: "52px",
              }}
            >
              {!props.jobData?.company?.logoSrc?.url ? (
                <div className=" flex-align-items-center flex-justify-content-center inherit-parent-height inherit-parent-width">
                  <div className="qr-loader" data-cy="qr-loader" />
                </div>
              ) : (
                <img
                  style={{
                    width: "52px",
                    height: "52px",
                  }}
                  src={props.jobData?.company?.logoSrc?.url}
                  alt="logo"
                />
              )}
            </div>
          ),
          dataCy: "company-log",
        },
      ].map((data, index) => (
        <div className="inherit-parent-width" key={`${index}-${data?.issue}`}>
          <Fields
            title={data?.title}
            data={data?.value}
            dataCy={data?.dataCy}
          />
        </div>
      ))}
    </div>
  );
};

const Fields = (props) => {
  return (
    <div className="display-flex flex-justify-content-space-between padding-vertical-medium">
      <div className="display-flex">
        <div
          className="white-space-no-wrap"
          style={{ width: "180px" }}
          data-cy={`${props.title}`}
        >
          {props.title}
        </div>
        <div className="padding-horizontal-medium">:</div>
      </div>
      <div
        style={typeof props.data === "string" ? {} : { minWidth: "300px" }}
        className={`text-align-left height-fit-content inherit-parent-width text-uppercase ${
          typeof props.data === "string" ? "" : "white-space-no-wrap"
        } `}
        data-cy={props.dataCy || props.data}
      >
        {props.data}
      </div>
    </div>
  );
};

// const StartRecording = (props) => {
//   const [voiceRecorderControl, setVoiceRecorderControl] = useState(null);
//   const { startRecording, saveRecording } = useVoiceRecorder();
//   const [counter, setCounter] = useState(null);
//   const [running, setRunning] = useState(false);
//   const [recordedAudio, setRecordedAudio] = useState({
//     audioBlob: false,
//     audioURL: "",
//   });

//   useEffect(() => {
//     let counterTimer;
//     if (
//       counter !== null &&
//       typeof counter === "number" &&
//       counter <= voiceRecorderTimeLimit &&
//       running
//     ) {
//       counterTimer = setTimeout(() => {
//         setCounter(counter + 1);
//       }, 1000);
//     } else {
//       return () => clearTimeout(counterTimer);
//     }
//     if (counter === voiceRecorderTimeLimit) {
//       setVoiceRecorderControl("stop");
//       voiceRecorderHandler();
//     }
//     // eslint-disable-next-line
//   }, [counter]);

//   useEffect(() => {
//     if (recordedAudio?.audioURL) {
//       setVoiceRecorderControl(null);
//     }
//   }, [recordedAudio?.audioURL]);

//   const voiceRecorderHandler = async () => {
//     if (voiceRecorderControl === null || voiceRecorderControl === "cancel") {
//       setVoiceRecorderControl("start");
//       setCounter(0);
//       setRunning(true);

//       const res = await startRecording();
//       if (res === false) {
//         /**
//          * voice recorder returns false is microphone is detected or any other issues in recording
//          */
//         setVoiceRecorderControl(null);
//       }
//     } else if (voiceRecorderControl === "start") {
//       setVoiceRecorderControl("stop");
//       setCounter(null);
//       setRunning(false);
//       const resBlob = await saveRecording();
//       setRecordedAudio({
//         audioBlob: resBlob,
//         audioURL: URL.createObjectURL(resBlob),
//       });

//       const audioFile = new File([resBlob], `${+new Date()}.mp3`, {
//         type: "audio/mp3",
//       });

//       props.setFile({
//         ...props.file,
//         audio: audioFile,
//       });

//       props.setClearAudio && props.setClearAudio(false);
//     } else if (voiceRecorderControl === "stop") {
//       setVoiceRecorderControl("cancel");
//       setRecordedAudio({
//         audioBlob: false,
//         audioURL: "",
//       });
//     }
//   };

//   return (
//     <div>
//       {!recordedAudio?.audioURL && (
//         <div className="flex-justify-content-space-between inherit-parent-width flex-align-items-center">
//           <div className="display-flex flex-align-items-center">
//             <div
//               className="flex-place-children-page-center  border-radius-50-percentage"
//               onClick={() => {
//                 voiceRecorderHandler();
//               }}
//               data-cy={`${
//                 voiceRecorderControl === "start"
//                   ? "stop-record-button"
//                   : "start-record-button"
//               }`}
//             >
//               {voiceRecorderControl === "start" ? (
//                 <StopIcon color="white" />
//               ) : (
//                 <MicIcon color="white" />
//               )}
//             </div>
//             <div className="padding-left-large">
//               {voiceRecorderControl === "start"
//                 ? "RECORDING..."
//                 : "TAP THE MIC TO RECORD AUDIO"}
//             </div>
//           </div>
//           {voiceRecorderControl === "start" && (
//             <div style={{ width: 50 }}>
//               00:{counter?.toString().length === 1 ? "0" : ""}
//               {counter}
//             </div>
//           )}
//         </div>
//       )}

//       {recordedAudio?.audioURL && (
//         <AudioPlayer
//           theme={props.theme}
//           className={"flex-basis-80-percentage"}
//           closeOnClick={() => {
//             setRecordedAudio({
//               audioBlob: false,
//               audioURL: "",
//             });
//           }}
//           src={
//             typeof recordedAudio.audioURL === "string"
//               ? recordedAudio.audioURL
//               : ""
//           }
//         />
//       )}
//     </div>
//   );
// };

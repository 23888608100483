import { defaultFilterData } from "../../Utils/constants";

const tableReducer = (
  state = {
    loading: false,
    selectedTable: "PETITIONS",
    petitions: {
      searchKey: null,
      selectedPetition: null,
      filter: defaultFilterData.petitions,
    },
    jobs: {
      searchKey: null,
      selectedJobs: null,
      filter: defaultFilterData.jobs,
    },
    volunteering: {
      searchKey: null,
      selectedVolunteering: null,
      filter: defaultFilterData.volunteering,
    },
    announcements: {
      searchKey: null,
      selectedAnnouncement: null,
      filter: defaultFilterData.announcements,
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_TABLE_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "PUT_SELECTED_TABLE":
      return {
        ...state,
        selectedTable: action.payload.selectedTable,
      };

    case "PUT_SELECTED_PETITION":
      return {
        ...state,
        petitions: {
          ...state.petitions,
          selectedPetition: action.payload.id,
        },
      };

    case "PUT_SELECTED_JOB":
      return {
        ...state,
        jobs: {
          ...state.jobs,
          selectedJob: action.payload.id,
        },
      };

    case "PUT_SELECTED_VOLUNTEERING":
      return {
        ...state,
        volunteering: {
          ...state.volunteering,
          selectedVolunteering: action.payload.id,
        },
      };

    case "PUT_SEARCH_KEY":
      return {
        ...state,
        petitions: {
          ...state.petitions,
          searchKey: action.payload.searchKey,
        },
      };

    case "PUT_PETITIONS_FILTER":
      return {
        ...state,
        petitions: {
          ...state.petitions,
          filter: action.payload.filter,
        },
      };

    case "RESET":
      return {
        loading: false,
        selectedTable: "PETITIONS",
        petitions: {
          searchKey: null,
          selectedPetition: null,
          filter: defaultFilterData.petitions,
        },
        jobs: {
          searchKey: null,
          selectedJobs: null,
          filter: defaultFilterData.jobs,
        },
        volunteering: {
          searchKey: null,
          selectedVolunteering: null,
          filter: defaultFilterData.volunteering,
        },
        announcements: {
          searchKey: null,
          selectedAnnouncement: null,
          filter: defaultFilterData.announcements,
        },
      };

    default:
      return state;
  }
};
export default tableReducer;

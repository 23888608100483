import React, { useEffect, useState } from "react";
import { isValidArray, isValidObject } from "../../Services/validators";
import "./Filter.css";
import { CloseIcon, ErrorIcon, SearchIcon } from "../../Assets/assets";
import { Regex, getValidStatus } from "../../Utils/constants";
import ToolTip from "../ToolTIp/ToolTip";

export default function Filter(props) {
  const [formData, setFormData] = useState({
    issueRaised: "",
    issueTakenBy: "",
  });

  useEffect(() => {
    if (props.dataCy === "petitions-filter") {
      const issueRaised = props.selectedFilter?.issueRaised?.[0];
      const issueTakenBy = props.selectedFilter?.issueTakenBy?.[0];

      setFormData({
        ...formData,
        issueRaised:
          issueRaised?.length > 0 ? issueRaised?.split("+91")?.[1] : "",
        issueTakenBy:
          typeof issueTakenBy === "string" && issueTakenBy?.length > 0
            ? issueTakenBy
            : "",
      });
    }

    // eslint-disable-next-line
  }, [props.selectedFilter]);

  const isValidPhoneNumber = (phoneNumber, filterName) => {
    if (!phoneNumber) {
      return;
    }
    if (filterName === "issueRaised") {
      if (phoneNumber?.length === 10 && Regex.phoneNumber.test(phoneNumber)) {
        return;
      } else {
        return {
          status: false,
          message: "Phone number must be 10 digits",
        };
      }
    }

    if (filterName === "issueTakenBy") {
      if (Regex.email.test(phoneNumber)) {
        return;
      } else {
        return {
          status: false,
          message: "Please enter valid email id",
        };
      }
    }
  };
  return (
    <div
      className="inherit-parent-height inherit-parent-width display-flex flex-justify-content-space-between"
      data-cy={`${props.dataCy ? props.dataCy : "filter"}-component`}
    >
      <div
        className="inherit-parent-height width-30-percentage display-flex flex-direction-column overflow-scroll "
        style={{
          // left: "24px",
          maxHeight: "960px",
        }}
      >
        {props.filterData
          .filter((data) => data.position === "left")
          .map((data, index, filterDataArray) => (
            <div
              className=" inherit-parent-width display-flex flex-direction-column padding-vertical-default"
              key={`${index}-${data?.title}`}
            >
              {/* title section */}
              <div
                className={`inherit-parent-width text-uppercase font-size-small  position-sticky top-0 padding-horizontal-default  ${
                  props.theme === "dark"
                    ? "background-color-dark"
                    : "background-white"
                }`}
                data-cy={`${props.dataCy}-${data?.title}`}
              >
                <p className="margin-vertical-medium inherit-parent-width">
                  {data.title}
                </p>
              </div>
              {/* form/subtext card - for text values (value - is array of string or numbers) */}
              {data.type === "text" &&
                isValidArray(data.value) &&
                data.value.every(
                  (category) =>
                    typeof category === "string" || typeof category === "number"
                ) &&
                data.value.map((category, index) => (
                  <div
                    key={`${index}-${category}`}
                    className={` inherit-parent-width padding-default margin-vertical-smaller font-size-default text-uppercase cursor-pointer display-flex
                ${
                  props.selectedFilter?.[data.name]?.includes(
                    getValidStatus(category)
                  )
                    ? props.theme === "dark"
                      ? "background-selected-card-gradient-e5e5e5-dark-left-to-right  "
                      : "background-selected-card-gradient-e5e5e5-light-left-to-right"
                    : ""
                }
                `}
                    data-cy={
                      props.selectedFilter?.[data.name]?.includes(
                        getValidStatus(category)
                      )
                        ? `${data.dataCy}${category}-selected`
                        : `${data.dataCy}-${category}`
                    }
                    onClick={() => {
                      if (
                        props.selectedFilter?.[data.name]?.includes(
                          getValidStatus(category)
                        )
                      ) {
                        props.setFilter({
                          ...props.selectedFilter,
                          [data.name]: props.selectedFilter?.[
                            data.name
                          ]?.filter(
                            (element) => element !== getValidStatus(category)
                          ),
                        });
                      } else {
                        props.setFilter({
                          ...props.selectedFilter,
                          [data.name]: [
                            ...props.selectedFilter?.[data.name],
                            getValidStatus(category),
                          ],
                        });
                      }
                    }}
                  >
                    <p>{category}</p>
                    {data?.showCountValue && (
                      <p className=" padding-horizontal-default">
                        {`(${getTotalValueCount(
                          data?.getCountFrom,
                          data?.name,
                          getValidStatus(category)
                        )})`}
                      </p>
                    )}
                  </div>
                ))}
              {/* form/subtext card - for text values (value - is array of object) */}
              {data.type === "text" &&
                isValidArray(data.value) &&
                data.value.every((subtext) => isValidObject(subtext)) &&
                data.value.map((subtext, index) => (
                  <div
                    key={`${index}-${subtext?.documentId}`}
                    className={` margin-vertical-smaller inherit-parent-height padding-default font-size-default text-uppercase text-overflow-ellipsis cursor-pointer  ${
                      props?.dataCy !== "petitions-filter"
                        ? props.selectedFilter?.[data.name]?.includes(
                            subtext?.documentId
                          )
                          ? props.theme === "dark"
                            ? "background-selected-card-gradient-e5e5e5-dark-left-to-right"
                            : "background-selected-card-gradient-e5e5e5-light-left-to-right"
                          : ""
                        : !!props.selectedFilter?.[data?.name]?.find(
                            (element) =>
                              element === subtext?.english ||
                              element === subtext?.tamil
                          )
                        ? props.theme === "dark"
                          ? "background-selected-card-gradient-e5e5e5-dark-left-to-right"
                          : "background-selected-card-gradient-e5e5e5-light-left-to-right"
                        : ""
                    }`}
                    data-cy={
                      props?.dataCy !== "petitions-filter"
                        ? props.selectedFilter?.[data.name]?.includes(
                            subtext.documentId
                          )
                          ? `${data.dataCy}-selected-${subtext.documentId}`
                          : `${data.dataCy}-select-${subtext.documentId}`
                        : !!props.selectedFilter?.[data?.name]?.find(
                            (element) =>
                              element === subtext?.english ||
                              element === subtext?.tamil
                          )
                        ? `${data.dataCy}-selected-${subtext.documentId}`
                        : `${data.dataCy}-select-${subtext.documentId}`
                    }
                    onClick={() => {
                      if (props.dataCy !== "petitions-filter") {
                        if (
                          props.selectedFilter?.[data.name]?.includes(
                            subtext.documentId
                          )
                        ) {
                          props.setFilter({
                            ...props.selectedFilter,
                            [data.name]: props.selectedFilter?.[
                              data.name
                            ]?.filter(
                              (element) => element !== subtext.documentId
                            ),
                          });
                        } else {
                          props.setFilter({
                            ...props.selectedFilter,
                            [data.name]: [
                              ...props.selectedFilter?.[data.name],
                              subtext.documentId,
                            ],
                          });
                        }
                      } else {
                        if (
                          props.selectedFilter?.[data?.name]?.find(
                            (element) =>
                              element === subtext?.english ||
                              element === subtext?.tamil
                          )
                        ) {
                          props.setFilter({
                            ...props.selectedFilter,
                            [data?.name]: props.selectedFilter?.[
                              data?.name
                            ]?.filter((element) => {
                              if (
                                element !== subtext?.english &&
                                element !== subtext?.tamil
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }),
                          });
                        } else {
                          props.setFilter({
                            ...props.selectedFilter,
                            [data?.name]: [
                              ...props.selectedFilter?.[data?.name],
                              ...Object.values(subtext),
                            ],
                          });
                        }
                      }
                    }}
                  >
                    {subtext.title}

                    {props.dataCy === "petitions-filter" && (
                      <>
                        {Object.values(subtext)?.map((element, index) => (
                          <p
                            className=" inherit-parent-width padding-bottom-small padding-top-smaller"
                            key={index}
                          >
                            {element}
                          </p>
                        ))}
                      </>
                    )}
                  </div>
                ))}
              {/* type = input box */}
              {data.type === "inputBox" && (
                <div
                  className=" display-flex flex-direction-row font-size-medium  flex-align-items-center padding-default margin-vertical-default"
                  data-cy={`${data.dataCy}-input-box-section-left`}
                >
                  <p
                    className=" text-uppercase text-align-left white-space-no-wrap"
                    style={{ width: "112px" }}
                  >
                    {data?.inputLabel}
                  </p>
                  <p className=" text-align-center padding-horizontal-large">
                    :
                  </p>
                  <input
                    placeholder={data?.inputPlaceholder}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        [event.target.name]: event.target.value,
                      });

                      data?.inputOnChange(event.target.value);
                      return;
                    }}
                    data-cy={`${data.dataCy}-phoneNumber-input-box-left`}
                    className={` ${
                      props.theme === "dark"
                        ? "background-color-dark font-color-white"
                        : "background-white "
                    }  border-none font-size-default margin-right-medium`}
                    name={data?.name}
                    style={{
                      minWidth: "50%",
                    }}
                    value={formData?.[data?.name]}
                  />
                  {isValidPhoneNumber(formData?.[data?.name], data.name)
                    ?.status === false && (
                    <div className=" padding-horizontal-small cursor-pointer display-flex">
                      <ToolTip
                        text={
                          isValidPhoneNumber(formData?.[data?.name])?.message
                        }
                        theme={props.theme}
                        dataCy={data?.dataCy}
                      >
                        <ErrorIcon width="20px" height="20px" />{" "}
                      </ToolTip>
                    </div>
                  )}
                  {(!formData?.[data?.name] ||
                    formData?.[data?.name]?.length === 0) && (
                    <div className="padding-horizontal-smaller cursor-pointer display-flex">
                      <SearchIcon
                        color={props.theme === "dark" ? "white" : "#404040"}
                        width="16px"
                        height="16px"
                      />
                    </div>
                  )}
                  {typeof formData?.[data?.name] === "string" &&
                    formData?.[data?.name]?.length !== 0 && (
                      <div
                        className="padding-horizontal-smaller cursor-pointer display-flex"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            [data?.name]: "",
                          });

                          props.setFilter({
                            ...props.selectedFilter,
                            [data?.name]: [],
                          });
                        }}
                      >
                        <CloseIcon
                          color={props.theme === "dark" ? "white" : "#404040"}
                          width={16}
                          height={16}
                        />
                      </div>
                    )}
                </div>
              )}
              {/* type = dateRangeForm */}
              {data.type === "dateRangeForm" && (
                <form
                  className=""
                  data-cy={`${data.dataCy}-component`}
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      props.setFilter({
                        ...props.selectedFilter,
                        [data.name]: {
                          ...props.selectedFilter?.[data.name],
                          [e.target.name]: e.target.value,
                          ...(props.selectedFilter?.[data.name]?.to
                            ? {}
                            : { to: new Date().toISOString().split("T")[0] }),
                          ...(e.target.name === "to" &&
                          props.selectedFilter?.[data.name]?.from
                            ? {}
                            : { from: e.target.value }),
                        },
                      });
                    } else {
                      props.setFilter({
                        ...props.selectedFilter,
                        [data.name]: {
                          from: null,
                          to: null,
                        },
                      });
                    }
                  }}
                >
                  <div
                    className="display-flex padding-default margin-vertical-smaller flex-align-items-center"
                    style={{
                      justifyContent: "end",
                    }}
                  >
                    <div
                      // style={{ width: 70 }}
                      className="font-size-default "
                    >
                      FROM
                    </div>

                    <div className=" padding-horizontal-large">:</div>

                    <div
                      className="display-flex flex-direction-column "
                      style={{
                        justifyContent: "end",
                      }}
                    >
                      <input
                        name="from"
                        data-cy="filter-from-input"
                        className={` ${
                          props.theme === "dark"
                            ? "font-color-white background-color-dark calendar-icon-color-invert"
                            : "font-color-secondary"
                        }  border-none inherit-parent-width font-size-default font-family-RHD-medium `}
                        style={{
                          width: "150px",
                        }}
                        value={props.selectedFilter?.[data.name]?.from || ""}
                        max={
                          props.selectedFilter?.[data.name]?.to ||
                          new Date().toISOString().split("T")[0]
                        }
                        type="date"
                      />
                    </div>
                  </div>

                  <div
                    className="display-flex padding-vertical-default margin-vertical-smaller flex-align-items-center "
                    style={{
                      justifyContent: "end",
                    }}
                  >
                    <div className="font-size-default ">TO</div>
                    <div className=" padding-horizontal-large">:</div>
                    <div className="display-flex flex-direction-column ">
                      <input
                        name="to"
                        data-cy="filter-to-input"
                        className={` ${
                          props.theme === "dark"
                            ? "font-color-white background-color-dark calendar-icon-color-invert"
                            : "font-color-secondary"
                        }  border-none inherit-parent-width font-size-default font-family-RHD-medium `}
                        style={{
                          width: "150px",
                        }}
                        value={
                          props.selectedFilter?.[data.name].to ||
                          new Date().toISOString().split("T")[0]
                        }
                        min={
                          props.selectedFilter?.[data.name]?.from ||
                          new Date().toISOString().split("T")[0]
                        }
                        max={new Date().toISOString().split("T")[0]}
                        type="date"
                      />
                    </div>
                  </div>
                </form>
              )}
              {/* border of the section */}
              {index !== filterDataArray.length - 1 ? (
                <div
                  className={` inherit-parent-width margin-top-large ${
                    props.theme === "dark"
                      ? "background-selected-card-gradient-e5e5e5-dark-left-to-right"
                      : "background-selected-card-gradient-e5e5e5-light-left-to-right"
                  } `}
                  style={{
                    minHeight: "1px",
                    maxHeight: "1px",
                  }}
                />
              ) : (
                <div
                  className={` inherit-parent-width margin-vertical-large  `}
                />
              )}
            </div>
          ))}
      </div>

      <div
        onMouseEnter={props.mouseInWhiteArea}
        onMouseLeave={props.mouseOutWhiteArea}
        className="inherit-parent-height background-white width-30-percentage display-flex flex-direction-column text-align-right overflow-scroll padding-larger"
        style={{
          // right: "24px",
          maxHeight: "960px",
        }}
      >
        {props.filterData
          .filter((data) => data.position === "right")
          .map((data, index) => (
            <div
              className=" inherit-parent-width display-flex flex-direction-column padding-vertical-default"
              key={`${index}-${data?.title}`}
            >
              {/* title section */}
              <div
                className={`inherit-parent-width text-uppercase font-size-small  position-sticky top-0 padding-horizontal-default  ${
                  props.theme === "dark"
                    ? "background-color-dark"
                    : "background-white"
                }`}
              >
                <p className="margin-vertical-medium inherit-parent-width">
                  {data.title}
                </p>
              </div>

              {/* form/subtext card - for text values (value - is array of string) */}
              {data.type === "text" &&
                isValidArray(data.value) &&
                data.value.every((category) => typeof category === "string") &&
                data.value.map((category, index) => (
                  <div
                    key={`${index}-${category}`}
                    className={` inherit-parent-width padding-default margin-vertical-smaller font-size-default text-uppercase cursor-pointer display-flex 
                ${
                  props.selectedFilter?.[data.name]?.includes(
                    getValidStatus(category)
                  )
                    ? props.theme === "dark"
                      ? "background-selected-card-gradient-e5e5e5-dark-right-to-left "
                      : "background-selected-card-gradient-e5e5e5-light-right-to-left "
                    : ""
                }
                `}
                    style={{
                      justifyContent: "flex-end",
                    }}
                    data-cy={
                      props.selectedFilter?.[data.name]?.includes(
                        getValidStatus(category)
                      )
                        ? `${data.dataCy}-selected-${category}`
                        : `${data.dataCy}-${category}`
                    }
                    onClick={() => {
                      let value = category;

                      if (props.selectedFilter?.[data.name]?.includes(value)) {
                        props.setFilter({
                          ...props.selectedFilter,
                          [data.name]: props.selectedFilter?.[
                            data.name
                          ]?.filter(
                            (element) => element !== getValidStatus(value)
                          ),
                        });
                      } else {
                        props.setFilter({
                          ...props.selectedFilter,
                          [data.name]: [
                            ...props.selectedFilter?.[data.name],
                            getValidStatus(value),
                          ],
                        });
                      }
                    }}
                  >
                    {!!data?.showCountValue && (
                      <p className=" padding-horizontal-default">
                        {`(${getTotalValueCount(
                          data?.getCountFrom,
                          data?.name,
                          getValidStatus(category)
                        )})`}
                      </p>
                    )}
                    {category}
                  </div>
                ))}

              {/* form/subtext card - for text values (value - is array of object) */}
              {data.type === "text" &&
                isValidArray(data?.value) &&
                data.value.every((subtext) => isValidObject(subtext)) &&
                data.value.map((subtext, index) => (
                  <div
                    key={`${index}-${subtext?.documentId}`}
                    className={` margin-vertical-smaller inherit-parent-height padding-default font-size-default text-uppercase text-overflow-ellipsis cursor-pointer  ${
                      props?.dataCy !== "petitions-filter"
                        ? props.selectedFilter?.[data.name]?.includes(
                            subtext?.documentId
                          )
                          ? props.theme === "dark"
                            ? "background-selected-card-gradient-e5e5e5-dark-left-to-right"
                            : "background-selected-card-gradient-e5e5e5-light-left-to-right"
                          : ""
                        : !!props.selectedFilter?.[data?.name]?.find(
                            (element) =>
                              element === subtext?.english ||
                              element === subtext?.tamil
                          )
                        ? props.theme === "dark"
                          ? "background-selected-card-gradient-e5e5e5-dark-left-to-right"
                          : "background-selected-card-gradient-e5e5e5-light-left-to-right"
                        : ""
                    }`}
                    data-cy={
                      props?.dataCy !== "petitions-filter"
                        ? props.selectedFilter?.[data.name]?.includes(
                            subtext.documentId
                          )
                          ? `${data.dataCy}-selected-${subtext.documentId}`
                          : `${data.dataCy}-select-${subtext.documentId}`
                        : !!props.selectedFilter?.[data?.name]?.find(
                            (element) =>
                              element === subtext?.english ||
                              element === subtext?.tamil
                          )
                        ? `${data.dataCy}-selected-${subtext.documentId}`
                        : `${data.dataCy}-select-${subtext.documentId}`
                    }
                    onClick={() => {
                      if (props.dataCy !== "petitions-filter") {
                        if (
                          props.selectedFilter?.[data.name]?.includes(
                            subtext.documentId
                          )
                        ) {
                          props.setFilter({
                            ...props.selectedFilter,
                            [data.name]: props.selectedFilter?.[
                              data.name
                            ]?.filter(
                              (element) => element !== subtext.documentId
                            ),
                          });
                        } else {
                          props.setFilter({
                            ...props.selectedFilter,
                            [data.name]: [
                              ...props.selectedFilter?.[data.name],
                              subtext.documentId,
                            ],
                          });
                        }
                      } else {
                        if (
                          props.selectedFilter?.[data?.name]?.find(
                            (element) =>
                              element === subtext?.english ||
                              element === subtext?.tamil
                          )
                        ) {
                          props.setFilter({
                            ...props.selectedFilter,
                            [data?.name]: props.selectedFilter?.[
                              data?.name
                            ]?.filter((element) => {
                              if (
                                element !== subtext?.english &&
                                element !== subtext?.tamil
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }),
                          });
                        } else {
                          props.setFilter({
                            ...props.selectedFilter,
                            [data?.name]: [
                              ...props.selectedFilter?.[data?.name],
                              ...Object.values(subtext),
                            ],
                          });
                        }
                      }
                    }}
                  >
                    {subtext.title || "N/A"}
                    {props.dataCy === "petitions-filter" && (
                      <>
                        {Object.values(subtext)?.map((element, index) => (
                          <p
                            className=" inherit-parent-width padding-bottom-small padding-top-smaller"
                            key={index}
                          >
                            {element}
                          </p>
                        ))}
                      </>
                    )}
                  </div>
                ))}

              {/* type = input box */}
              {data.type === "inputBox" && (
                <div
                  className=" display-flex font-size-medium  flex-align-items-center flex-justify-content-end padding-default margin-vertical-default"
                  data-cy={`${data.dataCy}-input-box-section-right`}
                >
                  <p
                    className=" text-uppercase white-space-no-wrap"
                    style={{ width: "112px" }}
                  >
                    {data?.inputLabel}
                  </p>
                  <p className=" text-align-center padding-horizontal-large">
                    :
                  </p>
                  <input
                    placeholder={data?.inputPlaceholder}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        [event.target.name]: event.target.value,
                      });

                      data?.inputOnChange(event.target.value);
                    }}
                    // onPaste={(event) => {
                    //   event.preventDefault();
                    // }}
                    data-cy={`${data.dataCy}-phoneNumber-input-box-right`}
                    className={` ${
                      props.theme === "dark"
                        ? "background-color-dark font-color-white"
                        : "background-white "
                    }  border-none font-size-default margin-right-medium`}
                    name={data?.name}
                    value={formData?.[data?.name]}
                    style={{
                      minWidth: "50%",
                    }}
                  />

                  {isValidPhoneNumber(formData?.[data?.name], data.name)
                    ?.status === false ? (
                    <div className="  display-flex cursor-pointer">
                      <ToolTip
                        text={
                          isValidPhoneNumber(formData?.[data?.name], data.name)
                            ?.message
                        }
                        theme={props.theme}
                        dataCy={data?.dataCy}
                        position="bottom"
                      >
                        <ErrorIcon width="20px" height="20px" />{" "}
                      </ToolTip>
                    </div>
                  ) : (
                    <div style={{ width: "20px", height: "20px" }} />
                  )}

                  {(!formData?.[data?.name] ||
                    formData?.[data?.name]?.length === 0) && (
                    <div
                      className=" padding-left-small display-flex cursor-pointer"
                      style={{
                        alignItems: "end",
                      }}
                    >
                      <SearchIcon
                        color={props.theme === "dark" ? "white" : "#404040"}
                        width={20}
                        height={20}
                      />
                    </div>
                  )}

                  {typeof formData?.[data?.name] === "string" &&
                    formData?.[data?.name]?.length !== 0 && (
                      <div
                        className="padding-horizontal-smaller display-flex cursor-pointer"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            [data?.name]: "",
                          });
                          props.setFilter({
                            ...props.selectedFilter,
                            [data?.name]: [],
                          });
                        }}
                      >
                        <CloseIcon
                          color={props.theme === "dark" ? "white" : "#404040"}
                          width={20}
                          height={20}
                        />
                      </div>
                    )}
                </div>
              )}

              {/* type = dateRangeForm */}
              {data.type === "dateRangeForm" && (
                <form
                  className=""
                  data-cy={`${data.dataCy}-component`}
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      props.setFilter({
                        ...props.selectedFilter,
                        [data.name]: {
                          ...props.selectedFilter?.[data.name],
                          [e.target.name]: e.target.value,
                          ...(e.target.name === "from" &&
                          !props.selectedFilter?.[data.name]?.to
                            ? { to: new Date().toISOString().split("T")[0] }
                            : {}),
                          ...(e.target.name === "to" &&
                          !props.selectedFilter?.[data.name]?.from
                            ? { from: e.target.value }
                            : {}),
                        },
                      });
                    } else {
                      props.setFilter({
                        ...props.selectedFilter,
                        [data.name]: {
                          from: null,
                          to: null,
                        },
                      });
                    }
                  }}
                >
                  <div
                    className="display-flex padding-default margin-vertical-smaller flex-align-items-center"
                    style={{
                      justifyContent: "end",
                    }}
                  >
                    <div className="font-size-default ">FROM</div>

                    <div className=" padding-horizontal-large">:</div>

                    <div
                      className="display-flex flex-direction-column "
                      style={{
                        justifyContent: "end",
                      }}
                    >
                      <input
                        name="from"
                        data-cy="filter-from-input"
                        className={` ${
                          props.theme === "dark"
                            ? "font-color-white background-color-dark calendar-icon-color-invert"
                            : "font-color-secondary"
                        }  border-none inherit-parent-width font-size-default font-family-RHD-medium `}
                        style={{
                          width: "150px",
                        }}
                        value={props.selectedFilter?.[data.name]?.from || ""}
                        max={
                          props.selectedFilter?.[data.name]?.to ||
                          new Date().toISOString().split("T")[0]
                        }
                        type="date"
                      />
                    </div>
                  </div>

                  <div
                    className="display-flex padding-default margin-vertical-smaller flex-align-items-center "
                    style={{
                      justifyContent: "end",
                    }}
                  >
                    <div className="font-size-default ">TO</div>
                    <div className=" padding-horizontal-large">:</div>
                    <div className="display-flex flex-direction-column ">
                      <input
                        name="to"
                        data-cy="filter-to-input"
                        className={` ${
                          props.theme === "dark"
                            ? "font-color-white background-color-dark calendar-icon-color-invert"
                            : "font-color-secondary"
                        }  border-none inherit-parent-width font-size-default font-family-RHD-medium `}
                        style={{
                          width: "150px",
                        }}
                        value={props.selectedFilter?.[data.name]?.to || ""}
                        min={props.selectedFilter?.[data.name]?.from || ""}
                        max={new Date().toISOString().split("T")[0]}
                        type="date"
                      />
                    </div>
                  </div>
                </form>
              )}

              {/* border of the section */}
              {index !==
              props.filterData?.filter((data) => data?.position === "right")
                ?.length -
                1 ? (
                <div
                  className={` inherit-parent-width margin-top-large ${
                    props.theme === "dark"
                      ? "background-selected-card-gradient-e5e5e5-dark-right-to-left"
                      : "background-selected-card-gradient-e5e5e5-light-right-to-left"
                  } `}
                  style={{
                    minHeight: "1px",
                    maxHeight: "1px",
                  }}
                />
              ) : (
                <div
                  className={` inherit-parent-width margin-vertical-large  `}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

const getTotalValueCount = (data, type, value) => {
  let count = 0;
  data &&
    Object.keys(data)?.forEach((key) => {
      if (data?.[key]?.[type] === value) {
        count++;
      }
    });
  return count;
};

const volunteeringReducer = (
  state = {
    loading: false,
    data: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_VOLUNTEERING_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_VOLUNTEERING_ASSETS":
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };

    case "SET_VOLUNTEERING_DATA":
      return {
        ...state,
        data: action.payload.data,
      };

    case "RESET":
      return {
        loading: false,
        data: null,
      };

    default:
      return state;
  }
};
export default volunteeringReducer;

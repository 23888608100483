import { put, takeEvery, all } from "redux-saga/effects";
import { isValidArray } from "../../Services/validators";
import { setErrorStatus } from "../status/action";
// import { setErrorStatus } from "../status/action";

export const authActionTypes = {
  SET_TABLE_LOADING: "SET_TABLE_LOADING",
  SET_SEARCH_KEY: "SET_SEARCH_KEY",
  SET_SELECTED_TABLE: "SET_SELECTED_TABLE",
  SET_SELECTED_TABLE_ID: "SET_SELECTED_TABLE_ID",
  SET_TABLE_FILTER: "SET_TABLE_FILTER",
};

function* setSelectedTableWorker(action) {
  yield put({
    type: "PUT_SELECTED_TABLE",
    payload: {
      selectedTable: action.payload.selectedTable,
    },
  });
}

function* setSearchKeyWorker(action) {
  yield put({
    type: "PUT_SEARCH_KEY",
    payload: {
      searchKey: action.payload.searchKey,
    },
  });
}

function* setSelectedTableIdWorker(action) {
  if (action.payload.table === "PETITIONS") {
    yield put({
      type: "PUT_SELECTED_PETITION",
      payload: {
        id: action.payload.id,
      },
    });
  }

  if (action.payload.table === "JOBS") {
    yield put({
      type: "PUT_SELECTED_JOB",
      payload: {
        id: action.payload.id,
      },
    });
  }

  if (action.payload.table === "VOLUNTEERING") {
    yield put({
      type: "PUT_SELECTED_VOLUNTEERING",
      payload: {
        id: action.payload.id,
      },
    });
  }
}

function* setTableFilterWorker(action) {
  if (action.payload.table === "PETITIONS") {
    let filterLength = 1;

    Object.values(action.payload.filter).forEach((data) => {
      if (isValidArray(data)) {
        filterLength *= data.length;
      }
    });

    if (filterLength <= 20) {
      yield put({
        type: "PUT_PETITIONS_FILTER",
        payload: {
          filter: action.payload.filter,
        },
      });
    } else {
      setErrorStatus({ code: "custom", message: "Filter limit reached" });
    }
  }
}

export default function* tableWatcher() {
  yield all([
    takeEvery("SET_SELECTED_TABLE", setSelectedTableWorker),
    takeEvery("SET_SELECTED_TABLE_ID", setSelectedTableIdWorker),
    takeEvery("SET_SEARCH_KEY", setSearchKeyWorker),
    takeEvery("SET_TABLE_FILTER", setTableFilterWorker),
  ]);
}

// function* setTableLoading(value) {
//   yield put({
//     type: "SET_TABLE_LOADING",
//     payload: {
//       loading: value,
//     },
//   });
// }
